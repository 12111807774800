import React, { useEffect, useMemo, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Stack,
  Box,
  Flex,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { StakeMaker } from ".";
import { BetSidesEnum, RsaBet, OrderType, RsaRunner } from "../../../types";
import {
  useBetsStore,
  useBetMakerStakesStore,
  useGetBets,
} from "../../../store";
import {
  BiListPlus,
  BiTrash,
  BiArrowToBottom,
  BiArrowToTop,
} from "react-icons/bi";
import { BetUtils, useWhatIfs } from "../utils";
import { Utils } from "../../tables/utils";
import { Styled } from "../styles";
import { PriceTypeDisplay } from "./stake-maker";
import { PriceUtils } from "../../../utils/prices";

export const CopyButton = (props: {
  icon: React.ReactElement;
  label: string;
  from: number;
  to: number;
  reverse?: boolean;
  onClick: (count: number) => void;
}) => {
  const { icon, label } = props;
  return (
    <>
      {props.to === props.from && (
        <IconButton
          variant="outline"
          aria-label={label}
          icon={icon}
          onClick={() => props.onClick(props.from)}
        />
      )}
      {props.to > props.from && (
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label={label}
            icon={icon}
            variant="outline"
          />
          <MenuList>
            {Array.from({ length: props.to - props.from + 1 }, (_, i) => {
              const count = props.reverse ? props.to - i : props.from + i;
              return (
                <MenuItem key={count} onClick={() => props.onClick(count)}>
                  {count}
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      )}
    </>
  );
};

export const BetDialog = (props: {
  onClose: () => void;
  onCloseAndUpdate: (bets: RsaBet[]) => void;
  isOpen: boolean;
  marketId: string;
  faveNo: number;
  bsp: number;
  bets: RsaBet[];
  runners: RsaRunner[];
  unmatched?: boolean;
}) => {
  const {
    onClose,
    onCloseAndUpdate,
    faveNo,
    isOpen,
    bsp,
    runners,
    unmatched = false,
    marketId,
  } = props;
  const getId = useBetsStore((state: any) => state.getId);
  const dataStore = useBetMakerStakesStore((state: any) => state);
  const userBets = useGetBets(marketId);
  const maxFave = Math.max(...runners.map((r) => r.faveNo));

  const emptyBet: RsaBet = useMemo(() => {
    return {
      size: 0, //dataStore[BetSidesEnum.Back] || 0.1,
      price: PriceUtils.round(bsp, BetSidesEnum.Back),
      side: BetSidesEnum.Back,
      faveNo: faveNo,
      bsp: bsp,
      userBet: false,
      orderType: OrderType.LIMIT,
    };
  }, [bsp, faveNo]);

  const [localBets, setLocalBets] = useState<RsaBet[]>([]);
  const [removedBets, setRemovedBets] = useState<RsaBet[]>([]);
  const whatifs: any = useWhatIfs(
    () => [
      ...userBets.filter((ub: RsaBet) => ub.faveNo !== faveNo),
      ...localBets,
    ],
    marketId,
    runners
  );

  function addNewBet(): void {
    setLocalBets([
      ...localBets,
      { id: getId(marketId, faveNo, 1), ...emptyBet },
    ]);
  }

  function RemoveBet(id: string): void {
    const betToRemove = localBets.find((bet) => bet.id === id);
    if (betToRemove) {
      setRemovedBets([...removedBets, { ...betToRemove, size: -1, price: -1 }]);
    }
    setLocalBets(localBets.filter((bet) => bet.id !== id));
  }

  const copyBets = (count: number, direction: "up" | "down") => {
    const bets = BetUtils.copyBets(
      marketId,
      runners,
      localBets,
      faveNo,
      count,
      direction,
      getId
    );
    onCloseAndUpdate(bets);
  };

  const copyDown = (count: number) => copyBets(count, "down");

  const copyUp = (count: number) => copyBets(count, "up");

  useEffect(() => {
    if (isOpen) {
      setLocalBets(
        props.bets.length > 0
          ? props.bets
          : [{ id: getId(marketId, faveNo, 0), ...emptyBet }]
      );
    }
  }, [JSON.stringify(props.bets), isOpen]);

  function updateBet(bet: RsaBet): void {
    setLocalBets(
      localBets.map((b) => {
        if (b.id === bet.id) {
          return bet;
        }
        return b;
      })
    );
  }

  const wifs = useMemo(() => {
    return Utils.roundDecimal(whatifs[faveNo] || 0);
  }, [JSON.stringify(localBets), isOpen]);

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      size={"xl"}
      onClose={() => onClose()}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Orders for Fave #{faveNo} :{" "}
          <Styled.WhatIfs $neg={wifs < 0}>£{wifs}</Styled.WhatIfs>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Stack direction="column">
            {localBets.map((bet, index) => (
              <Stack direction="row" key={`stack-${bet.faveNo}:${index}`}>
                <StakeMaker
                  key={`stakemaker-${bet.faveNo}:${index}`}
                  value={bet}
                  defaultValue={bet}
                  defaultIncr={dataStore[bet.side] || 0.1}
                  startSize={false}
                  active={false}
                  showPrice={true}
                  showPriceType={
                    !unmatched && !bet.userBet
                      ? PriceTypeDisplay.Show
                      : PriceTypeDisplay.Invisible
                  }
                  onChanges={(bet) => updateBet(bet)}
                />

                <IconButton
                  key={`addlay-${bet.faveNo}:${index}`}
                  size="sm"
                  fontSize={20}
                  isRound={true}
                  variant="unstyled"
                  aria-label="Remove Row"
                  icon={<BiTrash />}
                  onClick={() => RemoveBet(bet?.id ?? "")}
                />
              </Stack>
            ))}

            {!unmatched && (
              <Box>
                <IconButton
                  size="sm"
                  fontSize={20}
                  variant="outline"
                  aria-label="Add Row"
                  icon={<BiListPlus />}
                  onClick={() => addNewBet()}
                />
              </Box>
            )}
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Flex gap={4}>
            {!unmatched && (
              <>
                <CopyButton
                  icon={<BiArrowToTop />}
                  label="Copy Up"
                  from={1}
                  to={faveNo - 1}
                  reverse={true}
                  onClick={copyUp}
                />

                <CopyButton
                  icon={<BiArrowToBottom />}
                  label="Copy Down"
                  from={faveNo + 1}
                  to={maxFave}
                  onClick={copyDown}
                />
              </>
            )}
            <Button
              onClick={() =>
                onCloseAndUpdate(
                  unmatched ? [...localBets, ...removedBets] : localBets
                )
              }
            >
              {unmatched ? "Update" : "Save"}
            </Button>
            <Button onClick={() => onCloseAndUpdate([])}>
              {unmatched ? "Cancel Unmatched" : "Delete All"}
            </Button>
            <Button onClick={() => onClose()}>Cancel</Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
