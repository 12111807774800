import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  Flex,
  AccordionIcon,
  AccordionPanel,
  Button,
  useToast,
} from "@chakra-ui/react";
import { RsaRangeSelect } from "../range";
import { FilterValueEnum } from "../../types";
import { getNumbers, prepareData } from "../dropdown";
import { useSystemsStore } from "../../store";
import { RsaSwitch } from "../switch";
import { SettingsLayoutType } from "../../store";
import { RsaEditable } from "../text-field";
import { ExportFile, ImportFile } from "../file";

export const SystemsDialog = (props: {
  onClose: () => void;
  isOpen: boolean;
}) => {
  const { onClose, isOpen } = props;
  const toast = useToast();
  const storeInit = useSystemsStore((state: any) => state.init);
  const storeReload = useSystemsStore((state: any) => state.reload);

  const layout = useSystemsStore((state: any) => state.layout());
  const filters = useSystemsStore((state: any) => state.filters);
  const syskeys = Object.keys(filters);

  const onReset = () => {
    storeInit(true);
    toast({
      title: "Completed",
      description: "The configuration been reset.",
      status: "success",
      duration: 9000,
      isClosable: true,
    });
  };

  const onImport = (data: string) => {
    let result = storeReload(data);
    toast(
      result
        ? {
            title: "Imported",
            description: "The configuration imported sucessfully.",
            status: "success",
            duration: 9000,
            isClosable: true,
          }
        : {
            title: "Import failed.",
            description: "The configuration import was unsucessfull.",
            status: "error",
            duration: 9000,
            isClosable: true,
          }
    );
  };

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Systems Configuration</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Accordion allowToggle key={"systems-acc"}>
            {syskeys.map((syskey: string) => {
              const sys = (filters as any)[syskey];
              return (
                <AccordionItem key={`${syskey}-item`} pb={4}>
                  <h2 key={`${syskey}-header`}>
                    <AccordionButton key={syskey}>
                      <Box as="span" flex="1" textAlign="left">
                        <span>{syskey.toUpperCase()}</span>
                        <span> - </span>
                        <span>{sys.name}</span>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} key={`${syskey}-panel`}>
                    <Stack direction="column" key={`col-${syskey}`}>
                      <RsaEditable
                        fontSize="sm"
                        storekey={`${syskey}.name`}
                        storage={useSystemsStore}
                      />
                      {layout
                        .sort(
                          (a: SettingsLayoutType, b: SettingsLayoutType) =>
                            a.order - b.order
                        )
                        .map((item: any, index: number) => {
                          return (
                            <div key={`${syskey}.${item.id}.${index}`}>
                              {item.type === "range" ? (
                                <RsaRangeSelect
                                  label={item.title}
                                  fromLabel="Plus"
                                  toLabel="Minus"
                                  storage={useSystemsStore}
                                  storekey={`${syskey}.${item.id}`}
                                  items={prepareData(getNumbers(0, 53))}
                                  size="sm"
                                  datatype={FilterValueEnum.int}
                                  placeholder="--"
                                  key={`${syskey}.${item.id}.${index}`}
                                />
                              ) : item.type === "switch" ? (
                                <>
                                  <Stack
                                    direction={"row"}
                                    key={`row-${syskey}`}
                                  >
                                    <div>{item.title}</div>
                                    <RsaSwitch
                                      colorScheme="teal"
                                      storage={useSystemsStore}
                                      storekey={`${syskey}.${item.id}`}
                                      key={`${syskey}.${item.id}.${index}`}
                                    />
                                  </Stack>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          );
                        })}
                    </Stack>
                  </AccordionPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        </ModalBody>

        <ModalFooter>
          <Flex gap={4}>
            <ExportFile
              label="Export"
              fileName="settings-configurations.cfg"
              data={JSON.stringify({ version: 2, filters })}
            />
            <ImportFile label="Import" ext=".cfg" onChange={onImport} />
            <Button onClick={onReset}>Reset</Button>
            <Button onClick={onClose}>Done</Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
