import React, { useEffect, useState, useMemo } from "react";
import { BaseTable } from "./base";
import { RsaTableProps } from "./types";
import { prepareTableData, createColumns } from "./columns";
import { Utils } from "./utils";

export const SimpleTable = (props: RsaTableProps & { keyField: string }) => {
  const { data, title, keyField, options = {}, ...rest } = props;
  const [filteredData, setFilteredData] = useState<{
    rows: any[];
    totals?: any[];
  }>({
    rows: [],
    totals: [],
  });

  options.formatter =
    options.formatter ||
    ((name: string, value: any) => {
      return value && Utils.roundInt(value);
    });

  if (!options.additionalColumns) {
    options.additionalColumns = [{ key: "all", name: "All" }];
  }

  const columns = useMemo(
    () => createColumns(keyField, options),
    [keyField, options]
  );

  useEffect(() => {
    if (data) {
      setFilteredData(prepareTableData(data, options));
    }
  }, [data]);

  return (
    <BaseTable
      rows={filteredData.rows}
      columns={columns}
      totals={filteredData.totals}
      title={props.title}
      size={options?.size}
      {...rest}
    />
  );
};
