export type RsaQueryRequestItem = {
  years?: number[];
  week: { from?: number; to?: number };
  dow?: number[];
  ran: { from?: number; to?: number };
  raceno: { from?: number; to?: number };
  track?: string[];
  distance?: string[];
  type?: string[];
  iphp: { from?: number; to?: number };
  iphm: { from?: number; to?: number };
  ip: { from?: number; to?: number };
  sp: { from?: number; to?: number };
  favebsp: { from?: number; to?: number };
  fave2bsp: { from?: number; to?: number };
  faveno: { from?: number; to?: number };
  top4: { from?: number; to?: number };
  state?: string;
  jumpflat?: string;
  aggs: string[];
  system: string;
  history: number;
  digest: string;
};

export type RsaQueryRequest = {
  queries: RsaQueryRequestItem[];
  digest: string;
};

export enum RsaQueryAggsEnum {
  Week = "Week",
  Dow = "Dow",
  Ran = "Ran",
  Type = "Type",
  Dis = "Dis",
  Track = "Track",
  RaceNo = "RaceNo",
  SP = "SP",
  Fav = "Fav",
  Money = "Money",
  SBB = "SBB",
}

export type RsaFaveQueryRequest = {
  week: { from?: number; to?: number };
  years: number[];
  history?: number;
};

export interface RsaQueryResponse {
  digest: string;
  lpd: {
    FavSP: any;
  };
  system: string;
  yrs: {
    Fav?: any;
    Dow?: any;
    Type?: any;
    Track?: any;
    Dis?: any;
    RaceNo?: {
      Winners?: {
        FavNo?: any;
      };
    };
    Ran?: any;
    SP?: any;
    IR?: any;
    Week?: any;
  };
}

export enum JumpFlatHarnessEnum {
  Flat = "Flat",
  Jump = "Jump",
  Harness = "Harness",
}

export const JumpFlatHarnessConvertor = (value: number | string) => {
  switch (`${value}`) {
    case "2":
      return JumpFlatHarnessEnum.Jump;
    case "3":
      return JumpFlatHarnessEnum.Harness;
    case "1":
    default:
      return JumpFlatHarnessEnum.Flat;
  }
};

export type QuickFinderDataType = {
  id: string;
  time: string;
  ran: number;

  week: { from?: number; to?: number };
  dow?: number[];
  track: string[];
  distance: string[];
  type: string[];
  country: string;
  jumpflat: string;

  switches: {
    track: boolean;
    distance: boolean;
    type: boolean;
    country: boolean;
    jumpflat: boolean;
  };

  history: number;
  digest: string;
};
