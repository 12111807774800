import React, { useState } from "react";
import { IconButton } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { useFilterStore } from "../../store";
import { UseBoundStore } from "zustand/react";
import { StoreApi } from "zustand/vanilla";

export const RsaResetFilter = (props: {
  size: string;
  storekey: string;
  storage?: UseBoundStore<StoreApi<unknown>>;
}) => {
  const { storage, storekey, ...rest } = props;
  const clearStore = (storage || useFilterStore)((state: any) => state.clear);

  return (
    <>
      <IconButton
        aria-label="Reset"
        icon={<DeleteIcon />}
        onClick={() => clearStore(storekey)}
        {...rest}
      />
    </>
  );
};
