import { create } from "zustand";
import {
  FilterValueEnum,
  QuickFinderDataType,
  RaceDataType,
  RsaQueryAggsEnum,
} from "../../types";
import { set_filter, get_filter, clear_filter } from "../utils";
import { get_digest } from "../../utils/digest";
import { useEffect } from "react";

export type QuickFinderFilterType = {
  week?: {
    from: number;
    to: number;
  };
  ran?: {
    from: number;
    to: number;
  };
  track?: string;
  distance?: string;
  type?: string;
  state?: string;
  jumpflat?: string;
};

export const useQuickFinderStore = create((set, get) => ({
  filters: { empty: true } as QuickFinderFilterType,
  get: (id: string) => {
    return get_filter(get() as any, id);
  },
  load: (initData: QuickFinderDataType) =>
    set((state: any) => {
      return {
        ...state,
        filters: {
          week: initData.week || { from: 1, to: 53 },
          dow:
            initData.dow && initData.dow.length > 0 ? initData.dow : undefined,
          ran: { from: initData.ran, to: initData.ran + 1 },
          track: initData.switches.track ? initData.track : undefined,
          distance: initData.switches.distance ? initData.distance : undefined,
          type: initData.switches.type ? initData.type : undefined,
          state: initData.switches.country ? initData.country : undefined,
          jumpflat: initData.switches.jumpflat ? initData.jumpflat : undefined,
          history: initData.history,
        },
      };
    }),
  store: (id: string, type: FilterValueEnum, value: any) =>
    set((state: any) => {
      if (state.filters.empty) {
        delete state.filters.empty;
      }
      state = set_filter(state, id, type, value);
      return { ...state, filters: { ...state.filters } };
    }),
  clear: (id: string) =>
    set((state: any) => {
      state = clear_filter(state, id);
      return { ...state, filters: { ...state.filters } };
    }, true),
}));

export const useGetQuickFilters = (
  aggs: RsaQueryAggsEnum[],
  initData?: QuickFinderDataType
) => {
  const load = useQuickFinderStore((state: any) => state.load);

  useEffect(() => {
    if (initData) {
      load(initData);
    }
  }, [initData]);

  const filters = useQuickFinderStore(
    (state: any) => state.filters || ({} as QuickFinderFilterType)
  );

  let params = {
    system: "mbf",
    aggs,
    ...filters,
  };

  return {
    ...params,
    digest: get_digest(params),
  };
};
