import { PriceType, RsaBet, RsaRunner } from "../../../types";
import { PriceUtils } from "../../../utils/prices";

const copyBets = (
  marketId: string,
  runners: RsaRunner[],
  localBets: RsaBet[],
  faveNo: number,
  count: number,
  direction: "up" | "down",
  getId: (marketId: string, faveNo: number, ext: number) => string
): RsaBet[] => {
  return localBets.reduce(
    (acc, bet) => {
      const start = direction === "down" ? faveNo + 1 : count;
      const end = direction === "down" ? count : faveNo - 1;
      for (let favex = start; favex <= end; favex++) {
        acc.push({
          ...bet,
          faveNo: favex,
          price:
            bet.priceType === PriceType.Percentage
              ? bet.price
              : PriceUtils.round(bet.price, bet.side),
          // PriceUtils.round(
          //     runners.find((r) => r.faveNo === favex)?.bsp?.value ?? 0,
          //     bet.side
          //   ),
          id: getId(marketId, favex, acc.length),
        });
      }
      return acc;
    },
    [...localBets]
  );
};

export const prepareForSubmit = (
  bets: RsaBet[],
  runners: RsaRunner[]
): RsaBet[] => {
  return (bets || [])
    .filter((b: RsaBet) => b.size !== 0)
    .map((bet: RsaBet) => {
      const runner = runners.find((r: RsaRunner) => r.faveNo === bet.faveNo);
      let price = PriceUtils.round(bet.price, bet.side);
      if (bet.priceType === PriceType.Percentage) {
        price = PriceUtils.round(
          bet.price * (parseFloat(runner?.bsp.value || 0) - 1) + 1,
          bet.side
        );
      }
      return runner
        ? { ...bet, price: price, selectionId: runner.selectionId }
        : bet;
    });
};

export const BetUtils = {
  copyBets,
  prepareForSubmit,
};
