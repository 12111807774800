import styled from "styled-components";
const SummaryTable = styled.div`
  width: 350px;
  margin-top: 20px;
  border: 1px solid black;
  padding: 5px;
  h1 {
    font-size: 1.5em;
    margin-bottom: 10px;
    border: 1px solid black;
    font-size: large;
  }
  .summary-section {
  }
  .summary-header {
    font-weight: bold;
    background-color: #aaa;
    margin: 4px 0;
  }
  .summary-body {
    display: flex;
  }
  .summary-row div {
    width: 50%;
    background-color: #f0f0f0;
  }
  .sub-body.faves {
    font-weight: bold;
    background-color: yellow;
  }
  .sub-header {
    font-weight: bold;
    color: #012285;
  }
  .summary-row {
    padding: 2px;
    display: flex;
    gap: 3px;
    width: 50%;
  }
`;

export const Styled = {
  SummaryTable,
};
