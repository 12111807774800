import { useAccessTokenStore, useBfAccessTokenStore } from "../../store";
import { LoginRequest } from "../../types/user";
import { USER_LOGIN } from "../endpoints";
import { request, RequestMethod } from "../requests";
import { MutateOptions, useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

export enum LoginErrorCodeEnum {
  NoRefreshToken = "NoRefreshToken",
  InvalidLogin = "InvalidLogin",
}

export const useUserLogin = (
  options: MutateOptions<unknown, AxiosError, LoginRequest, any> & {
    onNext: () => void;
  }
) => {
  const { store } = useAccessTokenStore((state: any) => state);
  const bfAccessToken = useBfAccessTokenStore(
    (state: any) => state.accessToken
  );

  const userLogin = async (paylod: LoginRequest) => {
    return await request(
      USER_LOGIN,
      {
        method: RequestMethod.POST,
        data: paylod,
      },
      undefined,
      bfAccessToken()
    );
  };

  options.onSuccess =
    options.onSuccess ||
    function (response: any) {
      store(response);
      options.onNext && options.onNext();
    };

  return useMutation({
    mutationFn: userLogin,
    mutationKey: ["user-login"],
    ...options,
  });
};
