export const prepareData = (items: any[]) =>
  items.map((item, index) => ({ label: `${item}`, id: `${item}` }));

export const getNumbers = (from: number, count: number) => {
  let numbers = [];
  let current = from;
  for (let nx = 0; nx < count; nx++) {
    numbers.push(current + nx);
  }

  return numbers;
};
