import React, { Children, useMemo, useRef, useState } from "react";
import { Styled } from "./styles";
import { Styled as BaseStyled } from "../styles";
import Skeleton from "react-loading-skeleton";

import { generateBackgroundColor, generateLegend } from "./utils";
import { EmptyRowsRenderer } from "../empty-box";

// Read the blog post here:
// https://letsbuildui.dev/articles/building-a-heatmap-chart-component
export const HeatmapTable = (props: {
  data: any[];
  highlights: number[];
  xAxisLabels: any[];
  yAxisLabels: any[];
  orientation: "vertical" | "horizontal";
  showLegend?: boolean;
  loading?: boolean;
  children?: React.ReactNode;
}) => {
  const {
    data = [],
    xAxisLabels = [],
    yAxisLabels = [],
    highlights = [],
    loading = false,
    orientation = "vertical",
  } = props;

  const formattedData = data;
  const { gridCells, minMaxCount } = useMemo(() => {
    let minMaxCount: number[] = [];

    const gridCells: {
      [key: string]: {
        xAxis: {
          key: string;
          name: string;
          count: number;
          in_range: boolean;
        }[];
        name: string;
      };
    } = xAxisLabels.reduce((days, dayLabel) => {
      const dayAndHour: { dayHour: string; count: number }[] =
        yAxisLabels.reduce((xAxis, xAxisLabel) => {
          const { count, in_range } = formattedData[
            dayLabel.key
          ]?.ranges.reduce(
            (total: { count: number; in_range: boolean }, range: any) => {
              return range.key === xAxisLabel.key
                ? {
                    count: total.count + 1,
                    in_range: !total.in_range ? range.in : total.in_range,
                  }
                : total;
            },
            { count: 0, in_range: false }
          );

          minMaxCount = [...minMaxCount, count];

          return [
            ...xAxis,
            {
              key: `${xAxisLabel.key}`,
              name: xAxisLabel.name,
              count,
              in_range,
            },
          ];
        }, []);

      return {
        ...days,
        [dayLabel.key]: {
          xAxis: dayAndHour,
          name: dayLabel.name,
        },
      };
    }, {});

    return { gridCells, minMaxCount };
  }, [formattedData, xAxisLabels, yAxisLabels]);

  const highlighted = (faveNo: string) => {
    return highlights.map((f) => `${f}`).includes(faveNo);
  };

  return (
    <Styled.Container>
      {loading ? (
        <BaseStyled.TableLoadingWrapper>
          <Skeleton count={10} />
        </BaseStyled.TableLoadingWrapper>
      ) : (
        <>
          {formattedData.length === 0 ? (
            <EmptyRowsRenderer title="Heatmap" />
          ) : (
            <>
              <Styled.Heatmap
                $orientation={orientation}
                $rows={xAxisLabels.length}
                $cols={yAxisLabels.length + 2}
              >
                {Object.keys(gridCells).map((faveNo) => (
                  <div key={faveNo} className="cells col">
                    <Styled.Label>
                      <Styled.SubLabel $highlighted={highlighted(faveNo)}>
                        {gridCells[faveNo].name}
                      </Styled.SubLabel>
                    </Styled.Label>

                    {gridCells[faveNo].xAxis.map(
                      ({ key, name, count, in_range }) => (
                        <Styled.Cell
                          $rng={key}
                          $bgColor={generateBackgroundColor(count)}
                          $inRange={count > 0 ? in_range : true}
                          key={key}
                          aria-label={`${key} ${name} ${count}`}
                        >
                          {key === "z0" ? (
                            <>{gridCells[faveNo].name}</>
                          ) : count !== 0 ? (
                            <div className="number">{count}</div>
                          ) : (
                            <></>
                          )}
                        </Styled.Cell>
                      )
                    )}
                    <Styled.Label>
                      <Styled.SubLabel $highlighted={highlighted(faveNo)}>
                        {gridCells[faveNo].name}
                      </Styled.SubLabel>
                    </Styled.Label>
                  </div>
                ))}
                <div className="col">
                  <span></span>
                  {yAxisLabels.map((label, index) => (
                    // Only render every other label text
                    <Styled.Label key={label.key}>
                      {index % 2 === 0 ? label.name : null}
                    </Styled.Label>
                  ))}
                </div>
                <Styled.Filters>{props.children}</Styled.Filters>
              </Styled.Heatmap>
              {props.showLegend && generateLegend(minMaxCount)}
            </>
          )}
        </>
      )}
    </Styled.Container>
  );
};
