import styled from "styled-components";
import { Editable } from "@chakra-ui/react";

const TextFiledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const EditableWrapper = styled(Editable)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const DecimalInputWrapper = styled.input`
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
`;

export const Styled = {
  TextFiledWrapper,
  EditableWrapper,
  DecimalInputWrapper,
};
