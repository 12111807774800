import React, { useMemo } from "react";
import { RsaTableColumn } from "../tables/types";
import { BetSidesEnum, OrderType, RsaBet, RsaRunner } from "../../types";
import { Utils } from "../tables";
import { useWhatIfs } from "./utils";
import { StakeMaker, BettingControls, SetLay } from "./betting-tools";
import { Styled } from "./styles";
import { useBetsStore, useGetBets } from "../../store";
import { IconButton, Stack, Switch, Tooltip } from "@chakra-ui/react";
import { BiEraser, BiMailSend, BiSolidMessageAltX } from "react-icons/bi";
import { ProfitLoss } from "./betting-tools/profil-lost";
import { AvailableBet, BSPBox } from "./betting-tools/available-bet";

export const useGetColumns = (
  market: any,
  isTraining: boolean,
  runners: RsaRunner[],
  orders: any[],
  onSubmitOrders: (bets: RsaBet[]) => void,
  onUpdateUnmatched: (cancel: boolean, bets?: RsaBet[]) => void
): {
  columns: RsaTableColumn[];
} => {
  const addBets = useBetsStore((state: any) => state.add);
  const clearBets = useBetsStore((state: any) => state.clear);
  const userBets = useGetBets(market?.marketId);

  const whatifs: any = useWhatIfs(useGetBets, market?.marketId, runners);

  const hasUnmatched = useMemo(() => {
    let unmatched = orders?.flatMap((o: any) => o.unmatched) || [];
    return unmatched.length > 0;
  }, [orders]);

  const betsUpdated = (bet: RsaBet) => {
    addBets(market.marketId, bet);
  };

  const columns: RsaTableColumn[] = [
    {
      key: "faveNo",
      name: "#",
      width: 10,
      renderHeaderCell(props) {
        return <div className="left">{props.column.name}</div>;
      },
    },
    {
      key: "name",
      name: "Name",
      resizable: true,
      width: 150,
      renderHeaderCell(props) {
        return <div className="left">{props.column.name}</div>;
      },
      renderCell({ row }: { row: any }) {
        return (
          <Styled.Runner>
            <Styled.JockyAvatar src={`${row.img}`} alt={row.name} />
            <Styled.RunnerDetails>
              <Styled.HorseName>{row.name}</Styled.HorseName>
              <Styled.JockyName>{row.jockey}</Styled.JockyName>
            </Styled.RunnerDetails>
          </Styled.Runner>
        );
      },
    },
    {
      key: "bak1",
      name: "Back",
      width: 80,
      renderCell({ row }: { row: any }) {
        return (
          <AvailableBet
            row={row}
            cell={row.bak1}
            side={BetSidesEnum.Back}
            onSubmitOrders={onSubmitOrders}
          />
        );
      },
    },
    {
      key: "bsp",
      name: "BSP",
      width: 60,
      renderCell({ row }: { row: any }) {
        return <BSPBox bsp={row.bsp} />;
      },
    },
    {
      key: "lay1",
      name: "Lay",
      width: 80,
      renderCell({ row }: { row: any }) {
        return (
          <AvailableBet
            row={row}
            cell={row.lay1}
            side={BetSidesEnum.Lay}
            onSubmitOrders={onSubmitOrders}
          />
        );
      },
    },
    {
      key: "what-id",
      name: "What If £",
      width: 80,
      renderCell({ row }: { row: any }) {
        let order = orders?.filter(
          (o: any) => o.selectionId === row.selectionId
        );
        let wifs0 = Number(
          order && order.length > 0 ? order[0]?.ifWin || 0 : 0
        );
        let wifs1 = Utils.roundDecimal(whatifs[row.faveNo] || 0);
        return (
          <Styled.WhatIfsWraper>
            {wifs0 !== 0 && (
              <Styled.WhatIfs $neg={wifs0 < 0}>£{wifs0}</Styled.WhatIfs>
            )}
            <div>
              {wifs0 !== 0 && wifs1 !== 0 && <span>+</span>}
              {wifs1 !== 0 && (
                <Styled.WhatIfs $neg={wifs1 < 0}>£{wifs1}</Styled.WhatIfs>
              )}
            </div>
          </Styled.WhatIfsWraper>
        );
      },
    },
    {
      key: "mybet",
      name: "My Bets",
      width: 275,
      renderHeaderCell(props) {
        return (
          <Stack direction="row">
            {isTraining && market.in_result && <ProfitLoss />}
            {(!isTraining || !market.in_result) && (
              <>
                <div style={{ height: "24px", marginTop: "-4px" }}>
                  <IconButton
                    size="sm"
                    fontSize={20}
                    variant="unstyled"
                    aria-label="Submit"
                    icon={<BiMailSend />}
                    style={{ color: "#007bff" }}
                    onClick={() => onSubmitOrders(userBets)}
                  >
                    Submit
                  </IconButton>
                  <span style={{ display: "inline" }}>Submit</span>
                </div>
                <Switch onChange={() => {}} />
                <span>Auto</span>
                <Tooltip label="Clear All" aria-label="Clear All">
                  <IconButton
                    size="sm"
                    fontSize={20}
                    variant="unstyled"
                    aria-label="Clear"
                    icon={<BiEraser />}
                    style={{ color: "#007bff", height: "20px" }}
                    onClick={() => clearBets(market.marketId)}
                  >
                    Clear
                  </IconButton>
                </Tooltip>
                <span style={{ display: "inline", marginLeft: "-20px" }}>
                  All
                </span>
              </>
            )}
          </Stack>
        );
      },
      renderCell({ row }: { row: RsaRunner }) {
        let betId = `${market.marketId}:${row.faveNo}-default`;
        let bet0 = userBets.find((b: any) => b.id === betId && b.userBet);
        let def: RsaBet = {
          id: betId,
          size: 0,
          side: BetSidesEnum.Back,
          faveNo: row.faveNo,
          price: row.bsp.value,
          bsp: row.bsp.value,
          orderType: OrderType.LIMIT,
          userBet: true,
        };

        return (
          <>
            {!market.in_result ? (
              <StakeMaker
                label={row.faveNo}
                key={row.faveNo}
                defaultValue={def}
                value={bet0}
                defaultIncr={0.1}
                active={row.highlight}
                startSize={false}
                onChanges={betsUpdated}
              />
            ) : /WINNER/i.test(row.status) ? (
              row.status
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      key: "setlay",
      name: "",
      renderCell({ row }: { row: any }) {
        return (
          <>
            {!market.in_result && (
              <SetLay
                title="Set Lay"
                marketId={market.marketId}
                faveNo={row.faveNo}
                bsp={row.bsp.value}
              />
            )}
          </>
        );
      },
    },
    {
      key: "bets",
      name: "",
      width: 180,
      renderHeaderCell(props) {
        return (
          <Stack direction="row">
            {hasUnmatched && (
              //@Nasim: fix the style
              <div>
                <Tooltip
                  label="Cancel All unmatched"
                  aria-label="Cancel All unmatched"
                >
                  <IconButton
                    size="sm"
                    fontSize={20}
                    variant="unstyled"
                    aria-label="Add Bet"
                    icon={<BiSolidMessageAltX />}
                    style={{ color: "#ffae00", height: "20px" }}
                    onClick={() => onUpdateUnmatched(true)}
                  />
                </Tooltip>
                <span
                  style={{
                    display: "inline",
                    marginLeft: "-10px",
                    paddingRight: "10px",
                  }}
                >
                  Unmatched
                </span>
              </div>
            )}
          </Stack>
        );
      },
      renderCell({ row }: { row: any }) {
        return (
          <>
            {!market.in_result && (
              <BettingControls
                key={`betting-buttons-${market.marketId}:${row.faveNo}`}
                marketId={market.marketId}
                faveNo={row.faveNo}
                bsp={row.bsp.value}
                orders={orders?.filter(
                  (o: any) => o.selectionId === row.selectionId
                )}
                bets={userBets.filter(
                  (b: any) => b.faveNo === row.faveNo && b.size > 0
                )}
                onUpdateUnmatched={onUpdateUnmatched}
                runners={runners}
              />
            )}
          </>
        );
      },
    },
  ];

  return { columns };
};
