import {
  BETFAIR_MARKET_UPDATE,
  TRAINING_MARKET_UPDATE,
  templateString,
} from "../endpoints";
import { bfRequest, request, RequestMethod } from "../requests";
import { useAccessTokenStore, useBfAccessTokenStore } from "../../store";
import { MutateOptions, useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

export const useGetMarketUpdate = (
  options: MutateOptions<unknown, AxiosError, any, any>
) => {
  const accessToken = useAccessTokenStore((state: any) => state.accessToken);
  const bfAccessToken = useBfAccessTokenStore(
    (state: any) => state.accessToken
  );

  const getMarketUpdate = async (options: {
    marketId: string;
    includebets: boolean;
    isSimulate?: boolean;
  }) => {
    if (options.isSimulate) {
      return request(
        templateString(TRAINING_MARKET_UPDATE, { marketId: options.marketId }),
        { method: RequestMethod.GET },
        accessToken()
      );
    }

    return await bfRequest(
      `${BETFAIR_MARKET_UPDATE}/${options.marketId}${
        options.includebets ? "?includebets=true" : ""
      }`,
      { method: RequestMethod.GET },
      accessToken(),
      bfAccessToken()
    ).then((res: any) => {
      return { ...res, timestamp: Date.now() };
    });
  };

  return useMutation({
    mutationFn: getMarketUpdate,
    mutationKey: ["get-market-update"],
    ...options,
  });
};
