import React from "react";
import { DateUtils } from "../../utils/dateutils";

export const UserUtils = (function () {

  const displayCreditCard = function (card: { brand: string; last4: string; }) {
    if (card) {
      return (<span>{`${card.brand} ending with ${card.last4}`}</span>)
    }
    return <></>;
  };

  const subscriptionActive = function (subscription: any) {
    if (subscription &&
      subscription.type === 'recur' &&
      !subscription.period_ends) {
      return true;
    }
    return false;
  }

  const trialCustomer = function (customer: any) {
    if (customer.trial) {
      return (
        <>
          <span>{customer.trial.type === 'trial' ? 'FREE Trial' : 'Starter'}</span>

          <span>{customer.trial.remains > 0 ? `Expires in ${Math.round(customer.trial.remains)} days` : 'Expired'}</span>
        </>
      );
    }
  }

  const displaySubscription = function (subscription: any) {
    if (subscription.type === "trial") {
      return (
        <>
          <span>FREE Trial</span>
          <span>{`Expires ${DateUtils.fromTimestamp(
            subscription.expires
          )}`}</span>
        </>
      );
    } else if (subscription.type === "starter") {
      return (
        <>
          <span>20 Days starter</span>

          <span>{`Expires ${DateUtils.fromTimestamp(
            subscription.expires
          )}`}</span>
        </>
      );
    } else if (subscription.type === "prepaid") {
      return (
        <>
          <span>{subscription.desc}</span>
          <span>{`${subscription.remains} remains`}</span>
        </>
      );
    } else {
      return (
        <>
          <span>{`${subscription.desc} Subscription.`}</span>
          <br />
          <span>
            {subscription.remains
              ? `Cancels in ${Math.round(subscription.remains)} days`
              : subscription.period_ends
                ? `Ends on ${DateUtils.fromTimestamp(subscription.expires)}`
                : `Next billing: ${DateUtils.fromTimestamp(subscription.due)}`}
          </span>
        </>
      );
    }
  };

  //   const validateTelegramId = function (username, $this) {
  //     let v = /@[a-zA-Z]+[a-zA-Z0-9]*/.exec(username) ? true : false;
  //     //$this.removeClass('error');
  //     if (!v) {
  //       //$this.addClass('error');
  //     }
  //     return v;
  //   };

  //   const show_telegramid = function (telegramid) {
  //     $('#user-acct-table .telegram-id .telegram-add').text(telegramid);
  //     $('.telegram-id input.telegram-set[type="text"]').val(telegramid);
  //     $(".telegram-id .telegram-add").show();
  //     $(".telegram-id .telegram-set").hide();
  //   };

  //   const add_chatroom = function (username, $this) {
  //     if (validateTelegramId(username, $this)) {
  //       let spinner = new Spinner('.telegram-id');
  //       spinner.show();
  //       raceService.update_user({ "custom:telegram": username }, (res) => {
  //           spinner.hide();
  //           if (res && res["custom:telegram"]) {
  //               show_telegramid(res["custom:telegram"]);
  //           }
  //       });
  //     }
  //   };

  //     $('#stripe-portal-btn').click(function (event) {
  //         event.preventDefault();
  //         raceService.user_portal((data) => {
  //             if (data && data.url) {
  //                 window.open(data.url, "_blank", "directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,height=1100px,width=1100px");
  //             }
  //             else {
  //                 Lookups.warning('Payment Control', 'No customer found! please contact support.');
  //             }
  //         });
  //     });

  //     $(".telegram-id a.telegram-set").click(function (event) {
  //         add_chatroom($('.telegram-id input.telegram-set[type="text"]').val(), $('.telegram-id input.telegram-set[type="text"]'));
  //     });
  // });

  const subs = function (plan: string) {
    let subs = /(\w+)\+(\w+)/i.exec(plan);
    if (subs) {
      return {
        frq: subs[1],
        type: subs[2],
      };
    }

    return null;
  };

  const subs_level = function (user: any) {
    if (user && user.subscription) {
      let plan = subs(user.subscription.plan);
      return plan ? plan.type : null;
    }

    return null;
  };

  const has_permission = function (user: any, permission: string, callback: (arg0: any) => any) {
    let valid = false;
    if (user) {
      let subscription =
        user.subscriptions && user.subscriptions.length > 0
          ? user.subscriptions[0]
          : user.subscription;
      let permissions =
        subscription && subscription.permissions
          ? subscription.permissions
          : {};
      valid = permissions[permission];
    }

    // if (!valid && callback) {
    //     let buttons = {
    //         "Cancel": function () {
    //             callback(false);
    //             $(this).dialog("close");
    //         },
    //         "Upgrade": function () {
    //             $(this).dialog("close");
    //             window.location.replace('/plans.html');
    //         }
    //     };

    //     return Lookups.warning('No Subscription', `Your subscription does not have ${permission}, To Subscribe now please Click Upgrade otherwise click Cancel.`, buttons);
    // }

    return callback ? callback(valid) : valid;
  };

  return {
    displaySubscription,
    subscriptionActive,
    trialCustomer,
    displayCreditCard,
    subs,
    subs_level,
    has_permission,
  };
})();
