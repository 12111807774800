import React from "react";
import { useTrainingPnLStore } from "../../../store";
import { Styled } from "./styles";

export const ProfitLoss = () => {
  const pnlData = useTrainingPnLStore((state: any) => state.pnl);

  return (
    <>
      <Styled.PnLWrapper>
        <Styled.PnLLabel>W: </Styled.PnLLabel>
        <Styled.PnLWon>{pnlData.won}</Styled.PnLWon>
        <Styled.PnLLabel>L: </Styled.PnLLabel>
        <Styled.PnLLost>{pnlData.lost}</Styled.PnLLost>
        <Styled.PnLLabel>P: </Styled.PnLLabel>
        <Styled.PnLProfit $neg={pnlData.profit < 0}>
          {pnlData.profit}
        </Styled.PnLProfit>
      </Styled.PnLWrapper>
    </>
  );
};
