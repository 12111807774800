import styled, { css } from "styled-components";

const TableContainer = styled.div<{ $size?: "sm" | "md" | "lg" }>`
  .rdg-header-row {
    .rdg-cell:first-child {
      width: 50px;
    }
  }
  .rdg-cell {
    ${(props) => {
      switch (props.$size) {
        case "sm": {
          return css`
            font-size: 9px;
            padding: 0px;
          `;
        }
        case "md": {
          return css`
            font-size: 10px;
          `;
        }
        default:
        case "lg": {
          return css`
            font-size: 12px;
            padding: 2px;
          `;
        }
      }
    }}
  }
`;

const TableLoadingWrapper = styled.div`
border - radius: 8px;
box - shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
height: 300px;
padding: 24px;
min-width: 250px;
`;

const MVTableContainer = styled.div<{ $size?: "sm" | "md" | "lg" }>`
  .rdg-cell {
    > div {
      height: 100%;
      padding: ${(props) => (props.$size === "sm" ? "10px" : "5px 18px")};
      margin: ${(props) => (props.$size === "sm" ? "1px" : "0px")};
    }
  }
  .rdg-money {
    .rdg-cell {
      .negative {
        background: pink;
      }
      .positive {
        background: #add8e6;
      }
    }
  }

  .fav1RowClass {
    .rdg-cell {
      background-color: #ff0;
      color: #000;
      border: 1px solid #fff;
      .negative {
        color: #ff0027;
      }
    }

    &:hover .rdg-cell {
      background-color: #ffae00;
    }
  }

  .fav2RowClass {
    .rdg-cell {
      background-color: #ff0;
      color: #000;
      border: 1px solid #fff;
      .negative {
        color: #ff0027;
      }
    }

    &:hover .rdg-cell {
      background-color: #ffae00;
    }
  }

  .favOtherRowClass {
    .rdg-cell {
      background-color: navy;
      color: #fff;
      border: 1px solid #fff;
      .negative {
        color: #6af06a;
      }
    }

    &:hover .rdg-cell {
      background-color: rgb(0, 41, 128);
    }
  }

  .fav1CellClass {
    background-color: #ff0;
  }

  .fav2CellClass {
    background-color: #ffae00;
  }
`;

export const Styled = {
  TableContainer,
  TableLoadingWrapper,
  MVTableContainer,
};
