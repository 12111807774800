export const schema_v1 = {
  type: "object",
  patternProperties: {
    "^s[0-9]+$": {
      type: "object",
      properties: {
        name: {
          type: "object",
          properties: {
            type: { type: "string", enum: ["text"] },
            value: { type: "string" },
          },
          required: ["type", "value"],
        },
        wk: {
          type: "object",
          properties: {
            type: { type: "string", enum: ["range"] },
            plus: { type: "number" },
            minus: { type: "number" },
          },
          required: ["type", "plus", "minus"],
        },
        ran: {
          type: "object",
          properties: {
            type: { type: "string", enum: ["range"] },
            plus: { type: "number" },
            minus: { type: "number" },
          },
          required: ["type", "plus", "minus"],
        },
        dow: {
          type: "object",
          properties: {
            type: { type: "string", enum: ["switch"] },
            value: { type: "boolean" },
          },
          required: ["type", "value"],
        },
        trk: {
          type: "object",
          properties: {
            type: { type: "string", enum: ["switch"] },
            value: { type: "boolean" },
          },
          required: ["type", "value"],
        },
        dis: {
          type: "object",
          properties: {
            type: { type: "string", enum: ["switch"] },
            value: { type: "boolean" },
          },
          required: ["type", "value"],
        },
        type: {
          type: "object",
          properties: {
            type: { type: "string", enum: ["switch"] },
            value: { type: "boolean" },
          },
          required: ["type", "value"],
        },
        cry: {
          type: "object",
          properties: {
            type: { type: "string", enum: ["switch"] },
            value: { type: "boolean" },
          },
          required: ["type", "value"],
        },
        jfh: {
          type: "object",
          properties: {
            type: { type: "string", enum: ["switch"] },
            value: { type: "boolean" },
          },
          required: ["type", "value"],
        },
      },
      required: [
        "name",
        "wk",
        "ran",
        "dow",
        "trk",
        "dis",
        "type",
        "cry",
        "jfh",
      ],
    },
  },
  additionalProperties: false,
};
