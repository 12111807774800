import React, { useState } from "react";
import { Switch } from "@chakra-ui/react";
import { FilterValueEnum } from "../../types";
import { useFilterStore } from "../../store";
import { UseBoundStore } from "zustand/react";
import { StoreApi } from "zustand/vanilla";

export const RsaSwitch = (props: {
  size?: "sm" | "md" | "lg";
  colorScheme?: string;
  isChecked?: boolean;
  storekey: string;
  storage?: UseBoundStore<StoreApi<unknown>>;
}) => {
  const { storekey, storage, ...rest } = props;
  const dataStore = (storage || useFilterStore)((state: any) =>
    state.get(storekey)
  );
  const store = (storage || useFilterStore)((state: any) => state.store);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    store(storekey, FilterValueEnum.boolean, event.target.checked);
  };

  return <Switch onChange={onChange} isChecked={dataStore} {...rest} />;
};
