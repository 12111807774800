import { Utils } from "../utils";
import { YEAROFFSET } from "../../../types";

export const dsDaysMap: any = {
  Day1: "Sun",
  Day2: "Mon",
  Day3: "Tue",
  Day4: "Wed",
  Day5: "Thu",
  Day6: "Fri",
  Day7: "Sat",
};

const addLiveWinners = (dailyData: any, faveData0: any) => {
  let faveData = JSON.parse(JSON.stringify(faveData0));
  let daysid = Object.keys(dailyData || {});

  daysid.forEach((dayid) => {
    let dayrgx = /^Live_(Day\d+)$/i.exec(dayid);
    if (dayrgx) {
      let dayix = dayrgx[1];
      (dailyData[dayid] || []).forEach((element: any) => {
        if (element.Winner && element.C_year) {
          if (!faveData[dayix]) {
            faveData[dayix] = {};
          }
          if (!faveData[dayix][element.Winner]) {
            faveData[dayix][element.Winner] = {};
          }
          faveData[dayix][element.Winner][element.C_year] =
            (faveData[dayix][element.Winner][element.C_year] || 0) + 1;
        }
      });
    }
  });
  return faveData;
};

const addDaySheet = function (
  fave_results_raw: any,
  day_results: any,
  years_total: any,
  thisYear: number
) {
  let fave_results = JSON.parse(JSON.stringify(fave_results_raw));
  let fave_numbers: number[] = Object.keys(fave_results)
    .filter((f) => /total/i.exec(f) == null)
    .map((f) => parseInt(f));
  let winners: any = {};
  let maxYear0 = new Date().getUTCFullYear();
  let maxYear = thisYear || maxYear0;
  let minYear = maxYear0 - YEAROFFSET; //metadata.yearOffset();
  (day_results || []).forEach((e: any) => {
    if (e.Winner) {
      if (!winners[e.Winner]) {
        winners[e.Winner] = 0;
      }
      winners[e.Winner]++;
    }
  });

  let noofraces = day_results ? day_results.length : 0;
  let b25: any = {},
    b251: number[] = [],
    sum = 0;
  let valid_years: number[] = [];
  let count = 0;

  for (let yr = minYear; yr <= maxYear; yr++) {
    if (years_total[yr] && years_total[yr].Total > 0) {
      valid_years.push(yr);
      if (fave_results.total[yr] != undefined) {
        sum += fave_results.total[yr];
        count++;
        b25[yr] = fave_results.total[yr]
          ? noofraces / fave_results.total[yr]
          : 0;
        b251.push(b25[yr]);
      }
    }
  }

  let min = Math.min(...b251);
  let max = Math.max(...b251);
  let avg = sum / count;
  let over = noofraces - avg;
  count = 0;
  b251.forEach((b) => {
    if (b != min && b != max) {
      count++;
    }
  });

  let fave = 1;
  let fave_results_shadow: any = { total: {} };
  //for(fave = 1; fave <= 30; fave++) {
  fave_numbers.forEach((fave) => {
    if (fave_results[fave]) {
      fave_results_shadow[fave] = {};
      fave_results_shadow[`plus${fave}`] = {};
      //for(yr = minYear; yr < maxYear; yr++) {
      valid_years.forEach((yr) => {
        if (fave_results[fave][yr] != undefined) {
          fave_results_shadow[fave][yr] =
            (fave_results[fave][yr] || 0) * (b25[yr] || 0);
          fave_results_shadow.total[yr] =
            (fave_results_shadow.total[yr] || 0) +
            fave_results_shadow[fave][yr];
          fave_results_shadow[`plus${fave}`][yr] = noofraces
            ? fave_results_shadow[fave][yr] / noofraces
            : 0;
          fave_results_shadow[`plus${fave}`].total =
            (fave_results_shadow[`plus${fave}`].total || 0) +
            fave_results_shadow[`plus${fave}`][yr];
        }

        fave_results_shadow[`plus${fave}`].count =
          (fave_results_shadow[`plus${fave}`].count || 0) + 1;
      });
    }
  });

  let total_plusYear = 0;
  let total_year = 0;
  let total_exp_fave12 = 0;
  let total_act_fave12 = 0;

  fave_numbers.forEach((fave) => {
    if (fave_results[fave]) {
      let index = `plus${fave}`;

      if (fave_results_shadow[index] != undefined) {
        fave_results_shadow[index].avg =
          fave_results_shadow[index].total /
          (fave_results_shadow[index].count || 1);
      }

      var plusYear = fave_results_shadow[index].avg * noofraces;
      fave_results[fave][`qsMaxYearqs`] = plusYear;
      fave_results[fave][`${maxYear}`] = winners[fave] || 0;
      total_year += fave_results[fave][`${maxYear}`];

      if (fave >= 1 && fave < 3) {
        total_exp_fave12 += plusYear;
        total_act_fave12 += fave_results[fave][`${maxYear}`];
      }

      let diff =
        plusYear >= 1 ? fave_results[fave][`${maxYear}`] / plusYear : 0;
      let variance = fave_results[fave][`${maxYear}`] - plusYear;
      total_plusYear += plusYear || 0;

      fave_results[fave][`var`] = variance;
    } else {
      fave_results[fave] = {};
    }
  });

  fave_numbers.forEach((fave) => {
    let ws =
      total_year > 0 ? (fave_results[fave][`${maxYear}`] || 0) / total_year : 0;
    fave_results[fave][`ws`] = ws ? `${Utils.formatDecimal(ws * 100, 1)}%` : "";
  });

  fave_results.total[`qsMaxYearqs`] = total_plusYear;
  fave_results.total[`${maxYear}`] = total_year;
  let diffTotal =
    total_plusYear && fave_results.total[`${maxYear}`]
      ? fave_results.total[`${maxYear}`] / total_plusYear
      : 0;

  const summary = {
    actual: {
      faves: total_year !== 0 ? (total_act_fave12 / total_year) * 100 : 0,
      outsiders: total_year - total_act_fave12,
    },
    expected: {
      faves: (total_exp_fave12 / total_plusYear) * 100,
      outsiders: ((total_plusYear - total_exp_fave12) / total_plusYear) * 100,
    },
  };

  return { faves: fave_results, summary, shadow: null };
};

const addWeekSheet = function (
  fave_results_shadow_raw: any,
  noofraces: number,
  years_total: any,
  thisYear?: number
) {
  let fave_results_shadow = JSON.parse(JSON.stringify(fave_results_shadow_raw));
  let fave_numbers: number[] = Object.keys(fave_results_shadow)
    .filter((f) => /total/i.exec(f) == null)
    .map((f) => parseInt(f));

  let b25: any = {},
    b251: number[] = [],
    sum = 0;
  let maxYear0 = new Date().getUTCFullYear();
  let maxYear = thisYear || maxYear0;
  let minYear = maxYear0 - YEAROFFSET; //metadata.yearOffset();
  let count = 0;

  let valid_years: number[] = [];

  for (let yr = minYear; yr < maxYear; yr++) {
    if (years_total[yr] && years_total[yr].Total > 0) {
      valid_years.push(yr);

      if (fave_results_shadow.total[yr] != undefined) {
        sum += fave_results_shadow.total[yr];
        count++;
        b25[yr] = fave_results_shadow.total[yr]
          ? noofraces / fave_results_shadow.total[yr]
          : 0;
        b251.push(b25[yr]);
      }
    }
  }

  let min = Math.min(...b251);
  let max = Math.max(...b251);
  let avg = sum / count;
  let over = noofraces - avg;
  count = 0;
  b251.forEach((b) => {
    if (b != min && b != max) {
      count++;
    }
  });

  let fave = 1;
  let fave_results: any = { total: {} };
  fave_numbers.forEach((fave) => {
    if (fave_results_shadow[fave]) {
      fave_results[fave] = {};
      valid_years.forEach((yr) => {
        if (fave_results_shadow[fave][yr] != undefined) {
          fave_results[fave][yr] =
            (fave_results_shadow[fave][yr] || 0) * (b25[yr] || 0);
          fave_results.total[yr] =
            (fave_results.total[yr] || 0) + fave_results[fave][yr];
        }
      });
    }
  });

  // we do it in another loop because total is available here
  fave_numbers.forEach((fave) => {
    if (fave_results_shadow[fave]) {
      fave_results[`week${fave}`] = {};
      valid_years.forEach((yr) => {
        if (fave_results_shadow[fave][yr] != undefined) {
          fave_results[`week${fave}`][yr] = fave_results.total[yr]
            ? fave_results[fave][yr] / fave_results.total[yr]
            : 0;
          fave_results[`week${fave}`].total =
            (fave_results[`week${fave}`].total || 0) +
            fave_results[`week${fave}`][yr];
          //fave_results[fave][yr] = Math.round(fave_results[fave][yr]);
        }
        fave_results[`week${fave}`].count =
          (fave_results[`week${fave}`].count || 0) + 1;
      });
    }
  });

  let total_plusYear = 0;
  let total_year = 0;
  let total_exp_fave12 = 0;
  let total_act_fave12 = 0;
  fave_numbers.forEach((fave) => {
    if (fave_results_shadow[fave]) {
      let index = `week${fave}`;

      if (fave_results[index] != undefined) {
        fave_results[index].avg =
          fave_results[index].total / (fave_results[index].count || 1);
      }

      let plusYear = fave_results[index].avg * noofraces;
      fave_results[fave][`qs${maxYear}qs`] = plusYear;
      fave_results[fave][`${maxYear}`] =
        fave_results_shadow[fave][`${maxYear}`] || 0;
      total_year += fave_results[fave][`${maxYear}`];

      if (fave >= 1 && fave < 3) {
        total_exp_fave12 += plusYear;
        total_act_fave12 += fave_results[fave][`${maxYear}`];
      }

      let diff =
        plusYear >= 1 ? fave_results[fave][`${maxYear}`] / plusYear : 0;
      let variance = fave_results[fave][`${maxYear}`] - plusYear;
      total_plusYear += plusYear || 0;

      fave_results[fave][`diff`] = diff
        ? `${Utils.formatDecimal(diff * 100, 1)}%`
        : "";
      fave_results[fave][`var`] = variance;
    }

    if (!fave_results[fave]) {
      fave_results[fave] = {};
    }
  });
  fave_numbers.forEach((fave) => {
    let ws =
      total_year > 0 ? (fave_results[fave][`${maxYear}`] || 0) / total_year : 0;
    fave_results[fave][`ws`] = ws ? `${Utils.formatDecimal(ws * 100, 1)}%` : "";
  });

  fave_results.total[`qs${maxYear}qs`] = total_plusYear;
  fave_results.total[`${maxYear}`] = total_year;
  let diffTotal =
    total_plusYear && fave_results.total[`${maxYear}`]
      ? fave_results.total[`${maxYear}`] / total_plusYear
      : 0;
  fave_results.total[`diff`] = diffTotal
    ? `${Utils.formatDecimal(diffTotal * 100, 1)}%`
    : "";

  const summary = {
    actual: {
      faves: total_year !== 0 ? (total_act_fave12 / total_year) * 100 : 0,
      outsiders: total_year - total_act_fave12,
    },
    expected: {
      faves: (total_exp_fave12 / total_plusYear) * 100,
      outsiders: ((total_plusYear - total_exp_fave12) / total_plusYear) * 100,
    },
  };

  return { faves: fave_results, shadow: fave_results_shadow, summary };
};

export const prepareDSData = (
  thisYear: number,
  dailyData: any,
  faveData_raw: any
) => {
  let dayKeys = Object.keys(faveData_raw || {}).filter(
    (key) => !/years|week/i.test(key)
  );

  let faveData = addLiveWinners(dailyData, faveData_raw);
  let favesData_out: any = { runners: {} };
  let all_runners = 0;
  Object.keys(dailyData).forEach((e) => {
    favesData_out.runners[e] = dailyData[e].length;
    all_runners += dailyData[e].length;
  });

  dayKeys.forEach((day) => {
    favesData_out[day] = addDaySheet(
      faveData[day],
      dailyData[day],
      faveData.years,
      thisYear
    );
  });

  favesData_out.Week = addWeekSheet(
    faveData.Week,
    all_runners,
    faveData.years,
    thisYear
  );

  favesData_out.years = faveData_raw.years
    ? JSON.parse(JSON.stringify(faveData_raw.years))
    : {};

  return favesData_out;
};

export const getToDateWeek = (
  thisYear: number,
  days: string[],
  faveData: any
) => {
  let all_runners = 0;
  let faves_data: any = { total: {} };

  all_runners = 0;
  days.forEach((day) => {
    if (faveData && faveData[day]) {
      let faves = faveData[day]?.faves;
      all_runners += faveData.runners ? faveData.runners[day] || 0 : 0;

      Object.keys(faves).forEach((f) => {
        if (!faves_data[f]) {
          faves_data[f] = {};
        }

        Object.keys(faves[f]).forEach((y) => {
          faves_data[f][y] = (faves_data[f][y] || 0) + faves[f][y];
        });
      });
    }
  });

  const { shadow, faves } = addWeekSheet(
    faves_data,
    all_runners,
    faveData.years,
    thisYear
  );

  return { shadow, faves };
};
