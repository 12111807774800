export const schema_v2 = {
  type: "object",
  properties: {
    version: {
      type: "number",
    },
    filters: {
      type: "object",
      patternProperties: {
        "^s[0-9]+$": {
          type: "object",
          properties: {
            name: {
              type: "string",
            },
            week: {
              type: "object",
              properties: {
                from: {
                  type: ["number", "null"],
                },
                to: {
                  type: ["number", "null"],
                },
              },
              required: ["from", "to"],
            },
            ran: {
              type: "object",
              properties: {
                from: {
                  type: "number",
                },
                to: {
                  type: "number",
                },
              },
              required: ["from", "to"],
            },
            dow: {
              type: "boolean",
            },
            track: {
              type: "boolean",
            },
            distance: {
              type: "boolean",
            },
            type: {
              type: "boolean",
            },
            state: {
              type: "boolean",
            },
            jumpflat: {
              type: "boolean",
            },
          },
          required: [
            "name",
            "week",
            "ran",
            "dow",
            "track",
            "distance",
            "type",
            "state",
            "jumpflat",
          ],
        },
      },
      additionalProperties: false,
    },
  },
  required: ["version", "filters"],
  additionalProperties: false,
};
