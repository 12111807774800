import { BETFAIR_ORDERS_MARKET, templateString } from "../endpoints";
import { bfRequest, RequestMethod } from "../requests";
import { useAccessTokenStore, useBfAccessTokenStore } from "../../store";
import { MutateOptions, useMutation } from "@tanstack/react-query";
import { RsaBet } from "../../types";
import { AxiosError } from "axios";

export const useCancelOrders = (
  options: MutateOptions<
    unknown,
    AxiosError,
    { marketId: string; bets: RsaBet[] },
    any
  >
) => {
  const accessToken = useAccessTokenStore((state: any) => state.accessToken);
  const bfAccessToken = useBfAccessTokenStore(
    (state: any) => state.accessToken
  );

  const cancelOrders = async (
    payload: { marketId: string; bets: RsaBet[] }
  ) => {
    const url = templateString(BETFAIR_ORDERS_MARKET, { marketId: payload.marketId });

    return await bfRequest(
      url,
      { method: RequestMethod.DELETE, data: payload.bets },
      accessToken(),
      bfAccessToken()
    );
  };

  return useMutation({
    mutationFn: cancelOrders,
    mutationKey: ["cancel-orders"],
    ...options,
  });

};
