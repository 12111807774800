import React from "react";
import { HStack, TagLabel, TagRightIcon } from "@chakra-ui/react";
import { BiEditAlt } from "react-icons/bi";
import { BetSidesEnum, RsaBet } from "../../../types";
import { Styled } from "./styles";

export enum ActiveBetMode {
  Unmatched,
  Local,
}

export const ActiveBets = (props: {
  bets: RsaBet[];
  mode: ActiveBetMode;
  onClick: () => void;
}) => {
  const { bets } = props;
  const lays = bets.filter((b) => b.side === BetSidesEnum.Lay).length;
  const backs = bets.filter((b) => b.side === BetSidesEnum.Back).length;

  return (
    <>
      {bets.length > 0 && (
        <HStack spacing={4} style={{ margin: "9px 0 0 0" }}>
          <Styled.ActiveBetsTag
            $side={
              lays > 0 && backs > 0
                ? BetSidesEnum.None
                : lays > 0
                  ? BetSidesEnum.Lay
                  : BetSidesEnum.Back
            }
            $unmatched={props.mode === ActiveBetMode.Unmatched}
            size="sm"
            onClick={props.onClick}
          >
            <TagLabel>
              {backs > 0 && (
                <Styled.ActiveBetsBadge
                  $side={BetSidesEnum.Back}
                  $size="sm"
                  $unmatched={props.mode === ActiveBetMode.Unmatched}
                >
                  {backs}
                </Styled.ActiveBetsBadge>
              )}{" "}
              {lays > 0 && (
                <Styled.ActiveBetsBadge
                  $side={BetSidesEnum.Lay}
                  $size="sm"
                  $unmatched={props.mode === ActiveBetMode.Unmatched}
                >
                  {lays}
                </Styled.ActiveBetsBadge>
              )}
            </TagLabel>
            <TagRightIcon as={BiEditAlt} />
          </Styled.ActiveBetsTag>
        </HStack>
      )}
    </>
  );
};
