import { create } from "zustand";

export const useWorkerStore = create((set, get) => ({
  worker: null,
  listeners: {},
  setworker: (w: any) =>
    set(() => {
      return { worker: w };
    }),
  addListener: (name: string, fn: (event: any) => {}) =>
    set((state: any) => {
      if (state.worker && window.Worker) {
        
        if(state.listeners[name]) {
          state.worker.removeEventListener("message", state.listeners[name]);
        }

        state.listeners[name] = fn;
        state.worker.addEventListener("message", fn);
      }
      return Object.assign({}, state);
    }),
    removeListener: (name: string) =>
      set((state: any) => {
        if (state.worker && window.Worker) {
          state.worker.removeEventListener("message", state.listeners[name]);
          delete state.listeners[name];
        }
        return Object.assign({}, state);
      }),    
  clear: () => set({ worker: null }, true),
}));
