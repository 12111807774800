export const defaultLayout: any = {
  lg: [
    {
      i: "heatmap",
      x: 0,
      y: 0,
      w: 10,
      h: 12,
    },
    {
      i: "betmaker",
      x: 14,
      y: 0,
      w: 14,
      h: 12,
      minW: 5,
      maxW: 12,
    },
    {
      i: "sbb",
      x: 0,
      y: 12,
      w: 10,
      h: 24,
    },
    {
      i: "qfinder",
      x: 14,
      y: 12,
      w: 14,
      h: 24,
    },
  ],
  sm: [
    {
      i: "betmaker",
      x: 0,
      y: 0,
      w: 16,
      h: 12,
      minW: 5,
    },
    {
      i: "heatmap",
      x: 0,
      y: 12,
      w: 16,
      h: 12,
    },
    {
      i: "sbb",
      x: 0,
      y: 24,
      w: 16,
      h: 22,
    },
    {
      i: "qfinder",
      x: 0,
      y: 46,
      w: 16,
      h: 22,
    },
  ],
};
