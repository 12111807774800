import styled, { css } from "styled-components";

export enum CompareBoxType {
  Fave,
  Second,
  Outsiders,
  Total,
}

const ComparetBox = styled.div`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  width: 60px;
  overflow: hidden;
  padding: 0px;
  font-size: 10px;
  font-weight: bold;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid gray;
  color: #235db3;
  text-align: center;
  background: #eee;
`;

const ComparetBoxTitle = styled.div<{ $type: CompareBoxType }>`
  line-height: 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: block;
  padding: 0px 2px;
  border: 0px solid gray;
  border-bottom: none;
  text-align: center;
  ${(props) => {
    switch (props.$type) {
      case CompareBoxType.Fave:
        return css`
          background: green;
          color: #fff;
        `;
      case CompareBoxType.Second:
        return css`
          background: yellow;
          color: #000;
        `;
      case CompareBoxType.Outsiders:
        return css`
          background: navy;
          color: #fff;
        `;
      default:
      case CompareBoxType.Total:
        return css`
          background: gray;
          color: #fff;
        `;
    }
  }};
`;

const ComparetBoxContent = styled.div<{ $value: number }>`
  ${({ $value }) =>
    $value === 5
      ? css`
          background-color: orange;
          color: black;
        `
      : $value < 5 && $value > 0
      ? css`
          background-color: red;
          color: white;
        `
      : css``}
`;

const ComparetBoxSubTitle = styled.span`
  border-top: 1px solid #000;
  padding: 1px 0 0;
  display: block;
`;

export const Styled = {
  ComparetBox,
  ComparetBoxTitle,
  ComparetBoxContent,
  ComparetBoxSubTitle,
};
