import { FilterValueEnum } from "../types";

const set_filter = (
  state: any,
  id: string,
  type: FilterValueEnum,
  value: any
) => {
  const convert = (value: any) => {
    switch (type) {
      case FilterValueEnum.string:
        return value;
      case FilterValueEnum.int:
        return parseInt(value);
      case FilterValueEnum.float:
        return parseFloat(value);
      default:
        return value;
    }
  };

  let cval =
    value instanceof Array
      ? value.length > 0
        ? value.map((item) => convert(item))
        : undefined
      : convert(value);

  const [sys, key, segment] = id.split(".");
  if (!state.filters[sys]) {
    state.filters[sys] = {};
  }

  if (segment) {
    if (!state.filters[sys][key]) {
      state.filters[sys][key] = {};
    }
    state.filters[sys][key][segment] = cval;
  } else if (key) {
    state.filters[sys][key] = cval;
  } else {
    state.filters[sys] = cval;
  }

  return state;
};

const get_filter = (state: any, id: string) => {
  const [sys, key, segment] = id.split(".");

  if (segment) {
    return state.filters[sys] && state.filters[sys][key]
      ? state.filters[sys][key][segment]
      : "";
  } else if (key) {
    return state.filters[sys] ? state.filters[sys][key] : "";
  }
  return state.filters[sys];
};

const clear_filter = (state: any, id: string) => {
  const [sys, key, segment] = id.split(".");

  if (segment) {
    if (state.filters[sys] && state.filters[sys][key]) {
      delete state.filters[sys][key][segment];
    }
  } else if (key) {
    if (state.filters[sys]) {
      delete state.filters[sys][key];
    }
  } else delete state.filters[sys];

  return state;
};

export { set_filter, get_filter, clear_filter };
