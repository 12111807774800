import React, { useEffect, useMemo, useState } from "react";
import { useCurrentSystemStore } from "../../../store";
import { HeatmapTable } from "../heatmap";
import { heatmapData } from "./heatmapData";
import { SbbUtils } from "./utils";
import { RsaNumberSlider } from "../../slider/number-slider";

export const Heatmap = (props: {
  data: any;
  filter: number;
  bsps: number[];
  ran: number;
  loading: boolean;
  onHighlightChanged: (highlight: number[]) => void;
}) => {
  const { data, filter, bsps, ran } = props;
  const currentSystemKey = useCurrentSystemStore((state: any) => state.sys);

  const [filterPcngt, setFilterPcngt] = useState(20);
  const [filterFave, setFilterFave] = useState(10);

  const actualData = useMemo(() => {
    return data instanceof Array
      ? data?.find((d) => d.system === currentSystemKey)
      : data;
  }, [data, currentSystemKey]);

  const {
    faves: rows,
    xAxisLabels,
    yAxisLabels,
    highlights,
  } = useMemo(() => {
    let { rows: data } = actualData
      ? SbbUtils.getRows(actualData, filter)
      : { rows: [] };
    return (
      (data &&
        data.length > 0 &&
        SbbUtils.prepareHMData(data, bsps, ran, filter, {
          filter_pcngt: filterPcngt,
          filter_fave: filterFave,
        })) || { faves: [], xAxisLabels: [], yAxisLabels: [], highlights: [] }
    );
  }, [
    actualData?.digest,
    filter,
    filterPcngt,
    filterFave,
    ran,
    JSON.stringify(bsps),
  ]);

  useEffect(() => {
    props.onHighlightChanged(highlights || []);
  }, [highlights]);

  return (
    <div>
      <HeatmapTable
        orientation="horizontal"
        data={rows}
        highlights={highlights}
        xAxisLabels={xAxisLabels}
        yAxisLabels={yAxisLabels}
        loading={props.loading}
      >
        <RsaNumberSlider
          marks={heatmapData.filters_pcntg}
          defaultValue={filterPcngt}
          onChange={setFilterPcngt}
        />
        <RsaNumberSlider
          marks={heatmapData.filters_faves}
          defaultValue={filterFave}
          onChange={setFilterFave}
        />
      </HeatmapTable>
      {/* <HeatmapTable
        key={`hm-${currentSystemKey}`}
        title={"HeatMap"}
        filter={filter}
        data={data}
        bsps={bsps}
        system={currentSystemKey}
        columns={columns}
        ran={ran}
        loading={props.loading}
        filterFave={filterFave}
        filterPcngt={filterPcngt}
        onHighlightChanged={props.onHighlightChanged}
      /> */}
    </div>
  );
};
