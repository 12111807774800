import React, { useEffect, useState } from "react";
import { Styled } from "./styles";
import { BetSidesEnum, ValueDirEnum } from "../../../types";
import { BiCaretDown, BiCaretUp } from "react-icons/bi";
import { Input, useNumberInput } from "@chakra-ui/react";
import { NumericFormat } from "react-number-format";
import { PriceUtils } from "../../../utils/prices";

export const ValueControl = (props: {
  defaultValue: number;
  side: BetSidesEnum;
  active?: boolean;
  enabled?: boolean;
  onChanges: (value: any) => void;
}) => {
  const {
    defaultValue,
    active = false,
    side,
    onChanges,
    enabled = true,
  } = props;

  const [value, setValue] = useState<any>(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const changePrice = (curValue: number, dir: ValueDirEnum): number => {
    if (dir === ValueDirEnum.Up) {
      return PriceUtils.next(curValue);
    } else {
      return PriceUtils.previous(curValue);
    }
  };

  function changeStage(dir: ValueDirEnum): void {
    if (!enabled) return;

    let newValue = changePrice(value, dir);

    setValue(newValue);
    onChanges(newValue);
  }

  return (
    <>
      <Styled.TriangleBtn
        $side={side}
        $active={active}
        $enabled={enabled}
        onClick={() => changeStage(ValueDirEnum.Up)}
      >
        {enabled ? <BiCaretUp /> : <div style={{ width: "40px" }}> </div>}
      </Styled.TriangleBtn>
      <Styled.StakeInput $side={side}>
        <NumericFormat
          value={value}
          readOnly={!enabled}
          onChange={(event) => {
            let value = event.target.value;
            setValue(value);
            onChanges(value);
          }}
        />
      </Styled.StakeInput>

      <Styled.TriangleBtn
        $side={side}
        $active={active}
        $enabled={enabled}
        onClick={() => changeStage(ValueDirEnum.Down)}
      >
        {enabled ? <BiCaretDown /> : <div style={{ width: "40px" }}> </div>}
      </Styled.TriangleBtn>
    </>
  );
};

export const CurrencyValueControl = (props: {
  defaultValue: number;
  side: BetSidesEnum;
  active?: boolean;
  enabled?: boolean;
  percentage?: boolean;
  precision?: number;
  min?: number;
  max?: number;
  step?: number;
  onChanges: (value: number) => void;
}) => {
  const {
    defaultValue,
    active = false,
    side,
    percentage,
    precision = 2,
    step = 0.1,
    min,
    max,
    onChanges,
    enabled = true,
  } = props;

  const sign = percentage ? `%` : "£";
  const format = (val: any) => `${sign}${val}`;
  const parse = (val: any) => val.replace(new RegExp(`^\\${sign}`), "");

  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step,
      value: percentage ? defaultValue * 100 : defaultValue,
      format: format,
      min,
      max,
      precision,
      onChange: (valueString) => {
        onChanges(Number(parse(valueString)) / (percentage ? 100 : 1));
      },
    });

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  return (
    <>
      <Styled.TriangleBtn
        $side={side}
        $active={active}
        $enabled={enabled}
        {...inc}
      >
        {enabled ? <BiCaretUp /> : <div style={{ width: "40px" }}> </div>}
      </Styled.TriangleBtn>
      <Styled.StakeInput $side={side}>
        <Input {...input} />
      </Styled.StakeInput>

      <Styled.TriangleBtn
        $side={side}
        $active={active}
        $enabled={enabled}
        {...dec}
      >
        {enabled ? <BiCaretDown /> : <div style={{ width: "40px" }}> </div>}
      </Styled.TriangleBtn>
    </>
  );
};
