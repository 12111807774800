import React from "react";
import { RsaTableProps } from "../types";
import { SimpleTable } from "../simple";
import { MultiValueTable } from "../multi-value";
import { Utils } from "../utils";

export const FavTable = (props: RsaTableProps & { simple?: boolean }) => {
  const { options = {}, simple = false, ...rest } = props;

  options.vh_formatter = (value: any, row: any) => {
    if (/\d+/.test(value)) {
      let num: number = parseInt(value);
      const remainder = num % 10;
      const remainderHundred = num % 100;
      if (remainder === 1 && remainderHundred !== 11) {
        return `${num}st`;
      } else if (remainder === 2 && remainderHundred !== 12) {
        return `${num}nd`;
      } else if (remainder === 3 && remainderHundred !== 13) {
        return `${num}rd`;
      } else {
        return `${num}th`;
      }
    }

    return value;
  };

  const rowClass = (row: any, index: number) => {
    switch (index) {
      case 0:
        return "favRowClass fav1RowClass";
      case 1:
        return "favRowClass fav2RowClass";
      default:
        return "favRowClass favOtherRowClass";
    }
  };

  return (
    <>
      {simple ? (
        <SimpleTable
          {...rest}
          options={options}
          keyField="Fav"
          rowClass={rowClass}
        />
      ) : (
        <MultiValueTable
          {...rest}
          options={options}
          keyField="Fav"
          rowClass={rowClass}
        />
      )}
    </>
  );
};

export const FavSheetTable = (props: RsaTableProps) => {
  const { options = {}, ...rest } = props;

  options.formatter = (name: string, value: any) => {
    if (name === "qsMaxYearqs" || name === "var") {
      return Utils.formatDecimal(value);
    }
    return `${Utils.roundInt(value)}`;
  };

  let maxYear = new Date().getUTCFullYear();
  let firstTwoDigits = maxYear.toString().match(/^(\d{2})(\d{2})/)?.[2];
  const additionalColumns = [
    ...(options.additionalColumns || []),
    { key: "var", name: "Var" },
    //{ key: "ws", name: "Ws" },
  ];

  const optionsX = { ...options, additionalColumns };

  return <FavTable options={optionsX} simple={true} {...rest} />;
};
