import React, { useMemo, useState } from "react";
import { UseBoundStore } from "zustand/react";
import { StoreApi } from "zustand/vanilla";
import { Box, Text, Flex, Switch } from "@chakra-ui/react";
import { useFilterStore } from "../../store";
import { RsaSelectProps } from "./types";
import { FilterValueEnum } from "../../types";

export const MultiSelector = (props: RsaSelectProps) => {
  const { key, storekey, storage, datatype, label, size, items, ...rest } =
    props;

  const dataStore: number[] = (storage || useFilterStore)((state: any) =>
    state.get(storekey)
  );
  const store = (storage || useFilterStore)((state: any) => state.store);

  const toggleValue = (dayId: number) => {
    let values = dataStore || [];
    values = values.includes(dayId)
      ? values.filter((id) => id !== dayId)
      : [...values, dayId];
    store(storekey, datatype, values.length > 0 ? values : undefined);
  };

  const selectedDays = useMemo(
    () => (dataStore || []).map((item) => item),
    [dataStore]
  );

  return (
    <Box>
      <Text fontSize={size} mb={2}>
        {label}
      </Text>
      <Box>
        {items.map((item) => (
          <Flex key={item.id} align="center" justify="space-between" mb={2}>
            <Text fontSize={size}>{item.label}</Text>
            <Switch
              isChecked={selectedDays?.includes(item.id as number)}
              onChange={() => toggleValue(item.id as number)}
              colorScheme="blue"
            />
          </Flex>
        ))}
      </Box>
    </Box>
  );
};

export interface RsaWeeklySelectProps {
  label?: string;
  placeholder: string;
  isDisabled?: boolean;
  size?: "xs" | "sm" | "md" | "lg";
  storekey: string;
  storage?: UseBoundStore<StoreApi<unknown>>;
}

export const WeekdaySelector = (props: RsaWeeklySelectProps) => {
  const daysOfWeek = [
    { id: 1, label: "Sunday" },
    { id: 2, label: "Monday" },
    { id: 3, label: "Tuesday" },
    { id: 4, label: "Wednesday" },
    { id: 5, label: "Thursday" },
    { id: 6, label: "Friday" },
    { id: 7, label: "Saturday" },
  ];
  return (
    <MultiSelector
      items={daysOfWeek}
      datatype={FilterValueEnum.string}
      {...props}
    />
  );
};
