import { RaceDataType, YEAROFFSET } from "../../types";

export const getNumbers = (from: number, count: number) => {
  let numbers = [];
  let current = from;
  for (let nx = 0; nx < count; nx++) {
    numbers.push(current + nx);
  }

  return numbers;
};

export const getYears = (small: boolean = false) => {
  let numbers = [];
  let current = new Date().getFullYear();
  for (let nx = current - YEAROFFSET; nx <= current; nx++) {
    numbers.push(small ? { y: nx, sy: nx - 2000 } : nx);
  }

  return numbers;
};

export const extractFiltersfromSystem = (
  raceData: RaceDataType,
  currentSystemKey: string,
  currentSystem: any
) => {
  let dow = raceData.M_dow;
  let woy = raceData.I_week;
  let filters: any = { ref: raceData.id, system: currentSystemKey };

  if (currentSystem) {
    if (currentSystem.week) {
      filters.week = {};

      //minus
      if ("to" in currentSystem.week) {
        let wkt_minus = woy - currentSystem.week.to;
        filters.week.from = wkt_minus > 0 ? wkt_minus : 53 + wkt_minus;
      }

      //plus
      if ("from" in currentSystem.week) {
        let wk_plus = woy + currentSystem.week.from;
        filters.week.to =
          ((woy + currentSystem.week.from) % 54) + (wk_plus > 53 ? 1 : 0);
      }
    }

    if (currentSystem.ran) {
      let market_ran = raceData.A_ran;

      filters.ran = {};

      //minus
      if ("to" in currentSystem.ran) {
        let ran_from = market_ran - currentSystem.ran.to;
        filters.ran.from = ran_from > 0 ? ran_from : 1;
      }

      //plus
      if ("from" in currentSystem.ran) {
        let ran_to = market_ran + currentSystem.ran.from;
        filters.ran.to = ran_to <= 50 ? ran_to : 50;
      }
    }

    if (currentSystem.dow) {
      filters.dow = [dow];
    }

    if (currentSystem.track) {
      filters.track = [raceData.G_track];
    }

    if (currentSystem.type) {
      filters.type = [raceData.E_type];
    }

    if (currentSystem.distance) {
      filters.distance = [raceData.D_dis];
    }

    if (currentSystem.state) {
      filters.state = raceData.H_country;
    } else {
      filters.state = "";
    }

    if (currentSystem.jumpflat) {
      filters.jumpflat = raceData.jumpflat;
    } else {
      filters.jumpflat = "";
    }
  }

  return filters;
};
