import styled, { keyframes, css } from "styled-components";

const getWidth = (
  size: "xs" | "sm" | "md" | "lg" | undefined,
  open: boolean
) => {
  switch (size) {
    case "xs":
      return open ? "100vw" : "40px";
    case "sm":
      return open ? "250px" : "40px";
    case "md":
      return open ? "3000px" : "60px";
    case "lg":
    default:
      return open ? "350px" : "80px";
  }
};

const slideIn = keyframes<{ $size?: "xs" | "sm" | "md" | "lg" }>`
  from {
    width: ${({ $size }) => getWidth($size, false)};
  }
  to {
    width: ${({ $size }) => getWidth($size, true)};
  }
`;

const slideOut = keyframes<{ $size?: "xs" | "sm" | "md" | "lg" }>`
  from {
    width: ${({ $size }) => getWidth($size, true)};
  }
  to {
    width: ${({ $size }) => getWidth($size, false)};
  }
`;

const slideInMobile = keyframes<{ $size?: "xs" | "sm" | "md" | "lg" }>`
  from {
    width: 40px;
  }
  to {
    width: 100vw;
  }
`;

const slideOutMobile = keyframes<{ $size?: "xs" | "sm" | "md" | "lg" }>`
  from {
    width: 100wv;
  }
  to {
    width: 40px;
  }
`;

const DrawerWrapper = styled.div<{
  $isOpen: boolean;
  $size?: "xs" | "sm" | "md" | "lg";
}>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: ${({ $size, $isOpen }) => getWidth($size, $isOpen)};
  overflow-x: hidden;
  transition: width 0.5s;
  z-index: 1000;
  background: white;
  box-shadow: 1px 0 5px rgba(0, 0, 0, 0.5);
  ${({ $isOpen }) =>
    $isOpen
      ? css`
          animation: ${slideIn} 0.5s forwards;
        `
      : css`
          animation: ${slideOut} 0.5s forwards;
        `}

  @media (max-width: 768px) {
    width: ${({ $isOpen }) => getWidth("xs", $isOpen)};
    ${({ $isOpen }) =>
      $isOpen
        ? css`
            animation: ${slideInMobile} 0.5s forwards;
          `
        : css`
            animation: ${slideOutMobile} 0.5s forwards;
          `}
  }
`;

const DrawerContent = styled.div`
  position: relative;
  height: 100%;
  padding: 16px;
  display: block;
`;

const DrawerHeader = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
`;

const CloseButton = styled.button<{ $size?: "xs" | "sm" | "md" | "lg" }>`
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  border: 1px solid #c4baba;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ $size }) => ($size === "sm" ? "24px" : "40px")};
  height: ${({ $size }) => ($size === "sm" ? "24px" : "40px")};
`;

const OpenButton = styled.button<{ $size?: "xs" | "sm" | "md" | "lg" }>`
  border: 1px solid #c4baba;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${({ $size }) => ($size === "sm" ? "8px" : "16px")};
  width: ${({ $size }) => ($size === "sm" ? "24px" : "40px")};
  height: ${({ $size }) => ($size === "sm" ? "24px" : "40px")};
  @media (max-width: 768px) {
    width: 24px;
    height: 24px;
    margin: 8px;
  }
`;

export const Styled = {
  DrawerWrapper,
  DrawerContent,
  CloseButton,
  DrawerHeader,
  OpenButton,
};
