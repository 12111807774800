import React, { useState, useEffect } from "react";
import {
  About,
  Contact,
  Gallery,
  Header,
  Team,
  Testimonials,
  Features,
  Pricing,
  Navigation,
} from "./components";
import { data } from "./data";
import "./bootstrap.css";
import SmoothScroll from "smooth-scroll";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

export const Home = () => {
  interface LandingPageData {
    Header?: any;
    Features?: any;
    About?: any;
    Pricing?: any;
    Gallery?: any;
    Testimonials?: any;
    Team?: any;
    Contact?: any;
  }

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  return (
    <div>
      <Navigation width={screenSize.dynamicWidth} />
      <Header data={data.Header} />
      <Features data={data.Features} width={screenSize.dynamicWidth} />
      <About data={data.About} />
      <Pricing data={data.Pricing} screenSize={screenSize} />
      <Gallery data={data.Gallery} />
      <Testimonials data={data.Testimonials} screenSize={screenSize} />
      <Team data={data.Team} />
      <Contact data={data.Contact} />
    </div>
  );
};
