import React, { useState } from "react";
import {
  RsaMultiSelect,
  RsaSelect,
  prepareData,
  getNumbers,
} from "../dropdown";
import { RsaRangeSelect, RsaRangeNumber } from "../range";
import { FilterValueEnum } from "../../types";

import {
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";

import { useLocation } from "react-router-dom";
import { routes } from "../../routes/consts";

import { SegmentedControl } from "../segmented/control";
import { ResultSegmentEnum } from "../../types";
import { useResultsSegmentStore } from "../../store";
import { Systems } from "../../components/systems";

interface RsaFiltersProps {
  lookupData: any;
}

export const RsaFilters: React.FC<RsaFiltersProps> = (props) => {
  const { lookupData } = props;
  const location = useLocation();
  const isRacingRoute = location.pathname === routes.RACING;
  const [isOpen, setIsOpen] = useState(false);

  let resultsSegments = [
    { value: ResultSegmentEnum.winners, label: "Winners" },
    { value: ResultSegmentEnum.losers, label: "Losers" },
    { value: ResultSegmentEnum.money, label: "Money" },
  ];

  return (
    <>
      <Stack shouldWrapChildren direction="column">
        {/* {isRacingRoute && <> do what you gotta do here</>} */}
        <Box>
          <SegmentedControl
            items={resultsSegments}
            storage={useResultsSegmentStore}
          />
        </Box>
        <RsaMultiSelect
          label="Year"
          items={prepareData(getNumbers(new Date().getFullYear() - 10, 11))}
          placeholder="Select Year"
          searchPlaceholder="Search Year"
          size="sm"
          storekey="years"
          datatype={FilterValueEnum.int}
        />
        <RsaRangeSelect
          label="Week"
          storekey="week"
          items={prepareData(getNumbers(1, 53))}
          size="sm"
          datatype={FilterValueEnum.int}
          placeholder="All"
        />
        <RsaMultiSelect
          label="Day of Week"
          items={prepareData(getNumbers(1, 7))}
          placeholder="Select Day"
          searchPlaceholder="Search Day"
          size="sm"
          datatype={FilterValueEnum.int}
          storekey="dow"
        />
        <RsaRangeSelect
          label="Set No. Ran"
          storekey="ran"
          items={prepareData(getNumbers(1, 55))}
          size="sm"
          datatype={FilterValueEnum.int}
          placeholder="All"
        />
        <RsaRangeSelect
          label="Race No"
          storekey="raceno"
          items={prepareData(getNumbers(1, 200))}
          size="sm"
          datatype={FilterValueEnum.int}
          placeholder="All"
        />
        <RsaMultiSelect
          label="Track"
          items={lookupData.track || []}
          placeholder="Select Track"
          searchPlaceholder="Search Track"
          size="sm"
          datatype={FilterValueEnum.string}
          storekey="track"
        />
        <RsaMultiSelect
          label="Distance"
          items={lookupData.dis || []}
          placeholder="Select Distance"
          searchPlaceholder="Search Distance"
          size="sm"
          datatype={FilterValueEnum.string}
          storekey="distance"
        />
        <RsaMultiSelect
          label="Type"
          items={lookupData.type || []}
          placeholder="Select Type"
          searchPlaceholder="Search Type"
          size="sm"
          datatype={FilterValueEnum.string}
          storekey="type"
        />
        <Accordion allowMultiple>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  IP & SP
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <RsaRangeNumber
                label="IP % High"
                size="xs"
                storekey="iphp"
                precision={2}
              />
              <RsaRangeNumber
                label="IP £ High"
                size="xs"
                prefix="£"
                storekey="iphm"
                precision={2}
              />
              <RsaRangeNumber
                label="Set IP"
                size="xs"
                storekey="ip"
                defaultValueF={1002}
                defaultValueT={0}
                precision={2}
              />
              <RsaRangeNumber
                label="Set SP"
                size="xs"
                storekey="sp"
                defaultValueF={1}
                defaultValueT={1002}
                precision={2}
              />
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Fave & BSP
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <RsaRangeNumber
                label="Set the Fav BSP"
                size="xs"
                storekey="favebsp"
                precision={2}
              />
              <RsaRangeNumber
                label="Set 2nd Fav BSP"
                size="xs"
                storekey="fave2bsp"
                precision={2}
              />
              <RsaRangeSelect
                label="Set Fav (i.e started as 6th fav)"
                storekey="faveno"
                items={prepareData(getNumbers(1, 20))}
                size="sm"
                datatype={FilterValueEnum.int}
                placeholder="All"
              />
              <RsaRangeNumber
                label="Top 4 %"
                size="xs"
                storekey="top4"
                precision={2}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <RsaSelect
          label="IRE/UK"
          items={prepareData(["GB", "IRE"])}
          placeholder="All"
          size="sm"
          storekey="state"
          datatype={FilterValueEnum.string}
        />
        <RsaSelect
          label="Jump/Flat"
          items={prepareData(["Flat", "Jump"])}
          placeholder="All"
          size="sm"
          storekey="jumpflat"
          datatype={FilterValueEnum.string}
        />
      </Stack>
    </>
  );
};
