import React, { useState } from "react";
import { RsaSelect } from "../dropdown/select";
import { Stack } from "@chakra-ui/react";
import { FilterValueEnum } from "../../types";
import { RsaResetFilter } from "../filters";
import { GStyled } from "../../styles/global";

interface RsaRangeSelectProps {
  label?: string;
  fromLabel?: string;
  toLabel?: string;
  storekey: string;
  datatype: FilterValueEnum;
  [key: string]: any;
}

export const RsaRangeSelect = (props: RsaRangeSelectProps) => {
  const {
    label,
    storekey,
    items,
    fromLabel,
    toLabel,
    hasSwitch,
    placeholder,
    size,
    ...rest
  } = props;
  const [disabled, setDisabled] = useState(false);

  return (
    <>
      <GStyled.Stack $dir="column" $spacing={2}>
        <strong>{label}</strong>
        <GStyled.Stack $dir="row" style={{ alignItems: "center", gap: "2px" }}>
          {fromLabel ? <div>{fromLabel}</div> : <></>}
          <div
            style={{
              width: `${size === "xs" ? "75px" : "85px"}`,
              display: "inline-flex",
            }}
          >
            <RsaSelect
              storekey={`${storekey}.from`}
              items={items}
              placeholder={placeholder}
              isDisabled={disabled}
              size={size}
              style={
                size == "xs"
                  ? { fontSize: "0.85em", width: 55, marginLeft: -5 }
                  : {}
              }
              {...rest}
            />
          </div>
          <div style={{ padding: "0px 0px 0px 5px" }}>{toLabel || "To"} </div>
          <div
            style={{
              width: `${size === "xs" ? "75px" : "85px"}`,
              display: "inline-flex",
            }}
          >
            <RsaSelect
              storekey={`${storekey}.to`}
              items={items}
              placeholder={placeholder}
              isDisabled={disabled}
              size={size}
              style={size === "xs" ? { fontSize: "0.85em", width: 55 } : {}}
              {...rest}
            />
          </div>
          <RsaResetFilter size="sm" storekey={storekey} {...rest} />
        </GStyled.Stack>
      </GStyled.Stack>
    </>
  );
};
