import React from "react";
import { useDisclosure } from "@chakra-ui/react";
import { RsaAlertDialog } from "../../popup";
import {
  BetSidesEnum,
  OrderType,
  PriceType,
  RsaBet,
  SPTypesEnum,
} from "../../../types";
import { useBetMakerStakesStore } from "../../../store";
import { Utils } from "../../tables";
import { Styled } from "./styles";

export const BSPBox = (props: {
  bsp: { class: SPTypesEnum; value: number };
}) => {
  return (
    <Styled.SPBox $spType={props.bsp.class}>
      <div className="bsp">{Utils.formatDecimal(props.bsp.value)}</div>
    </Styled.SPBox>
  );
};

export const AvailableBet = (props: {
  row: any;
  cell: any;
  side: BetSidesEnum;
  onSubmitOrders: (bets: RsaBet[]) => void;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { row, cell, side, onSubmitOrders } = props;
  const dataStore = useBetMakerStakesStore((state: any) => state);

  const size = dataStore[side];
  // const message = `Are you sure you want to place a bet on #${row.faveNo} - "${row.name}"?`;
  // const labelConfirm = {
  //   text: `${side === BetSidesEnum.Lay ? "Lay" : "Back"} £${size} / ${
  //     cell?.price
  //   }`,
  //   color: side === BetSidesEnum.Lay ? "#f6c9d4" : "#a7d8fc",
  // };

  const onInstantBetAction = (confirm: boolean) => {
    if (confirm) {
      onSubmitOrders([
        {
          faveNo: row.faveNo,
          selectionId: row.selectionId,
          side: side,
          bsp: cell.price,
          price: cell.price,
          size: size,
          orderType: OrderType.LIMIT,
          priceType: PriceType.Fixed,
          userBet: false,
        },
      ]);
    }
    onClose();
  };
  return (
    <Styled.SPBox
      $spType={SPTypesEnum.Taken}
      $side={side}
      onClick={() => {
        onInstantBetAction(true);
      }}
    >
      {/* <RsaAlertDialog
        isOpen={isOpen}
        onClose={onInstantBetAction}
        title="Instant Bet"
        message={message}
        labelConfirm={labelConfirm}
      /> */}
      <div className="sp price">{Utils.formatDecimal(cell?.price)}</div>
      <div className="sp size">
        {cell?.size ? `£${Utils.roundInt(cell?.size)}` : ""}
      </div>
    </Styled.SPBox>
  );
};
