import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

if (process.env.REACT_APP_NODE_ENV === "development") {
  (window as any).setBfk = (token: any) => {
    let jsonText = atob(token);
    let json = JSON.parse(jsonText);
    let bfk = { state: { data: token, expiresAt: json.expiresAt }, version: 0 };
    window.localStorage.setItem("_bfk", JSON.stringify(bfk));
  };
}

const rootElement = document.getElementById("root");
const root = rootElement
  ? ReactDOM.createRoot(rootElement)
  : { render: () => {}, unmount: () => {} };

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
