export const routes = {
  LOGIN: "/login",
  LOGOUT: "/login",
  SIGN_UP: "/sign-up",
  FORGOT_PASSWORD: "/forgot-password",
  APP: "/",
  HOME: "/",
  PROFILE: "/profile",
  BETFINDER: "/finder",
  QUICKFINDER: "/finder/quick",
  DAYSHEET: "/finder/daysheet",
  RACING: "/racing",
};
