import { useMemo } from "react";
import { RsaBet, BetSidesEnum } from "../../../types";

export const useWhatIfs = (
  getBets: (marketId: string, faveNo?: number) => RsaBet[],
  marketId: string,
  runners: any
): any => {
  var self: {
    orders: { [key: string]: any };
    commission: number;
    runners: { [key: string]: any };
  } = { orders: {}, commission: 0, runners: {} };

  const bets = getBets(marketId);

  const update = function (orders0: { orders: any[] }, runners: any[]) {
    let orders = orders0 && orders0.orders ? orders0.orders : [];
    orders.forEach((o) => {
      self.orders[o.selectionId] = o.ifWin;
    });

    (runners || []).forEach((r) => {
      self.runners[r.favNo] = r;
    });
  };

  const reset = function () {
    self = { orders: {}, commission: 0, runners: {} };
  };

  const lookup_selection = function (favNo: number) {
    if (self.runners && self.runners[favNo]) {
      return self.runners[favNo];
    }
    return null;
  };

  const execute = function (bets: RsaBet[]) {
    let commissionJ = 1 - self.commission / 100;

    let ifw = 0;
    let ifl = 0;
    bets.forEach((element) => {
      let e = element.side === BetSidesEnum.Lay ? -1 : commissionJ;

      ifw += element.price ? e * (element.price - 1) * element.size : 0;
      ifl += element.size ? -1 * e * element.size : 0;
    });

    return {
      ifw,
      ifl,
    };
  };

  const single = (bets: RsaBet[]) => {
    let wifs = execute(bets);
    let bet0 =
      bets && bets.length > 0
        ? bets[0]
        : { faveNo: 0, side: "", price: 0, size: 0 };
    let faveNo = bet0.faveNo ? bet0.faveNo : lookup_selection(bet0.faveNo);
    if (faveNo && self.orders[faveNo]) {
      wifs.ifw += self.orders[faveNo];
    }

    return wifs;
  };

  const prepare = (bets: RsaBet[]): RsaBet[] => {
    let faves = bets.map((b: RsaBet) => b.faveNo);
    let inits = runners
      .filter((runner: any) => faves.indexOf(runner.faveNo) < 0)
      .map((runner: any) => {
        return {
          id: new Date().getTime().toString(),
          faveNo: runner.faveNo,
          size: 0,
          side: BetSidesEnum.Back,
          price: runner.bsp.value,
        } as RsaBet;
      });

    return [...bets, ...inits];
  };

  const run = (bets: RsaBet[]) => {
    let result: { [key: string]: number } = {};
    let index;
    let map: any = {};
    let wifs: { [key: string]: { ifw: number; ifl: number } } = {};

    bets = prepare(bets);
    //map bets to selection
    for (index = 0; index < bets.length; index++) {
      const element = bets[index];
      if (!map[element.faveNo]) {
        map[element.faveNo] = [];
      }
      map[element.faveNo].push(element);
    }

    let selections = Object.keys(map);
    selections.forEach((s) => {
      wifs[s] = single(map[s]);
    });

    selections.forEach((s1) => {
      let r = 0;
      if (wifs[s1]) {
        r = wifs[s1].ifw;
        selections.forEach((s2) => {
          if (s1 !== s2) {
            r += wifs[s2].ifl;
          }
        });
      }
      result[s1] = r;
    });

    return result;
  };

  update({ orders: [] }, runners);

  return useMemo(() => {
    return run(bets);
  }, [bets]);
};
