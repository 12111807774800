import { useState, useEffect } from "react";

export const useWindow = () => {
  const [windowWidth, setWindowWidth] = useState(window);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowWidth;
};
