import React, { useState, useEffect } from "react";
import styled from "styled-components";
import RSAlogo from "../img/rsa-logo.png";
import { useAccessTokenStore } from "../../../store";
import { routes } from "../../../routes/consts";
import { Link } from "react-router-dom";

type TNavigation = {
  width: number;
};

const Links = [
  { text: "Bet Finder", href: routes.BETFINDER },
  { text: "Racing", href: routes.RACING },
];

const Navbar = styled.nav<{ small: boolean }>`
  position: fixed;
  top: 0;
  width: 100%;
  background: #fff;
  box-shadow: ${({ small }: { small: boolean }) =>
    small ? "0 4px 6px rgba(0, 0, 0, 0.1)" : "none"};
  transition: all 0.3s ease;
  z-index: 1000;
`;

const Container = styled.div<{ small: boolean }>`
  display: flex;
  justify-content: center; /* Center all items horizontally */
  align-items: center; /* Center all items vertically */
  max-width: 1200px;
  margin: 0 auto;
  padding: ${({ small }) => (small ? "1rem" : "2rem")};
  @media (max-width: 768px) {
    padding: 1rem;
    justify-content: flex-end;
  }
`;

const Brand = styled.a<{ small: boolean; width: number }>`
  position: absolute;
  left: 2rem; /* Position the logo to the left */
  display: flex;
  align-items: center;
  top: 10px;
  img {
    height: ${({ small, width }) => (small || width < 768 ? "40px" : "90px")};
    transition: height 0.3s ease;
  }
`;

const NavToggle = styled.button`
  display: none;
  background: none;
  border: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const NavList = styled.ul<{ collapsed: boolean; small: boolean }>`
  display: flex;
  justify-content: center; /* Center items inside the list */
  align-items: center;
  list-style: none;
  gap: 2rem; /* Add spacing between items */

  @media (max-width: 768px) {
    display: ${({ collapsed }: { collapsed: boolean }) =>
      collapsed ? "none" : "flex"};
    flex-direction: column;
    background: #fff;
    position: absolute;
    top: 56px;
    width: 100%;
    padding: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    right: 0;
  }
`;

const NavItem = styled.li`
  a {
    text-decoration: none;
    color: #333;
    font-size: 1rem;
    &:hover {
      color: #007bff;
    }
  }
`;

export const Navigation = ({ width }: TNavigation) => {
  const loggedIn = useAccessTokenStore((state: any) => state.loggedIn());
  const [small, setSmall] = useState(false);
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      setSmall(window.pageYOffset > 200);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Navbar small={small}>
      <Container small={small}>
        <Brand href="#page-top" small={small} width={width}>
          <img alt="" src={RSAlogo} />
        </Brand>
        <NavToggle onClick={() => setCollapsed(!collapsed)}>
          <span>&#9776;</span>
        </NavToggle>
        <NavList collapsed={collapsed} small={small}>
          <NavItem>
            <a href="#features">Features</a>
          </NavItem>
          <NavItem>
            <a href="#about">About</a>
          </NavItem>
          <NavItem>
            <a href="#pricing">Pricing</a>
          </NavItem>
          <NavItem>
            <a href="#portfolio">Gallery</a>
          </NavItem>
          <NavItem>
            <a href="#testimonials">Testimonials</a>
          </NavItem>
          <NavItem>
            <a href="#team">Team</a>
          </NavItem>
          <NavItem>
            <a href="#contact">Contact</a>
          </NavItem>
          {loggedIn ? (
            Links.map((link) => (
              <NavItem key={link.href}>
                <Link to={link.href}>{link.text}</Link>
              </NavItem>
            ))
          ) : (
            <NavItem>
              <Link to="/login">Log in</Link>
            </NavItem>
          )}
        </NavList>
      </Container>
    </Navbar>
  );
};
