import { Outlet, Route } from "react-router-dom";
import {
  Login,
  Home,
  SignUp,
  ForgotPassword,
  Profile,
  BetFinder,
  QuickFinder,
  Racing,
} from "../pages";
import { routes } from "./consts";
import React from "react";
import { ProtectRoutes } from "./protected-routes";
import { Layout } from "../components";
import { DaySheet } from "../pages/finder";

export const appRoutes = [
  <Route element={<Home />} path={routes.HOME} key="home" />,
  <Route element={<Login />} path={routes.LOGIN} key="login" />,
  <Route element={<SignUp />} path={routes.SIGN_UP} key="sign-up" />,
  <Route
    element={<ForgotPassword />}
    path={routes.FORGOT_PASSWORD}
    key="forgot-password"
  />,
  <Route
    element={
      <ProtectRoutes>
        <Layout>
          <Outlet />
        </Layout>
      </ProtectRoutes>
    }
    key="app"
  >
    <Route element={<Profile />} path={routes.PROFILE} />,
    <Route element={<BetFinder />} path={routes.BETFINDER} />,
    <Route element={<Racing />} path={routes.RACING} />,
  </Route>,
  <Route
    element={
      <ProtectRoutes>
        <Outlet />
      </ProtectRoutes>
    }
    key="popup"
  >
    <Route element={<QuickFinder />} path={routes.QUICKFINDER} />,
    <Route element={<DaySheet />} path={routes.DAYSHEET} />,
  </Route>,
];
