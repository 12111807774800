export const templateString = (
  template: string,
  replacements: { [key: string]: string }
) => template.replace(/\{(\w+)\}/g, (_, key) => replacements[key] || "");

export const USER_LOGIN = "/api/user/login";
export const USER_LOGOUT = "/api/user/login";
export const USER_PROFILE = "/api/user/customer";
export const USER_ME = "/api/user/me";
export const USER_PORTAL = "/api/user/portal";

export const LOOKUP_MAIN = "/api/lookups";

export const SUBS_BASE = "/api/user/subcs";

export const SEARCH_QUERY = "/api/query";

export const BETFAIR_MARKET_LIST = "/api/betfair/market";
export const BETFAIR_MARKET_DETAILS = "/market/{marketId}";
export const BETFAIR_MARKET_UPDATE = "/market/update";
export const BETFAIR_LOGIN = "/api/betfair/login";

export const TRAINING_MARKET_LIST = "/api/history/time";
export const TRAINING_MARKET_DETAILS = "/api/history/market?eventid={marketId}";
export const TRAINING_MARKET_UPDATE =
  "/api/history/market?eventid={marketId}&result=true";

export const BETFAIR_ORDER_PLACE_LIVE = "/api/betfair/market/{marketId}/bet";
export const BETFAIR_ORDER_PLACE_SIM =
  "/api/betfair/market/{marketId}/training";
export const BETFAIR_ORDERS_MARKET = "/market/{marketId}/bet";
export const BETFAIR_PROFILE = "/api/betfair/account";

export const DAYSHEET_DAILY =
  "/api/betfair/market/daysheet/{id}?dow={dow}{qtraining}";
export const DAYSHEET_FAVES = "/api/query/fave";
