import { Button } from "@chakra-ui/react";
import React from "react";
import { Uuid } from "../../utils/uuid";

export const ImportFile = (props: {
  label: string;
  ext: string;
  onChange: (data: string) => void;
}) => {
  const { label, ext, onChange } = props;
  const elementId = `input-import-${Uuid.UniqueNumberId()}`;

  const importData = function (event: React.ChangeEvent<HTMLInputElement>) {
    const files = event.target.files;
    if (!files || files.length === 0) {
      console.error("No file selected!");
      return;
    }
    var reader = new FileReader();

    // Handle load
    reader.onload = function (event) {
      if (event?.target?.result) {
        onChange(event.target.result as string);
      }
    };

    // Handle errors
    reader.onerror = function (evt) {
      if (evt?.target?.error?.name === "NotReadableError") {
        console.error("Canno't read file !");
      }
    };

    // Read file into memory as UTF-8
    reader.readAsText(files[0]);
  };

  return (
    <>
      <Button
        onClick={() => {
          const input: any = document.getElementById(elementId);
          if (input) {
            input.value = "";
            input.click();
          }
        }}
      >
        {label}
      </Button>
      <input
        id={elementId}
        name={elementId}
        type="file"
        style={{ display: "none" }}
        accept={ext}
        onChange={importData}
      />
    </>
  );
};
