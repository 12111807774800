import React from "react";
import { SimpleTable } from ".";
import { RsaTableProps } from "./types";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";

const SubGroupTable = (props: RsaTableProps) => {
  const { data, title } = props;

  return (
    <>
      {Object.keys(data ?? {})?.map((key) => {
        return (
          <Accordion allowMultiple key={key}>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    {key}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <SimpleTable
                  data={data[key]}
                  title={key}
                  key={key}
                  keyField={key}
                />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        );
      })}
    </>
  );
};

export const GroupedTable = (props: RsaTableProps & { levels: number }) => {
  const { data, title, levels } = props;

  return (
    <>
      {Object.keys(data ?? {}).map((key) => {
        return (
          <Accordion allowMultiple key={key}>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    {key}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                {levels === 2 ? (
                  <SubGroupTable data={data[key]} title={title} key={key} />
                ) : (
                  <SimpleTable
                    data={data[key]}
                    title={title}
                    key={key}
                    keyField={key}
                  />
                )}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        );
      })}
    </>
  );
};
