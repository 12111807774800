import styled from "styled-components";

const LiveWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: 1024px) {
    /* Tablet size */
    flex-direction: column;
    gap: 16px;
  }

  @media (max-width: 768px) {
    /* Mobile size */
    flex-direction: column;
    gap: 12px;
  }

  @media (max-width: 480px) {
    /* Small mobile size */
    flex-direction: column;
    gap: 8px;
  }
`;

const ListDataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

const FiltersWrapper = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap to the next row */
  gap: 1rem; /* Space between items */
  justify-content: center; /* Center items horizontally */
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FlexItem = styled.div`
  flex: 1 1 calc(50% - 1rem); /* Each item takes 50% of the row minus the gap */
  box-sizing: border-box; /* Ensures padding and border don't affect width */
  background-color: #f4f4f4;
  padding: 1rem;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  @media (max-width: 768px) {
    flex: 1;
    width: 100%;
  }
`;

export const Styled = {
  LiveWrapper,
  ListDataWrapper,
  FiltersWrapper,
  FlexContainer,
  FlexItem,
};
