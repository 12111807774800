import React from "react";
import { CompareBoxType, Styled } from "./styles";
import "./sample.css";
import { Utils } from "../tables/utils";

export enum CompareBoxValueType {
  percentage,
  number,
}

type CompareBoxProps = {
  type: CompareBoxType;
  title: string;
  value: number;
  compareValue: number;
  valueType: CompareBoxValueType;
};

export const CompareBox = (props: CompareBoxProps) => {
  const { type, title, value, compareValue, valueType } = props;
  return (
    <Styled.ComparetBox>
      <Styled.ComparetBoxTitle $type={type}>{title}</Styled.ComparetBoxTitle>
      <Styled.ComparetBoxContent $value={value}>
        <span id="match-criteria" className="">
          {valueType === CompareBoxValueType.number || value === 0
            ? value
            : `${Utils.toFixed(value, 1)}%`}
        </span>
        <Styled.ComparetBoxSubTitle>
          {valueType === CompareBoxValueType.number || compareValue === 0
            ? compareValue
            : `${Utils.toFixed(compareValue, 1)}%`}
        </Styled.ComparetBoxSubTitle>
      </Styled.ComparetBoxContent>
    </Styled.ComparetBox>
  );
};
