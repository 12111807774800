import React from "react";
import { useForm } from "react-hook-form";
import { useUserLogin, useUserRefreshToken } from "../../services";
import { useNavigate } from "react-router-dom";
import { useAccessTokenStore } from "../../store";

import { Button, Text, Box, Stack, Link } from "@chakra-ui/react";

import { Styled } from "./styles";
import { TextField } from "../../components";
import { useState, useEffect } from "react";

import { routes } from "../../routes/consts";
import { STATUS_CODE } from "../../services/status-code";

const FieldValues = {
  USER: "user",
  PASS: "pass",
};

export const Login = () => {
  const loggedIn = useAccessTokenStore((state: any) => state.loggedIn());
  const { data, isLoading: logingInProgress } = useUserRefreshToken();

  const userLogin = useUserLogin({
    onError: (err: any) => {
      if (err.status === STATUS_CODE.BAD_REQUEST) {
        setError("Invalid Username or password");
        setIsLoading(false);
      }
    },
    onNext: () => {
      navigate(routes.BETFINDER);
      setIsLoading(false);
    },
  });

  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm({
    defaultValues: {
      user: "",
      pass: "",
    },
    reValidateMode: "onChange",
  });

  const onSubmit = (credentials: any) => {
    setError("");
    setIsLoading(true);
    userLogin.mutate(credentials);
  };

  useEffect(() => {
    if (loggedIn) {
      navigate(routes.RACING);
    }
  }, [loggedIn, navigate]);

  return (
    <Styled.LoginWrapper>
      <Stack
        spacing={4}
        p="1rem"
        backgroundColor="whiteAlpha.900"
        boxShadow="md"
      >
        <Box minW={{ base: "90%", md: "468px" }}>
          <form className="App" onSubmit={handleSubmit(onSubmit)}>
            <TextField
              isInvalid={errors.user ? true : false}
              id={`${FieldValues.USER}`}
              name={FieldValues.USER}
              error={errors.user && errors.user.message}
              label="Username"
              placeholder="user@mail.com"
              register={register}
            />
            <TextField
              isInvalid={errors.pass ? true : false}
              id={`${FieldValues.PASS}`}
              name={FieldValues.PASS}
              type="password"
              error={errors.pass && errors.pass.message}
              label="Password"
              placeholder="***"
              register={register}
            />

            <Button
              mt={4}
              colorScheme="teal"
              isLoading={isSubmitting || isLoading}
              type="submit"
              style={{ width: "100%" }}
            >
              Login
            </Button>
            <br />
            {!!error && (
              <Text fontSize="14px" color="var(--chakra-colors-red-500)">
                {error}
              </Text>
            )}
          </form>
        </Box>
        <Box>
          Not registered yet?{" "}
          <Link color="teal.500" href={routes.SIGN_UP}>
            Create account here.
          </Link>
        </Box>
        <Box>
          Forgot your password?{" "}
          <Link color="teal.500" href={routes.FORGOT_PASSWORD}>
            Click here to reset.
          </Link>
        </Box>
      </Stack>
    </Styled.LoginWrapper>
  );
};
