import React, { useEffect, useState } from "react";
import { GStyled } from "../../styles/global";
import {
  Slider,
  SliderMark,
  SliderTrack,
  SliderThumb,
  SliderFilledTrack,
} from "@chakra-ui/react";

export const RsaNumberSlider = (props: {
  defaultValue: number;
  marks: number[];
  onChange: (value: number) => void;
}) => {
  const { marks, defaultValue, onChange } = props;

  const labelStyles = {
    mt: "2",
    ml: "-2.5",
    fontSize: "9px",
  };

  const min = Math.min(...marks);
  const max = Math.max(...marks);

  return (
    <GStyled.Box $p={4}>
      <Slider
        min={min}
        max={max}
        step={0.5}
        aria-label="slider-ex-6"
        onChange={onChange}
      >
        {marks.map((mark) => (
          <SliderMark key={mark} value={mark} {...labelStyles}>
            {mark}%
          </SliderMark>
        ))}
        <SliderMark
          value={defaultValue}
          textAlign="center"
          bg="blue.500"
          color="white"
          mt="-6"
          ml="-4"
          w="8"
          fontSize={10}
        >
          {defaultValue}%
        </SliderMark>
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
      </Slider>
    </GStyled.Box>
  );
};
