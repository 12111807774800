import { USER_PROFILE } from "../endpoints";
import { request, RequestMethod } from "../requests";
import { useQuery } from "@tanstack/react-query";
import { useAccessTokenStore } from "../../store";
import { routes } from "../../routes/consts";
import { STATUS_CODE } from "../status-code";
import { DEFAULT_API_CACHE } from "../../types";

export const useGetProfile = () => {
  const accessToken = useAccessTokenStore((state: any) => state.accessToken);

  return useQuery({
    queryFn: () =>
      request(USER_PROFILE, { method: RequestMethod.GET }, accessToken()).catch(
        (err) => {
          if (err?.response?.status === STATUS_CODE.UNAUTHORIZED) {
            window.location.replace(routes.LOGOUT);
          }
          throw err;
        }
      ),
    queryKey: ["profile"],
    staleTime: DEFAULT_API_CACHE
  });
};
