import { useAccessTokenStore, useBfAccessTokenStore } from "../../store";
import { USER_LOGIN } from "../endpoints";
import { request, RequestMethod } from "../requests";
import { useQuery } from "@tanstack/react-query";
import { LoginErrorCodeEnum } from "./login";

export const useUserRefreshToken = () => {
  const { store, refreshToken, needsRefresh, logout } = useAccessTokenStore(
    (state: any) => state
  );
  const bfAccessToken = useBfAccessTokenStore(
    (state: any) => state.accessToken
  );

  const userRefreshToken = async () => {
    if (!needsRefresh()) {
      return { message: "no need to refresh" };
    }

    let refreshTkn = refreshToken();
    if (!refreshTkn) {
      return { error: LoginErrorCodeEnum.NoRefreshToken };
    }

    return await request(
      USER_LOGIN,
      {
        method: RequestMethod.POST,
        data: { refreshToken: refreshTkn },
      },
      undefined,
      bfAccessToken()
    )
      .then((response) => {
        if (!response.refreshToken) {
          response.refreshToken = refreshTkn;
        }
        store(response);
        return response;
      })
      .catch((err) => {
        console.error("refresh token error", err);
        if (err.status === 400) {
          logout();
        }
        throw err;
      });
  };

  return useQuery({
    queryFn: () => userRefreshToken(),
    queryKey: ["user-refresh-token"],
    refetchInterval: 1000 * 20,
    refetchOnWindowFocus: false,
  });
};
