import React from "react";
import { BiSearchAlt } from "react-icons/bi";
type TEmptyRowsRenderer = {
  title?: string;
};
export const EmptyRowsRenderer = ({ title }: TEmptyRowsRenderer) => {
  return (
    <div>
      {!!title && <h1>{title}</h1>}

      <div
        style={{
          textAlign: "center",

          border: "1px solid rgb(181 168 168)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 8,
          boxShadow: "0 6px 20px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fafafa",
          flexDirection: "column",
          margin: 0,
          gap: 8,
          height: 300,
        }}
      >
        <BiSearchAlt size={48} />
        <p>Try searching to find the data you're looking for!</p>
      </div>
    </div>
  );
};
