import React from "react";
import { useFilterStore } from "../../store";
import { FilterValueEnum } from "../../types";
import { DecimalInput } from "../text-field";
import { Styled } from "./styles";

interface RsaRangeNumberProps {
  label: string;
  defaultValueF?: number;
  defaultValueT?: number;
  storekey: string;
  maxW?: number;
  min?: number;
  prefix?: string;
  [key: string]: any;
}

export const RsaRangeNumber = (props: RsaRangeNumberProps) => {
  const { label, defaultValueF, defaultValueT, prefix, storekey, ...rest } = props;

  const storekeyFrom = `${storekey}.from`;
  const storekeyTo = `${storekey}.to`;
  const dataStoreFrom = useFilterStore((state: any) => state.get(storekeyFrom));
  const dataStoreTo = useFilterStore((state: any) => state.get(storekeyTo));
  const store = useFilterStore((state: any) => state.store);

  return (
    <Styled.NumberWrapper {...rest}>
      <label>{label}</label>
      <Styled.NumberRow>
        <Styled.InputWrapper>
          <div>From</div>
          <DecimalInput
            value={dataStoreFrom}
            prefix={prefix}
            onChange={(valueString) => {
              store(storekeyFrom, FilterValueEnum.float, valueString);
            }}
          />
        </Styled.InputWrapper>
        <Styled.InputWrapper>
          <div>To</div>
          <DecimalInput
            value={dataStoreTo}
            prefix={prefix}
            onChange={(valueString) => {
              store(storekeyTo, FilterValueEnum.float, valueString);
            }}
          />
        </Styled.InputWrapper>
      </Styled.NumberRow>
    </Styled.NumberWrapper>
  );
};
