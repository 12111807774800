import { create } from "zustand";
import { FilterValueEnum } from "../../types";
import { persist } from "zustand/middleware";
import { set_filter, get_filter, clear_filter } from "../utils";
import { settings_layout, settings_default } from "./data";
import { import_data } from "./import_data";

export enum SystemsKeysEnum {
  S1 = "s1",
  S2 = "s2",
  S3 = "s3",
}

export type SettingsDefaultType = {
  [key in SystemsKeysEnum]: {
    name: string;
    week: {
      from: number;
      to: number;
    };
    ran: {
      from: number;
      to: number;
    };
    dow: boolean;
    track: boolean;
    distance: boolean;
    type: boolean;
    state: boolean;
    jumpflat: boolean;
  };
};

export type SettingsLayoutType = {
  id: string;
  type: string;
  title: string;
  order: number;
  css?: string;
};

export const useCurrentSystemStore = create((set, get) => ({
  sys: SystemsKeysEnum.S1,
  index: 0,
  store: (index: number, value: SystemsKeysEnum) =>
    set(() => {
      return { sys: value, index: index };
    }),
  clear: () => set({ sys: SystemsKeysEnum.S1, index: 0 }, true),
}));

export const useSystemsStore = create(
  persist(
    (set, get) => ({
      filters: {} as SettingsDefaultType,
      get: (id: string) => {
        return get_filter(get() as any, id);
      },
      layout: (): SettingsLayoutType[] => {
        return settings_layout;
      },
      store: (id: string, type: FilterValueEnum, value: any) =>
        set((state: any) => {
          state = set_filter(state, id, type, value);
          return Object.assign({}, state);
        }),
      init: (force?: boolean) =>
        set((state: any) => {
          if (force || Object.keys(state.filters).length <= 0) {
            state.filters = settings_default;
          }
          return Object.assign({}, state);
        }, true),
      reload: (data: string) => {
        let filters = import_data(data);
        if (!filters) {
          console.error("Failed to parse data");
          return false;
        }

        set((state: any) => {
          state.filters = filters;
          return { ...state };
        }, true);
        return true;
      },
      clear: (id: string) =>
        set((state: any) => {
          state = clear_filter(state, id);
          return Object.assign({}, state);
        }, true),
    }),
    { name: "_systems" }
  )
);
