import { SPTypesEnum, RsaRunner } from "../../types";
import DefaultSilk from "../../assets/images/default-silk.png";
import { useMemo } from "react";
import { Utils } from "../tables";

const isActive = (status: string): boolean => {
  return /ACTIVE|LOSER|WINNER/i.test(status);
};

const getBsps = (data: any): number[] => {
  return (data?.runners || [])
    .filter((runner: any) => isActive(runner.status))
    .map((runner: any) => {
      return runner.sp.actualSP ? runner.sp.actualSP : runner.sp.nearPrice ?? 0;
    })
    .sort((a: number, b: number) => a - b);
};

const getImg = (img: string): string => {
  if (!img) return DefaultSilk;

  const baseUrl =
    "https://content.betfair.com/feeds_images/Horses/SilkColours/";

  return `${baseUrl}${img}`;
};

const getRunners = (
  data: any,
  highlightFaves: number[]
): { runners: RsaRunner[]; bsps: number[] } => {
  let runners = (data?.runners || [])
    .filter((runner: any) => isActive(runner.status))
    .map((runner: any) => {
      return {
        selectionId: runner.selectionId,
        name: runner.runnerName,
        faveNo: runner.favNo,
        highlight: highlightFaves.includes(runner.favNo),
        status: runner.status,
        img: getImg(runner.metadata?.COLOURS_FILENAME),
        jockey: runner.metadata?.JOCKEY_NAME,
        bsp: {
          value: runner.sp.actualSP
            ? runner.sp.actualSP
            : runner.sp.nearPrice ?? "",
          class: runner.sp.actualSP ? SPTypesEnum.BSP : SPTypesEnum.Near,
        },
        bak1:
          runner.ex?.availableToBack?.length > 0
            ? runner.ex?.availableToBack[0]
            : { price: "", size: "" },
        bak2:
          runner.ex?.availableToBack?.length > 1
            ? runner.ex?.availableToBack[1]
            : { price: "", size: "" },
        lay1:
          runner.ex?.availableToLay?.length > 0
            ? runner.ex?.availableToLay[0]
            : { price: "", size: "" },
        lay2:
          runner.ex?.availableToLay?.length > 1
            ? runner.ex?.availableToLay[1]
            : { price: "", size: "" },
      };
    })
    .sort((a: any, b: any) => a.faveNo - b.faveNo);

  let bsps = getBsps(data);

  return { runners, bsps };
};

export const useRunners = (market: any, highlightFaves: number[]) => {
  return useMemo(() => {
    const { runners, bsps } = getRunners(market, highlightFaves);
    let faveBsp = Utils.roundDecimal(bsps && bsps.length > 0 ? bsps[0] : 0);
    return { runners, bsps, faveBsp };
  }, [market, highlightFaves]);
};

//Notes:
/*
"metadata": {
  "SIRE_NAME": "Acclamation",
  "CLOTH_NUMBER_ALPHA": "9",
  "OFFICIAL_RATING": null,
  "COLOURS_DESCRIPTION": "Light blue, royal blue star, armlets and cap",
  "COLOURS_FILENAME": "c20250110lin/00865014.jpg",
  "FORECASTPRICE_DENOMINATOR": "4",
  "DAMSIRE_NAME": "Oasis Dream",
  "WEIGHT_VALUE": "120",
  "SEX_TYPE": "G",
  "DAYS_SINCE_LAST_RUN": "10",
  "WEARING": "Tongue strap",
  "OWNER_NAME": "Sam Tingey & Charlie Tingey",
  "DAM_YEAR_BORN": "2008",
  "SIRE_BRED": "GBR",
  "JOCKEY_NAME": "Jack Doughty",
  "DAM_BRED": "GBR",
  "ADJUSTED_RATING": null,
  "runnerId": "40576706",
  "CLOTH_NUMBER": "9",
  "SIRE_YEAR_BORN": "1999",
  "TRAINER_NAME": "D Steele",
  "COLOUR_TYPE": "b",
  "AGE": "6",
  "DAMSIRE_BRED": "GBR",
  "JOCKEY_CLAIM": "3",
  "FORM": "642982-",
  "FORECASTPRICE_NUMERATOR": "11",
  "BRED": null,
  "DAM_NAME": "Sabratah",
  "DAMSIRE_YEAR_BORN": "2000",
  "STALL_DRAW": "9",
  "WEIGHT_UNITS": "pounds"
}
*/
