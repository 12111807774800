import React, { useEffect, useRef, useState } from "react";
import { WeekDate } from "../../utils/dateutils";
import { IconButton, IconButtonProps } from "@chakra-ui/react";

export const DaySheetLauncher = (
  props: { date: WeekDate } & IconButtonProps
) => {
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const quickFinderWindow = useRef<any>(null);
  const { date: selectedDate, ...rest } = props;

  useEffect(() => {
    if (container) {
      quickFinderWindow.current = window.open(
        "/finder/daysheet",
        "",
        "width=1800,height=900,left=200,top=200,location=no,scrollbars=yes,resizable=yes"
      );
      quickFinderWindow.current?.document.body.appendChild(container);

      const curWindow = quickFinderWindow.current;
      if (curWindow) {
        curWindow.onload = () => {
          curWindow.postMessage({ data: selectedDate, type: "WeekDate" }, "*");
        };
      }

      // Return cleanup function
      return () => curWindow?.close();
    }
  }, [container]);

  const openDataSheet = () => {
    setContainer(document.createElement("div"));
  };

  useEffect(() => {
    if (quickFinderWindow && quickFinderWindow.current) {
      quickFinderWindow.current?.postMessage(
        { data: selectedDate, type: "WeekDate" },
        "*"
      );
    }
  }, [selectedDate]);

  return <IconButton onClick={openDataSheet} {...rest} />;
};
