const getDaysInMonth = function (month: any, year: any) {
  return new Date(year, month, 0).getDate();
};

const utc = function (date: Date) {
  return Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};

const fromTimestamp = function (ts: number) {
  let now = new Date();
  if (ts) {
    now = new Date(ts * 1000);
  }
  return getDateText(now);
};

const getDateText = function (now0?: Date, seperator?: string) {
  let now = now0 ? now0 : new Date();
  let dd: any = now.getDate();

  var mm: any = now.getMonth() + 1;
  var yyyy = now.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  if (seperator === undefined) {
    seperator = "/";
  }
  //now = mm+'-'+dd+'-'+yyyy;
  //now = mm+'/'+dd+'/'+yyyy;
  //now = dd+'-'+mm+'-'+yyyy;
  return dd + seperator + mm + seperator + yyyy;
};

const today = function () {
  return getDateText();
};

const timestamp = function (date: Date, seperator?: string) {
  if (seperator === undefined) {
    seperator = "";
  }

  return getDateText(date, seperator);
};

const tomorrow = function () {
  var date = new Date();
  date.setDate(date.getDate() + 1);
  return getDateText(date);
};

const getParts = function (dateText: string) {
  const regex = /(\d{2})\/(\d{2})\/(\d{4})/gm;
  let m: any = regex.exec(dateText);
  return {
    d: parseInt(m[1]),
    m: parseInt(m[2]),
    y: parseInt(m[3]),
  };
};

const getUtcParts = function (dateText: string) {
  let match =
    /^(\d{1,4})-(\d{1,2})-(\d{1,2})(T?\s*((\d{1,2}):(\d{1,2}))(:(\d{1,2})(\.\d{3}Z?)?)?)?$/.exec(
      dateText
    );
  if (match) {
    return {
      y: parseInt(match[1]),
      m: parseInt(match[2]),
      d: parseInt(match[3]),
      hh: parseInt(match[6] || "0"),
      mm: parseInt(match[7] || "0"),
      t: match[5] || "00:00",
    };
  }

  return {};
};

const formatTime = function (date: Date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  //let ampm = hours >= 12 ? 'pm' : 'am';
  //hours = hours % 12;
  //hours = hours ? hours : 12; // the hour '0' should be '12'
  let minutesx = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutesx; // + ' ' + ampm;
  return strTime;
};

const getWeekDay = function (dt: Date) {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const daysNo = [1, 2, 3, 4, 5, 6, 7];

  let day = dt.getDay();
  return {
    d: days[day],
    n: daysNo[day],
  };
};

export type WeekDate = {
  key: string;
  date: Date;
  year: number;
  y: string;
  woy: number;
  day: string;
  lday: string;
  dow: number;
  ds: number;
};

export type RsaDate = WeekDate & {
  base: WeekDate;
  is_today: () => boolean;
};

const getRsaDate = function (dateText: string | Date): RsaDate {
  const days = [
    { ln: "Sunday", sn: "Sun" },
    { ln: "Monday", sn: "Mon" },
    { ln: "Tuesday", sn: "Tue" },
    { ln: "Wednesday", sn: "Wed" },
    { ln: "Thursday", sn: "Thu" },
    { ln: "Friday", sn: "Fri" },
    { ln: "Saturday", sn: "Sat" },
  ];
  const daysNo = [1, 2, 3, 4, 5, 6, 7];
  const daysInMonth = [];

  if (!dateText) {
    dateText = today();
  } else if (typeof dateText !== "string") {
    dateText = getDateText(dateText);
  }

  let parts = getParts(dateText);
  let dt = new Date(parts.y, parts.m - 1, parts.d);
  let ds = Date.UTC(parts.y, parts.m - 1, parts.d);

  const thisYear = dt.getUTCFullYear();
  let yearStart = new Date(dt.getUTCFullYear(), 0, 1);
  const wk1 = 7 - yearStart.getDay();

  for (let m0 = 0; m0 < 12; m0++) {
    daysInMonth.push(getDaysInMonth(m0 + 1, thisYear));
  }

  //let diffDays = Math.round(Math.abs((dt.getTime() - yearStart.getTime())/(86400000)));
  let diffDays = 0;
  for (let m1 = 0; m1 < parts.m - 1; m1++) {
    diffDays += daysInMonth[m1];
  }
  diffDays += parts.d;
  let diff1 = diffDays - wk1 > 0 ? diffDays - wk1 : 0;
  let weekNo = Math.ceil(diff1 / 7) + 1;
  let yy = parts.y.toString().substr(-2);
  let dow = daysNo[dt.getDay()];

  let wdate = {
    key: `${parts.y}-${weekNo}-${dow}`,
    date: dt,
    year: parts.y,
    y: yy,
    woy: weekNo,
    day: days[dt.getDay()].sn,
    lday: days[dt.getDay()].ln,
    dow: dow,
  } as WeekDate;

  return {
    ...wdate,
    base: wdate,
    ds,
    is_today: function () {
      let month = this.date.getMonth() + 1;
      let dom = this.date.getDate();

      let now = new Date();
      let dd = now.getDate();
      let mm = now.getMonth() + 1;
      let yy = now.getFullYear();

      return dd === dom && mm === month && yy === this.year;
    },
  };
};

const parseWeekDate = function (dateText: string) {
  const wtod = function (year: number, week: number, day: number) {
    const firstDayOfYear = new Date(Date.UTC(year, 0, 1));
    const daysOffset = (week - 1) * 7 + (day - 1) - firstDayOfYear.getUTCDay();
    const targetDate = new Date(
      firstDayOfYear.getTime() + daysOffset * 24 * 60 * 60 * 1000
    );
    return targetDate;
  };

  let match = /^(\d{1,4})-(\d{1,2})(-(\d{1,2}))?$/.exec(dateText);
  if (!match) {
    return {} as RsaDate;
  }
  let parts = {
    y: parseInt(match[1]),
    w: parseInt(match[2]),
    d: match[4] ? parseInt(match[4]) : 1,
  };

  let date = wtod(parts.y, parts.w, parts.d);

  return getRsaDate(date);
};

const range = function (ix: number, day?: string) {
  let start = new Date();
  if (day) {
    let parts = getParts(day);
    start = new Date(parts.y, parts.m - 1, parts.d, 0, 0, 0);
  } else {
    start.setHours(0, 0, 0);
  }

  let end = new Date(start);
  end.setDate(end.getDate() + ix);
  end.setSeconds(end.getSeconds() - 1);

  start.setDate(start.getDate() - ix);

  return {
    from: utc(start),
    to: utc(end),
  };
};

export const DateUtils = {
  today,
  timestamp,
  tomorrow,
  getRsaDate,
  parseWeekDate,
  fromTimestamp,
  formatTime,
  getDateText,
  getWeekDay,
  getUtcParts,
  range,
};
