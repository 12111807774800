import React from "react";
import { Stack } from "@chakra-ui/react";
import { Styled } from "./styles";
import { BetSidesEnum } from "../../../types";
import { useBetMakerStakesStore } from "../../../store";
import { DecimalInput } from "../../text-field";

export const MyBetTools = (props: {
  name:
  | string
  | React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  title: string;
}) => {
  const { name, title } = props;
  const store = useBetMakerStakesStore((state: any) => state.store);
  const dataStore = useBetMakerStakesStore((state: any) => state);

  return (
    <>
      <Stack direction="row">
        <div>{title}</div>
        <Styled.PriceBadgeWrap>
          <span>B:</span>
          <Styled.PriceInput $side={BetSidesEnum.Back}>
            <DecimalInput
              value={Number(dataStore.BACK)}
              prefix="£"
              onChange={(value) => {
                store(value, BetSidesEnum.Back);
              }}
            />
          </Styled.PriceInput>

          <span>L:</span>
          <Styled.PriceInput $side={BetSidesEnum.Lay}>
            <DecimalInput
              value={Number(dataStore.LAY)}
              prefix="£"
              onChange={(value) => {
                store(value, BetSidesEnum.Lay);
              }}
            />
          </Styled.PriceInput>
        </Styled.PriceBadgeWrap>
      </Stack>
    </>
  );
};
