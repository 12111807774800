import { SUBS_BASE } from "../endpoints";
import { request, RequestMethod } from "../requests";
import { useMutation } from "@tanstack/react-query";
import { useAccessTokenStore } from "../../store";

export const useSubsCancel = (subsid: string) => {
  const accessToken = useAccessTokenStore((state: any) => state.accessToken);

  return useMutation({
    mutationFn: () =>
      request(
        `${SUBS_BASE}/${subsid}`,
        { method: RequestMethod.DELETE },
        accessToken()
      ),
    mutationKey: ["subs-cancel", subsid],
  });
};
