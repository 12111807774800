import React from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Flex,
  useColorModeValue,
  Stack,
  HStack,
  useDisclosure,
  IconButton,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { Profile } from "../profile";
import Logo from "../../assets/images/RSA.png";
import { Styled } from "./styles";
import { routes } from "../../routes/consts";

const Links = [
  { text: "Racing", href: routes.RACING },
  { text: "Bet Finder", href: routes.BETFINDER },
];

export const Header = () => {
  const location = useLocation();
  const { isOpen, onToggle } = useDisclosure();
  return (
    <Box bg={useColorModeValue("gray.100", "gray.900")} px={6}>
      <Flex h={"80px"} alignItems={"center"} justifyContent={"space-between"}>
        <Flex
          flex={{ base: 1, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <HStack spacing={8} alignItems={"center"}>
          <Box display={{ base: "none", md: "flex" }}>
            <Styled.LogoWrapper to={routes.HOME}>
              <img src={Logo} alt="logo" />
            </Styled.LogoWrapper>
          </Box>
          <HStack as={"nav"} spacing={4} display={{ base: "none", md: "flex" }}>
            {Links.map((link) => (
              <Styled.MenuItem
                key={link.href}
                to={link.href}
                $isActive={location.pathname === link.href}
              >
                {link.text}
              </Styled.MenuItem>
            ))}
          </HStack>
        </HStack>
        <Flex alignItems={"center"}>
          <Stack direction={"row"} spacing={7}>
            <Profile />
          </Stack>
        </Flex>
      </Flex>
      {isOpen ? (
        <Box pb={4} display={{ md: "none" }}>
          <Stack as={"nav"} spacing={4}>
            {Links.map((link) => (
              <Styled.MenuItem
                key={link.href}
                to={link.href}
                $isActive={location.pathname === link.href}
              >
                {link.text}
              </Styled.MenuItem>
            ))}
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
};
