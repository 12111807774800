import { Link } from "react-router-dom";
import styled from "styled-components";

const LogoWrapper = styled(Link)`
  width: 64px;
  height: 64px;
  img {
    height: 100%;
  }
`;

const FooterWrapper = styled.div`
  border-width: 0px;
  border-style: solid;
  box-sizing: border-box;
  overflow-wrap: break-word;
  width: 100%;
  bottom: 0;
  footer {
    background: #f6f6f6;
    padding: 20px 24px;
    text-align: center;
    width: 100%;
    position: fixed;
    bottom: 0;
}
  }
`;

const MenuList = styled.ul`
  display: flex;
  gap: 16px;
  list-style-type: none;
  a {
    font-size: 16px;
    font-weight: 700;
  }
`;

const ContentWrapper = styled.div<{ $isOpen: boolean }>`
  transition: margin-left 0.3s;
  margin-left: ${(props) => (props.$isOpen ? "350px" : "80px")};
  @media (max-width: 768px) {
    margin-left: ${(props) => (props.$isOpen ? "350px" : "28px")};
  }
`;

const ContentContainer = styled.div`
  padding: 1.5rem;
  width: 100%;
`;

const MenuItem = styled(Link)<{ $isActive: boolean }>`
  color: ${(props) => (props.$isActive ? "#319795" : "#171923")};
  font-weight: ${(props) => (props.$isActive ? "bold" : "400")};
  cursor: pointer;
`;

export const Styled = {
  LogoWrapper,
  FooterWrapper,
  MenuList,
  ContentWrapper,
  ContentContainer,
  MenuItem,
};
