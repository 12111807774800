import React, { useEffect, useState, useMemo } from "react";
import { Styled } from "./styles";
import { SBB, Heatmap } from "../../components/tables";
import { useRunners } from "../../components/bet-maker/runners";
import {
  useMarketsStore,
  useSystemsStore,
  useGetRacingFilters,
  useTrainingPnLStore,
  useGetQFQuery,
} from "../../store";
import { Ticker } from "../../components/misc/ticker";
import {
  BetMaker,
  MarketList,
  BetfairProfile,
} from "../../components/bet-maker";
import {
  RsaQueryAggsEnum,
  RsaQueryRequestItem,
  RsaQueryResponse,
  RsaRunner,
} from "../../types";
import {
  useSearchQuery,
  useGetMarketDetails,
  useGetMarketUpdate,
} from "../../services";
import {
  getMarket,
  getRaceData,
  getRefreshFrq,
} from "../../components/bet-maker/utils";
import { DaySheetLauncher } from "../../components/launcher";
import { DateUtils, RsaDate } from "../../utils/dateutils";
import { IconButton } from "@chakra-ui/react";
import {
  BiRefresh,
  BiSearch,
  BiCalendar,
  BiBarChartSquare,
  BiRightIndent,
  BiTrophy,
} from "react-icons/bi";
import { Systems } from "../../components/systems";

import { FlexibleLayout, LayoutElementType } from "../../components";
import { QuickFinderComponent } from "../../components/qfinder";
import { defaultLayout } from "./default-layout";
import { LayoutsItemType, LayoutItemType } from "../../components/layout";

export const Racing = () => {
  const [layout, setLayout] = React.useState<LayoutsItemType>(defaultLayout);
  const storeInit = useSystemsStore((state: any) => state.init);
  const marketsMap = useMarketsStore((state: any) => state);
  const execTraining = useTrainingPnLStore((state: any) => state.execute);
  const [currentMarketId, setCurrentMarketId] = useState<string>("");
  const [queryLoading, setQueryLoading] = useState(false);
  const [lastUpdated, setLastUpdated] = useState<number>(0);
  const [marketUpdate, setMarketUpdate] = useState<any>(null);
  const [highlightFaves, setHighlightFaves] = useState<number[]>([]);
  const [weekDate, setWeekDate] = useState<RsaDate>(
    DateUtils.getRsaDate(new Date())
  );
  const [isOpen, setIsOpen] = useState(false);

  storeInit();

  const isTraining = marketsMap[currentMarketId]?.training;

  const [queryResponse, setQueryResponse] = useState<RsaQueryResponse | null>(
    null
  );

  const getSearch = useSearchQuery({
    onError: (err: any) => {
      console.log("ERR:", err);
    },
    onSuccess: (data: any) => {
      setQueryResponse(data);
      setQueryLoading(false);
    },
  });

  const { data: marketData, isLoading: marketLoading } = useGetMarketDetails(
    currentMarketId,
    isTraining
  );

  const { market, raceData } = useMemo(() => {
    let market = getMarket(currentMarketId, marketData, marketUpdate);

    let raceData =
      market && market.marketId ? marketsMap[market.marketId] : null;

    return {
      market,
      raceData: getRaceData(market, raceData, weekDate),
    };
  }, [marketData, marketUpdate]);

  const qfData = useGetQFQuery(raceData) || undefined;

  const { runners, bsps, faveBsp: filter } = useRunners(market, highlightFaves);

  const updateMarket = useGetMarketUpdate({
    onError: (err: any) => {
      console.log("ERR:", err);
    },
    onSuccess: (data: any) => {
      setMarketUpdate(data);
      if (isTraining && data.in_result) {
        let winner = (data?.runners || []).find((r: RsaRunner) =>
          /WINNER/i.test(r.status)
        );
        execTraining(currentMarketId, winner?.selectionId);
      }
    },
  });

  const onTick = (event: any) => {
    if (!marketLoading && !isTraining && market && market.marketId) {
      let refreshFrq = getRefreshFrq(market);
      if (refreshFrq && refreshFrq > 0) {
        if (refreshFrq <= lastUpdated) {
          updateMarket.mutate({ marketId: market.marketId, includebets: true });
          setLastUpdated(0);
        } else {
          setLastUpdated(lastUpdated + 1);
        }
      }
    }
  };

  const query = useGetRacingFilters(raceData, bsps, [
    RsaQueryAggsEnum.SBB,
    RsaQueryAggsEnum.Fav,
  ]);

  useEffect(() => {
    if (query) {
      onQuery(query.queries);
    }
  }, [query?.digest]);

  const onQuery = async (
    query?: RsaQueryRequestItem | RsaQueryRequestItem[]
  ) => {
    if (!query) return;
    setQueryLoading(true);
    getSearch.mutate(query);
  };

  const onOrderSubmit = () => {
    if (isTraining) {
      updateMarket.mutate({
        marketId: market.marketId,
        includebets: true,
        isSimulate: isTraining,
      });
    }
  };

  const elements: LayoutElementType[] = useMemo(() => {
    return [
      {
        id: "betmaker",
        element: (
          <BetMaker
            market={market}
            raceData={raceData}
            runners={runners}
            isTraining={isTraining}
            onOrderSubmit={onOrderSubmit}
            marketLoading={marketLoading}
          />
        ),
      },
      {
        id: "heatmap",
        element: (
          <Heatmap
            data={queryResponse}
            bsps={bsps}
            ran={runners.length}
            filter={filter}
            loading={queryLoading || marketLoading}
            onHighlightChanged={setHighlightFaves}
          />
        ),
      },
      {
        id: "sbb",
        element: (
          <SBB
            data={queryResponse}
            title="SBB"
            filter={filter}
            loading={queryLoading || marketLoading}
          />
        ),
      },
      {
        id: "qfinder",
        element: (
          <QuickFinderComponent
            filterData={qfData}
            bsps={bsps}
            loading={marketLoading}
          />
        ),
      },
    ];
  }, [
    market,
    queryResponse,
    runners,
    bsps,
    filter,
    queryLoading,
    marketLoading,
    isTraining,
    raceData,
  ]);

  const addElement = (id: string) => {
    const newLayout: LayoutsItemType = {};
    Object.keys(layout).forEach((breakpoint) => {
      newLayout[breakpoint] = [];
      newLayout[breakpoint].push(
        defaultLayout[breakpoint].find((el: any) => el.i === id)
      );
      layout[breakpoint].forEach((el: LayoutItemType) => {
        if (el.i !== id) {
          newLayout[breakpoint].push(el);
        }
      });
    });
    setLayout(newLayout);
  };

  const isElementInLayout = (id: string) => {
    return Object.keys(layout).some((breakpoint) => {
      return layout[breakpoint].some((el) => el.i === id);
    });
  };

  return (
    <Styled.LiveWrapper>
      <Styled.FiltersWrapper>
        <MarketList
          onRaceSelect={setCurrentMarketId}
          currentId={currentMarketId}
          onDateChange={setWeekDate}
        >
          <div>
            <Systems
              isOpen={isOpen}
              onClose={() => setIsOpen(false)}
              onEdit={() => setIsOpen(true)}
              key="systems"
            />
          </div>
          <IconButton
            size="md"
            fontSize={32}
            colorScheme="teal"
            variant="solid"
            aria-label="refresh"
            icon={<BiRefresh />}
            onClick={() => onQuery(query?.queries)}
          />
          <DaySheetLauncher
            date={weekDate.base}
            size="md"
            fontSize={32}
            colorScheme="teal"
            variant="solid"
            aria-label="refresh"
            icon={<BiCalendar />}
          />
          {!isElementInLayout("betmaker") && (
            <IconButton
              size="md"
              fontSize={32}
              colorScheme="teal"
              variant="solid"
              aria-label="betmaker"
              icon={<BiTrophy />}
              onClick={() => addElement("betmaker")}
            />
          )}
          {!isElementInLayout("heatmap") && (
            <IconButton
              size="md"
              fontSize={32}
              colorScheme="teal"
              variant="solid"
              aria-label="heatmap"
              icon={<BiBarChartSquare />}
              onClick={() => addElement("heatmap")}
            />
          )}
          {!isElementInLayout("sbb") && (
            <IconButton
              size="md"
              fontSize={32}
              colorScheme="teal"
              variant="solid"
              aria-label="sbb"
              icon={<BiRightIndent />}
              onClick={() => addElement("sbb")}
            />
          )}
          {!isElementInLayout("qfinder") && (
            <IconButton
              size="md"
              fontSize={32}
              colorScheme="teal"
              variant="solid"
              aria-label="mbf"
              icon={<BiSearch />}
              onClick={() => addElement("qfinder")}
            />
          )}
        </MarketList>
        <BetfairProfile />
      </Styled.FiltersWrapper>
      <FlexibleLayout
        layout={layout}
        elements={elements}
        onLayoutUpdated={setLayout}
      />
      <Ticker name="betfair-market" onTick={onTick} />
    </Styled.LiveWrapper>
  );
};
