import { BetSidesEnum } from "../types";

const prices: number[] = [
  1.01, 1.02, 1.03, 1.04, 1.05, 1.06, 1.07, 1.08, 1.09, 1.1, 1.11, 1.12, 1.13,
  1.14, 1.15, 1.16, 1.17, 1.18, 1.19, 1.2, 1.21, 1.22, 1.23, 1.24, 1.25, 1.26,
  1.27, 1.28, 1.29, 1.3, 1.31, 1.32, 1.33, 1.34, 1.35, 1.36, 1.37, 1.38, 1.39,
  1.4, 1.41, 1.42, 1.43, 1.44, 1.45, 1.46, 1.47, 1.48, 1.49, 1.5, 1.51, 1.52,
  1.53, 1.54, 1.55, 1.56, 1.57, 1.58, 1.59, 1.6, 1.61, 1.62, 1.63, 1.64, 1.65,
  1.66, 1.67, 1.68, 1.69, 1.7, 1.71, 1.72, 1.73, 1.74, 1.75, 1.76, 1.77, 1.78,
  1.79, 1.8, 1.81, 1.82, 1.83, 1.84, 1.85, 1.86, 1.87, 1.88, 1.89, 1.9, 1.91,
  1.92, 1.93, 1.94, 1.95, 1.96, 1.97, 1.98, 1.99, 2, 2.02, 2.04, 2.06, 2.08,
  2.1, 2.12, 2.14, 2.16, 2.18, 2.2, 2.22, 2.24, 2.26, 2.28, 2.3, 2.32, 2.34,
  2.36, 2.38, 2.4, 2.42, 2.44, 2.46, 2.48, 2.5, 2.52, 2.54, 2.56, 2.58, 2.6,
  2.62, 2.64, 2.66, 2.68, 2.7, 2.72, 2.74, 2.76, 2.78, 2.8, 2.82, 2.84, 2.86,
  2.88, 2.9, 2.92, 2.94, 2.96, 2.98, 3, 3.05, 3.1, 3.15, 3.2, 3.25, 3.3, 3.35,
  3.4, 3.45, 3.5, 3.55, 3.6, 3.65, 3.7, 3.75, 3.8, 3.85, 3.9, 3.95, 4, 4.1, 4.2,
  4.3, 4.4, 4.5, 4.6, 4.7, 4.8, 4.9, 5, 5.1, 5.2, 5.3, 5.4, 5.5, 5.6, 5.7, 5.8,
  5.9, 6, 6.2, 6.4, 6.6, 6.8, 7, 7.2, 7.4, 7.6, 7.8, 8, 8.2, 8.4, 8.6, 8.8, 9,
  9.2, 9.4, 9.6, 9.8, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15,
  15.5, 16, 16.5, 17, 17.5, 18, 18.5, 19, 19.5, 20, 21, 22, 23, 24, 25, 26, 27,
  28, 29, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50, 55, 60, 65, 70, 75, 80,
  85, 90, 95, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200, 210, 220,
  230, 240, 250, 260, 270, 280, 290, 300, 310, 320, 330, 340, 350, 360, 370,
  380, 390, 400, 410, 420, 430, 440, 450, 460, 470, 480, 490, 500, 510, 520,
  530, 540, 550, 560, 570, 580, 590, 600, 610, 620, 630, 640, 650, 660, 670,
  680, 690, 700, 710, 720, 730, 740, 750, 760, 770, 780, 790, 800, 810, 820,
  830, 840, 850, 860, 870, 880, 890, 900, 910, 920, 930, 940, 950, 960, 970,
  980, 990, 1000,
];

const price_ranges: number[] = [
  1.0, 1.5, 2.0, 2.5, 3.0, 3.5, 4.0, 4.5, 5.0, 5.5, 6.0, 6.5, 7.0, 7.5, 8.0,
  8.5, 9.0, 9.5, 10.0, 11.0, 12.0, 13.0, 14.0, 15.0, 16.0, 17.0, 18.0, 19.0,
  20.0, 22.0, 24.0, 26.0, 28.0, 30.0, 32.0, 34.0, 36.0, 38.0, 40.0, 42.0, 44.0,
  46.0, 48.0, 50.0, 55.0, 60.0, 65.0, 70.0, 75.0, 80.0, 85.0, 90.0, 95.0, 100.0,
  110.0, 120.0, 130.0, 140.0, 150.0, 160.0, 170.0, 180.0, 190.0, 200.0, 220.0,
  240.0, 260.0, 280.0, 300.0, 320.0, 340.0, 360.0, 380.0, 400.0, 420.0, 440.0,
  460.0, 480.0, 500.0, 550.0, 600.0, 650.0, 700.0, 750.0, 800.0, 850.0, 900.0,
  950.0, 1000.0,
];

const next = function (price: number) {
  let target = price;
  for (let ix = 0; ix < prices.length; ix++) {
    if (prices[ix] > price) {
      target = prices[ix];
      break;
    }
  }

  return target;
};

const previous = function (price: number) {
  let target = price;
  for (let ix = 0; ix < prices.length; ix++) {
    if (prices[ix] < price) {
      target = prices[ix];
    } else {
      break;
    }
  }

  return target;
};

const roundup = function (price: number, prices0: number[]) {
  let target = price;
  for (let ix = 0; ix < prices0.length; ix++) {
    if (prices0[ix] >= price) {
      target = prices0[ix];
      break;
    }
  }

  return target;
};

const rounddown = function (price: number, prices0: number[]) {
  let target = price;
  for (let ix = 0; ix < prices0.length; ix++) {
    if (prices0[ix] <= price) {
      target = prices0[ix];
    } else {
      break;
    }
  }

  return target;
};

const round = function (price: number, side: BetSidesEnum) {
  if (side === BetSidesEnum.Lay) {
    return roundup(price, prices);
  } else {
    return rounddown(price, prices);
  }
};

const round_to_range = function (price: number, side: BetSidesEnum) {
  if (side === BetSidesEnum.Lay) {
    return roundup(price, price_ranges);
  } else {
    return rounddown(price, price_ranges);
  }
};

// const round_price = function (bet: any) {
//   let price = parseFloat(bet.price);
//   if (bet.config && bet.config.bspp) {
//     price = bet.config.bspp * (parseFloat(bet.odds || price) - 1) + 1;
//   }
//   return round(price, bet.side);
// };

const get_range = function (price: number) {
  let target = price;
  let idx;
  for (idx = 0; idx < prices.length; idx++) {
    if (prices[idx] <= price) {
      target = prices[idx];
    } else {
      break;
    }
  }

  const max_count = 2;
  let start = target;
  let count = max_count + 1; //limit
  let offset = idx - 1;
  while (offset >= 0 && count > 0) {
    start = prices[offset];
    offset--;
    count--;
  }

  let end = target;
  count = max_count; //limit
  offset = idx;
  while (offset < prices.length && count > 0) {
    end = prices[offset];
    offset++;
    count--;
  }

  return { start, end };
};

export const PriceUtils = {
  next,
  previous,
  round,
  round_to_range,
  get_range,
};
