import React from "react";
import { Styled } from "./styles";
import { ChevronRightIcon, ChevronLeftIcon } from "@chakra-ui/icons";

interface CustomDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  children: any;
  onOpen: () => void;
  title: string;
  size?: "xs" | "sm" | "md" | "lg";
}

export const Drawer: React.FC<CustomDrawerProps> = ({
  title,
  size,
  isOpen,
  onClose,
  children,
  onOpen,
}) => {
  return (
    <Styled.DrawerWrapper $size={size} $isOpen={isOpen}>
      {isOpen ? (
        <Styled.DrawerContent>
          <Styled.CloseButton $size={size} onClick={onClose}>
            <ChevronLeftIcon w={8} h={8} />
          </Styled.CloseButton>
          <Styled.DrawerHeader>{title}</Styled.DrawerHeader>
          {children}
        </Styled.DrawerContent>
      ) : (
        <Styled.OpenButton $size={size} onClick={onOpen}>
          <ChevronRightIcon w={8} h={8} />
        </Styled.OpenButton>
      )}
    </Styled.DrawerWrapper>
  );
};
