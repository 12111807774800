import md5 from "crypto-js/md5";

export const get_digest = function (params: any) {
  const stringify_object = function (obj: any) {
    if (!obj) {
      return obj;
    }

    if (obj instanceof Array) {
      return stringify_array(obj);
    }

    if (typeof obj != "object") {
      return `${obj}`;
    }

    let keys = Object.keys(obj || {}).sort();
    let list: string[] = [];
    keys.forEach((k) => {
      //list.push(`${k}=${stringify_object(obj[k])}`);
      let val = stringify_object(obj[k]);
      if (val !== undefined) {
        list.push(`${k}=${val}`);
      }
    });

    return list.join(";");
  };

  const stringify_array = function (arr: any[]) {
    let list: string[] = [];
    arr.forEach((item: any) => {
      list.push(stringify_object(item));
    });
    return list.join(";");
  };

  if (params instanceof Array) {
    let data0 = params.sort((a, b) => {
      return a.system > b.system ? 1 : -1;
    });
    let dss: string[] = [];
    data0.forEach((d: any) => {
      dss.push(get_digest(d));
    });
    return dss.join(";");
  }

  let plain = stringify_object(params);

  return md5(plain).toString();
};
