const formatDecimal = function (val: any, digits?: number) {
  if (val) {
    if (typeof val === "string") {
      if (/^[-+]?[\d\.]+$/.exec(val)) {
        val = parseFloat(val || "0");
      } else {
        return "";
      }
    }
    return val
      .toFixed(digits != null && digits !== undefined ? digits : 2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  }

  return "";
};

const roundDecimal = function (val: any): number {
  return Math.round(parseFloat(val) * 100) / 100;
};

const round100 = function (v: number) {
  return Math.round(v * 10000) / 100;
};

const roundInt = function (val: any) {
  return parseInt(`${Math.round(parseFloat(val))}`);
};

const toFixed = function (val: number, dec?: number) {
  if (val) {
    return val.toFixed(dec || 2);
  }
  return val;
};

export const Utils = {
  formatDecimal,
  roundDecimal,
  round100,
  roundInt,
  toFixed,
};
