import React from "react";
import { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  text-align: center;
`;

const SectionTitle = styled.div`
  margin: 0 auto;
  max-width: 80%;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 25%;
  max-width: 25%;
  padding: 15px;

  @media (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

const FeatureText = styled.div`
  text-align: left;
  // Add any additional styles if needed
`;

const MoreText = styled.strong`
  cursor: pointer;
`;

type TFeatures = {
  data: any;
  width: number;
};

export const Features = ({ data, width }: TFeatures) => {
  const [selected, setSelected] = useState("");
  return (
    <Container id="features">
      <div className="container">
        <SectionTitle>
          <h2>Powerful Features</h2>
          <p>
            RaceStatsApp has six apps in one – giving you access to advanced
            data analysis and clear visuals not available anywhere else, plus
            direct link to Betfair for real time odds and placing bets.
          </p>
        </SectionTitle>
        <Row>
          {data
            ? data.map((d: any, i: any) => (
                <React.Fragment key={`${d.title}-${i}`}>
                  {i % 4 === 0 && i !== 0 && (
                    <div style={{ flexBasis: "100%" }} />
                  )}
                  <Col>
                    {d.link ? (
                      <a href={d.link} target="_blank" rel="noreferrer">
                        <img src={d.icon} alt={d.title} />
                      </a>
                    ) : (
                      <div>
                        <img src={d.icon} alt={d.title} />
                      </div>
                    )}
                    <h3 className="feature">
                      {d.link ? (
                        <a href={d.link} target="_blank" rel="noreferrer">
                          {d.title}
                        </a>
                      ) : (
                        `${d.title}`
                      )}
                    </h3>
                    {width < 768 ? (
                      <FeatureText>{d.text}</FeatureText>
                    ) : (
                      <FeatureText>
                        {d.text.length > 220
                          ? selected === `${d.name}-${i}`
                            ? d.text
                            : `${d.text.substring(0, 220)} `
                          : d.text}
                        {d.text.length > 220 && (
                          <MoreText
                            onClick={() =>
                              setSelected(
                                selected === `${d.name}-${i}`
                                  ? ""
                                  : `${d.name}-${i}`
                              )
                            }
                          >
                            {selected === `${d.name}-${i}`
                              ? " show less"
                              : " ...show more"}
                          </MoreText>
                        )}
                      </FeatureText>
                    )}
                  </Col>
                </React.Fragment>
              ))
            : "Loading..."}
        </Row>
      </div>
    </Container>
  );
};
