import React from "react";
import { useGetProfile, useSubsCancel } from "../../services";
import { Container, Box, Heading, Spinner, Button } from "@chakra-ui/react";
import { UserUtils } from "./utils";
export const Profile = () => {
  const { data: customer, isLoading } = useGetProfile();
  let subscription =
    customer?.subscriptions && customer?.subscriptions?.length > 0
      ? customer?.subscriptions[0]
      : null;

  const subsCancel = useSubsCancel(subscription?.id);
  const onCancelSubs = () => {
    subsCancel.mutate(subscription.id, {
      onSuccess: (res) => {
        subscription =
          res?.subscriptions && res?.subscriptions?.length > 0
            ? res?.subscriptions[0]
            : null;
      },
    });
  };

  return (
    <Container
      maxW="2xl"
      padding={"16px"}
      flexDirection={"column"}
      margin={4}
      gap={"16px"}
      display={"flex"}
    >
      <Heading as="h2" size="lg">
        User details
      </Heading>
      {isLoading ? (
        <Spinner />
      ) : (
        <Box
          padding="4"
          bg="#fff"
          color="black"
          maxW="lg"
          shadow={"0px 4px 8px 0px grey"}
          borderRadius={"8px"}
        >
          <p>{`${customer?.user.name} ${customer?.user.family}`}</p>
          <p>{customer?.user.email} </p>
          <p>{customer?.user.telegram} </p>
          <p>
            {customer?.cards.map((card: any, idx: number) => {
              return <span key={idx}>{UserUtils.displayCreditCard(card)}</span>;
            })}
          </p>
        </Box>
      )}

      <Heading as="h2" size="lg">
        Subscription details
      </Heading>
      {isLoading ? (
        <Spinner />
      ) : (
        <Box
          padding="4"
          bg="#fff"
          color="black"
          maxW="lg"
          shadow={"0px 4px 8px 0px grey"}
          borderRadius={"8px"}
        >
          {subscription ? (
            <>
              <p>
                Plan:{" "}
                {subscription
                  ? UserUtils.displaySubscription(subscription)
                  : ""}
              </p>
              <p>
                {UserUtils.subscriptionActive(subscription) ? (
                  <>
                    <span>Active</span>
                    <Button
                      colorScheme="teal"
                      type="submit"
                      style={{ width: "100%" }}
                      onClick={onCancelSubs}
                    >
                      Cancel
                    </Button>
                  </>
                ) : (
                  <></>
                )}
              </p>
            </>
          ) : (
            <>{UserUtils.trialCustomer(customer)}</>
          )}
        </Box>
      )}
    </Container>
  );
};
