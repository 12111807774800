import React from "react";
import styled from "styled-components";

const OverlayBackground = styled.div`
  display: flex;
  top: 80px;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 50;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.15);
`;

const OverlayShown = styled.div`
  opacity: 1;
`;

const OverlayHidden = styled.div`
  display: none;
  opacity: 0;
`;

const OverlayCard = styled.div`
  max-width: 900px;
  border-radius: 10px;
  z-index: 51;
  background-color: rgb(256, 256, 256);
  display: flex;
  justify-content: center;
`;

const stopProp = (e: React.MouseEvent) => {
  e.stopPropagation();
};

interface ModalProps {
  overlay: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export const Modal: React.FC<ModalProps> = ({ overlay, onClose, children }) => {
  return (
    <>
      {overlay ? (
        <OverlayShown>
          <OverlayBackground onClick={onClose}>
            <OverlayCard onClick={(e) => stopProp(e)}>{children}</OverlayCard>
          </OverlayBackground>
        </OverlayShown>
      ) : (
        <OverlayHidden />
      )}
    </>
  );
};
