import axios from "axios";

export const baseURL = process.env.REACT_APP_BASE_URL;
export const betfairUrl = process.env.REACT_APP_BF_BASE_URL;
export const apiKey = process.env.REACT_APP_API_KEY;

const axiosInstance = axios.create({ baseURL });
const axiosBfInstance = axios.create({ baseURL: betfairUrl });

export const RequestMethod = {
  DELETE: "DELETE",
  GET: "GET",
  PATCH: "PATCH",
  PUT: "PUT",
  POST: "POST",
};

const get_headers = function (accessToken?: string | undefined, extra?: any) {
  let hs: {
    "x-api-key": string | undefined;
    "Content-Type": string;
    Authorization?: string;
  } = {
    "x-api-key": apiKey,
    "Content-Type": "application/json",
  };

  if (accessToken) {
    hs.Authorization = `${accessToken}`;
  }

  return Object.assign(extra || {}, hs);
};

export const request = (
  url: string,
  config: any,
  accessToken?: string | undefined,
  betfairToken?: string | undefined
) => {
  let headers = get_headers(
    accessToken,
    betfairToken ? { "x-betfair-token": `BEARER ${betfairToken}` } : null
  );
  return axiosInstance(url, {
    headers,
    ...config,
  }).then((res) => res.data);
};

export const bfRequest = (
  url: string,
  config: any,
  accessToken: string,
  betfairToken: string
) => {
  let headers = get_headers(accessToken, {
    "x-betfair-token": `BEARER ${betfairToken}`,
  });
  return axiosBfInstance(url, {
    headers,
    ...config,
  }).then((res) => res.data);
};
