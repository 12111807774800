import { useAccessTokenStore, useBfAccessTokenStore } from "../../store";
import { BETFAIR_LOGIN } from "../endpoints";
import { request, RequestMethod } from "../requests";
import { MutateOptions, useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

export enum BetfairLoginErrorCodeEnum {
  NoRefreshToken = "NoRefreshToken",
  InvalidLogin = "InvalidLogin",
}

export const useBetfairLogin = (
  options: MutateOptions<unknown, AxiosError, string, any> & {
    onNext: () => void;
  }
) => {
  const { store } = useBfAccessTokenStore((state: any) => state);
  const accessToken = useAccessTokenStore((state: any) => state.accessToken);

  const betfairLogin = async (authCode: string) => {
    return await request(
      BETFAIR_LOGIN,
      {
        method: RequestMethod.POST,
        data: { authCode: authCode },
      },
      accessToken()
    );
  };

  options.onSuccess =
    options.onSuccess ||
    function (response: any) {
      store(response);
      options.onNext && options.onNext();
    };

  return useMutation({
    mutationFn: betfairLogin,
    mutationKey: ["betfair-login"],
    ...options,
  });
};

export const useBetfairRefresh = (
  options: MutateOptions<unknown, AxiosError, void, any> & {
    onNext: () => void;
  }
) => {
  const { store, refreshToken } = useBfAccessTokenStore((state: any) => state);
  const accessToken = useAccessTokenStore((state: any) => state.accessToken);

  const betfairRefresh = async () => {
    let refreshTkn = refreshToken();
    if (!refreshTkn) {
      throw new Error(BetfairLoginErrorCodeEnum.NoRefreshToken);
    }

    return await request(
      BETFAIR_LOGIN,
      {
        method: RequestMethod.POST,
        data: { refresh_token: refreshTkn },
      },
      accessToken()
    );
  };

  options.onSuccess =
    options.onSuccess ||
    function (response: any) {
      store(response);
      options.onNext && options.onNext();
    };

  return useMutation({
    mutationFn: betfairRefresh,
    mutationKey: ["betfair-refresh"],
    ...options,
  });
};
