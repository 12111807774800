import { RsaQueryResponse } from "../../../types";
import { Utils } from "../utils";
import { heatmapData } from "./heatmapData";
import { MAXROWS } from "../../../types";

const find_bsp = function (results: any[], bsp: number) {
  let result = { ix: Math.floor(results.length / 2), f: false };
  if (bsp) {
    for (let jdx = 0; jdx < results.length; jdx++) {
      let element = results[jdx];
      if (element.C_spf === bsp) {
        return { ix: jdx, f: true };
      } else if (element.C_spf < bsp) {
        return { ix: jdx, f: false };
      } else {
        result.ix = jdx + 1;
      }
    }
  }

  return result;
};

const sort_winsps = function (offset: number, result: any[]) {
  if (offset > 0) {
    let upper = result.slice(0, offset);
    let lower = result.slice(offset, result.length);
    lower.sort((a, b) => {
      let diff = b.C_spf - a.C_spf;
      if (diff === 0) {
        diff = a.D_spw - b.D_spw;
      }
      return diff;
    });

    upper.sort((a, b) => {
      let diff = b.C_spf - a.C_spf;
      if (diff === 0) {
        diff = b.D_spw - a.D_spw;
      }
      return diff;
    });

    return ([] as any[]).concat(upper, lower);
  }

  return result;
};

const getRows = function (data: RsaQueryResponse, filter: number) {
  const middle = Math.ceil(MAXROWS / 2) - 1;

  let fave_count = 0,
    fave12_count = 0,
    other_count = 0,
    total_count = 0;

  let results = data?.lpd?.FavSP || [];
  if (!results) {
    return { rows: [], fave_count, fave12_count, other_count, total_count };
  }

  let found = SbbUtils.find_bsp(results, filter);
  let index = found.ix;

  let offset = index > middle ? index - middle : 0; // offset from the list where we view_offset
  let view_offset = index > middle ? 0 : middle - (index < 0 ? 0 : index); // offset of the view

  // if(!filter && index >= 0 && results[index]) {
  //     filter = results[index].C_spf;
  //     filter = Utils.roundDecimal(filter);
  // }
  // let selected_result_ix = middle - view_offset;
  let selected_result_c = offset + (MAXROWS - view_offset) - (found.f ? 0 : 1);

  results = SbbUtils.sort_winsps(index, [].concat(results));

  if (filter && !found.f) {
    results.splice(found.ix, 0, { C_spf: filter });
  }

  let selected_result = results.slice(
    offset,
    selected_result_c > 0 ? selected_result_c : 0
  );

  let empty_count = (MAXROWS - 1) / 2 - found.ix;
  while (empty_count > 0) {
    selected_result.unshift({});
    empty_count--;
  }

  let remaining = results.length - found.ix;
  empty_count =
    remaining > (MAXROWS - 1) / 2
      ? 0
      : (MAXROWS - 1) / 2 - remaining + (found.f ? 1 : 0);

  while (empty_count > 0) {
    selected_result.push({});
    empty_count--;
  }

  selected_result.forEach((element: { E_ws: number }) => {
    if (element && element.E_ws) {
      total_count++;
      if (element.E_ws === 1) {
        fave_count++;
        fave12_count++;
      } else if (element.E_ws === 2) {
        fave12_count++;
      } else {
        other_count++;
      }
    }
  });

  if (total_count > 0) {
    fave_count = Utils.round100(fave_count / total_count);
    fave12_count = Utils.round100(fave12_count / total_count);
    other_count = Utils.round100(other_count / total_count);
  }

  return {
    rows: selected_result,
    fave_count,
    fave12_count,
    other_count,
    total_count,
  };
};

const findRange = function (v: any) {
  for (let ixx = 0; ixx < heatmapData.matchCriteria.length; ixx++) {
    let element = heatmapData.matchCriteria[ixx];
    let vx = Math.abs(v);
    if (vx > 1000) {
      vx = 1000;
    }

    if (vx >= element.from && vx < element.to) {
      return `${element.match >= 100 ? (v >= 0 ? "g" : "l") : ""}${
        v >= 0 ? "p" : "m"
      }${element.match >= 1000 ? 80 : element.match}`;
    }
  }

  return null;
};

const prepareHMData = (
  results: any[],
  bsps: number[],
  ran: number,
  filter: number,
  options: {
    filter_pcngt: number;
    filter_fave: number;
  }
) => {
  let found = SbbUtils.find_bsp(results, filter);
  let index = found.ix;
  let faveNo = 1;

  let resultsMap: any = {};
  results.forEach((element: any) => {
    if (element.E_ws && element.D_spw) {
      let faveNo = element.E_ws || 0;
      if (!resultsMap[faveNo]) {
        resultsMap[faveNo] = [];
      }
      resultsMap[faveNo].push({ fave: element.C_spf, bsp: element.D_spw });
    }
  });

  let faves: any = {};
  const highlights: number[] = [];
  const xAxisLabels: any[] = [];

  for (faveNo = 1; faveNo <= ran; faveNo++) {
    let sb_bsps = resultsMap[faveNo];
    xAxisLabels.push({
      key: faveNo,
      name: heatmapData.vheads[
        faveNo.toString() as keyof typeof heatmapData.vheads
      ],
    });

    if (!faves[faveNo]) {
      faves[faveNo] = { ranges: [], next: 0 };
    }

    (sb_bsps || []).forEach((sbs: any) => {
      let in_range = false;
      if (filter && filter > 0) {
        let percentage = Utils.round100(Math.abs((sbs.fave - filter) / filter));
        in_range = percentage <= options.filter_pcngt;
      }

      if (bsps[faveNo - 1]) {
        let faveBSP = bsps[faveNo - 1];
        let percentage = Utils.round100(sbs.bsp / faveBSP - 1);

        let rng = SbbUtils.findRange(percentage);
        if (rng) {
          faves[faveNo].ranges.push({ key: rng, in: in_range });
        }
      }
    });

    if (bsps[faveNo - 1]) {
      if (bsps[faveNo]) {
        let next = Utils.round100(bsps[faveNo] / bsps[faveNo - 1] - 1);
        if (next <= options.filter_fave) {
          highlights.push(faveNo);
        }
      }
    }
  }

  const yAxisLabels = heatmapData.hheads.map((f) => {
    return { key: f.key, name: f.name };
  });

  return { faves, xAxisLabels, yAxisLabels, highlights };
};

export const SbbUtils = {
  find_bsp,
  sort_winsps,
  getRows,
  findRange,
  prepareHMData,
};
