import { SEARCH_QUERY } from "../endpoints";
import { request, RequestMethod } from "../requests";
import { MutateOptions, useMutation } from "@tanstack/react-query";
import { RsaQueryRequestItem } from "../../types";
import { AxiosError } from "axios";
import { useAccessTokenStore } from "../../store";
import { routes } from "../../routes/consts";
import { STATUS_CODE } from "../status-code";

export const useSearchQuery = (
  options: MutateOptions<
    unknown,
    AxiosError,
    RsaQueryRequestItem | RsaQueryRequestItem[],
    any
  >
) => {
  const accessToken = useAccessTokenStore((state: any) => state.accessToken);

  const searchQuery = async (
    paylod: RsaQueryRequestItem | RsaQueryRequestItem[]
  ) => {
    return await request(
      SEARCH_QUERY,
      { method: RequestMethod.POST, data: paylod },
      accessToken()
    ).catch((err) => {
      if (err?.response?.status === STATUS_CODE.UNAUTHORIZED) {
        window.location.replace(routes.LOGOUT);
      }
      throw err;
    });
  };
  return useMutation({
    mutationFn: searchQuery,
    mutationKey: ["search-query"],
    ...options,
  });
};
