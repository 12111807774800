import React from "react";
import { Styled } from "./styles";

export const generateBackgroundColor = (count: number) => {
  //console.log("BackgroundColor", `hsl(196deg 36% ${count > 0 ? 95 - count * 5 : 95}%)`);
  return `hsl(27.07deg 90% ${count > 0 ? 95 - count * 15 : 95}%)`;
};

export function generateLegend(data: any) {
  const deduped = Array.from(new Set(data)).map(Number);
  const minValue = Math.min(...deduped);
  const maxValue = Math.max(...deduped);
  const minColor = generateBackgroundColor(minValue);
  const maxColor = generateBackgroundColor(maxValue);

  return (
    <>
      {deduped && deduped.length > 0 && (
        <Styled.Legend>
          <div
            className="cell"
            style={{
              background: `linear-gradient(90deg, ${minColor} 0%, ${maxColor} 100%)`,
            }}
          />
          <div className="labels">
            <span className="label">{minValue}</span>
            <span className="label">{maxValue}</span>
          </div>
        </Styled.Legend>
      )}
    </>
  );
}
