import { SPTypesEnum } from "./bets";

export enum FilterValueEnum {
  string = "string",
  int = "int",
  float = "float",
  boolean = "boolean",
}

export enum ResultSegmentEnum {
  winners = "won",
  losers = "lost",
  money = "money",
}

export interface RsaRunner {
  selectionId: any;
  name: any;
  faveNo: any;
  highlight: boolean;
  status: any;
  img: any;
  jockey: any;
  bsp: { value: any; class: SPTypesEnum };
  bak1: any;
  bak2: any;
  lay1: any;
  lay2: any;
}

export type RaceDataType = {
  id: string;
  L_time: string;
  G_track: string;
  D_dis: string;
  E_type: string;
  H_country: string;
  F_ran: number;
  A_ran: number;
  jumpflat: string;
  M_dow: number;
  I_week: number;
  training?: boolean;
  ds: number;
};
