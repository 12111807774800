import Ajv from "ajv";

export const JsonValidate = (schema: any, jsonData: any) => {
  const ajv = new Ajv();
  const validate = ajv.compile(schema);
  let valid = validate(jsonData);
  if (!valid) {
    console.error(validate.errors);
  }
  return valid;
};
