import React from "react";
import DataAnalyticsImg from "../img/data-analytics.jpeg";
import styled from "styled-components";

const Container = styled.div`
  // Add any additional styles if needed
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Col = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  padding: 15px;

  @media (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

const AboutImage = styled.div`
  text-align: center;

  img {
    max-width: 100%;
    height: auto;
  }
`;

const AboutText = styled.div`
  // Add any additional styles if needed
`;

export const About = (props: any) => {
  return (
    <Container id="about">
      <div className="container">
        <Row>
          <Col>
            <AboutImage>
              <img src={DataAnalyticsImg} alt="" />
            </AboutImage>
          </Col>
          <Col>
            <AboutText>
              <h2>Advanced Racing Data & Analytics</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
            </AboutText>
          </Col>
        </Row>
      </div>
    </Container>
  );
};
