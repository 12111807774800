import React, { useState } from "react";
import { IconButton } from "@chakra-ui/react";
import { Styled } from "./styles";
import { BetSidesEnum, OrderType, PriceType, RsaBet } from "../../../types";
import { useBetsStore, useSetLayStore } from "../../../store";
import { DecimalInput } from "../../text-field";
import { BiSolidMessageAltAdd } from "react-icons/bi";

export const SetLayConfig = (props: { title: any }) => {
  const { title } = props;
  const { setPrice, setSize, price, size } = useSetLayStore(
    (state: any) => state
  );
  return (
    <>
      <Styled.PriceBadgeWrap>
        <span>P: </span>
        <Styled.PriceInput $side={BetSidesEnum.Lay}>
          <DecimalInput
            value={Number(price)}
            onChange={(value) => {
              setPrice(Number(value));
            }}
          />
        </Styled.PriceInput>

        <span>S: </span>
        <Styled.PriceInput $side={BetSidesEnum.Lay}>
          <DecimalInput
            value={Number(size)}
            prefix="£"
            onChange={(value) => {
              setSize(Number(value));
            }}
          />
        </Styled.PriceInput>
      </Styled.PriceBadgeWrap>
    </>
  );
};

export const SetLay = (props: {
  title: any;
  marketId: string;
  faveNo: number;
  bsp: number;
}) => {
  const { marketId, faveNo, bsp } = props;

  const getId = useBetsStore((state: any) => state.getId);
  const addBets = useBetsStore((state: any) => state.add);
  const [layChanged, setLayChanged] = useState(false);

  const { price: layPrice, size: laySize } = useSetLayStore(
    (state: any) => state
  );

  const addLayBet = () => {
    const bet: RsaBet = {
      id: getId(marketId, faveNo, 1),
      size: laySize,
      side: BetSidesEnum.Lay,
      faveNo: faveNo,
      price: layPrice,
      bsp: bsp,
      orderType: OrderType.LIMIT,
      priceType: PriceType.Fixed,
      userBet: false,
    };
    addBets(marketId, bet);
    setLayChanged(!layChanged);
  };

  return (
    <IconButton
      size="sm"
      fontSize={20}
      isRound={true}
      variant="unstyled"
      aria-label="Add Lay"
      icon={<BiSolidMessageAltAdd />}
      style={{ color: "#ff0027", padding: "0" }}
      onClick={() => addLayBet()}
    />
  );
};
