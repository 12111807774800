import React from "react";
const PricingCard = ({ pricingData }: any) => {
  const price = pricingData.price.toString().split("."),
    dollar = price[0];
  let cent = price[1] || "00";

  return (
    <div className={"pricingCard " + (pricingData.featured ? "featured" : "")}>
      <div className="title">{pricingData.title}</div>

      <div className="pricing-card">
        <h2 className="price">
          <span className="price__currency">{pricingData.currency}</span>
          <span className="price__dollar">{dollar}</span>.
          <span className="price__cent">{cent}</span>
        </h2>

        <p className="price-desc">{pricingData.priceDesc}</p>

        <p className="price-overview">{pricingData.priceOverview}</p>

        <p className="description">{pricingData.description}</p>
        <div>
          <ul>
            {pricingData.options
              ? pricingData.options.map((d: any, i: any) => (
                  <li key={`${d}-${i}`}>{d}</li>
                ))
              : "Loading..."}
          </ul>
        </div>
      </div>
      <a
        className={"bttn bttn-join-pricing"}
        href={`${pricingData.cta}`}
        target="_blank"
      >
        Join Now
      </a>
    </div>
  );
};

export default PricingCard;
