import { JumpFlatHarnessConvertor, RaceDataType } from "../../../types";
import { DateUtils, RsaDate } from "../../../utils/dateutils";

const time_left = function (market: any) {
  if (!market) return { css: "", text: "--" };
  let startTime = market.marketStartTime;
  // if (market.inplay && marketMgr.market_starts[market.marketId]) {
  //   startTime = marketMgr.market_starts[market.marketId];
  // }

  let pad = function (num: number, size: number) {
    var s = "000000000" + num;
    return s.substr(s.length - size);
  };

  let text = "";
  let now = new Date();
  let tStartTime = new Date(startTime);

  // get total seconds between the times
  var delta = Math.abs(tStartTime.getTime() - now.getTime()) / 1000;

  let css = market.inplay
    ? "inplay"
    : tStartTime > now
    ? "positive"
    : "negative";

  // calculate (and subtract) whole days
  var days = Math.floor(delta / 86400);
  text = `${days > 0 ? days + "d " : ""}`;
  delta -= days * 86400;

  // calculate (and subtract) whole hours
  var hours = Math.floor(delta / 3600) % 24;
  text = `${text}${hours > 0 ? pad(hours, 2) + ":" : ""}`;
  delta -= hours * 3600;

  // calculate (and subtract) whole minutes
  var minutes = Math.floor(delta / 60) % 60;
  text = `${text}${minutes > 0 ? pad(minutes, 2) + ":" : ""}`;
  delta -= minutes * 60;

  // what's left is seconds
  var seconds = delta % 60; // in theory the modulus is not required
  text = `${text}${seconds > 0 ? pad(Math.floor(seconds), 2) : ""}`;

  return { css, text };
};

const getRaceData = (
  market: any,
  raceData: any,
  date: RsaDate
): RaceDataType => {
  if (market && market.marketId && raceData?.training) {
    return {
      id: raceData.id,
      L_time: raceData.time,
      G_track: market.track,
      D_dis: market.dis,
      E_type: market.type,
      H_country: market.H_state,
      F_ran: market.numberOfRunners,
      A_ran: market.numberOfActiveRunners,
      jumpflat: JumpFlatHarnessConvertor(market.W_jump_flat),
      M_dow: date.dow,
      I_week: date.woy,
      training: raceData.training,
      ds: date.ds,
    };
  }
  if (raceData) {
    return {
      ...raceData,
      A_ran: market.numberOfActiveRunners,
      jumpflat: JumpFlatHarnessConvertor(raceData.W_jump_flat),
    };
  }

  return raceData;
};

const getMarket = (
  currentMarketId: string,
  marketData: any,
  marketUpdate: any
): any => {
  return !marketUpdate || marketUpdate.marketId !== currentMarketId
    ? marketData
    : {
        ...marketData,
        marketStatus: marketUpdate.marketStatus,
        inplay: marketUpdate.inplay,
        status: marketUpdate.status,
        complete: marketUpdate.complete,
        in_result: marketUpdate.in_result,
        numberOfWinners: marketUpdate.numberOfWinners,
        numberOfRunners: marketUpdate.numberOfRunners,
        numberOfActiveRunners: marketUpdate.numberOfActiveRunners,
        //totalMatched,isMarketDataDelayed,betDelay,bspReconciled,
        // crossMatching,lastMatchTime,totalAvailable,runnersVoidable,version
        orders: marketUpdate.orders,
        //sortPriority, totalMatched, lastPriceTraded, adjustmentFactor, handicap
        runners: marketData?.runners?.map((runner: any) => {
          const updatedRunner = marketUpdate?.runners?.find(
            (r: any) => r.selectionId === runner.selectionId
          );
          return updatedRunner
            ? {
                ...runner,
                favNo: updatedRunner.favNo,
                status: updatedRunner.status,
                sp: updatedRunner.sp,
                ex: updatedRunner.ex,
              }
            : runner;
        }),
      };
};

const getRefreshFrq = function (m: any) {
  let frq = 0; //sec
  if (
    !m ||
    !m.marketStartTime ||
    (!/OPEN/i.test(m.status) && !/SUSPENDED/i.test(m.status))
  )
    return -1;
  if (m && !m.in_result) {
    // && $("#bf-chk-autoupdate").attr("data-state") == "1") {
    let left = new Date(m.marketStartTime).getTime() - new Date().getTime();
    if (left > 300000) {
      //5 min
      frq = 30;
    } else if (left > 120000) {
      //2 min
      frq = 10;
    } else if (left > 60000) {
      // 1min
      frq = 5;
    } else if (left > 30000) {
      //30 sec
      frq = 3;
    } else if (left > 5000) {
      // above 5 sec
      frq = 2;
    } else if (left <= 5000) {
      //under 5 sec
      frq = 1;
    }
  } else {
    frq = 30; //$("#bf-select-refresh-market").val();
  }

  return frq;
};

export { time_left, getRaceData, getMarket, getRefreshFrq };
