import styled from "styled-components";

const NumberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  label {
    font-size: 14px;
    font-weight: bold;
  }
`;

const NumberRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 8px;
`;
const InputWrapper = styled.div`
  display: flex;
  width: 50%;
  gap: 4px;
  align-items: center;
  input{
    width: 65px;
    border: 1px solid #d1d1d1;
  }
`;

export const Styled = {
  NumberRow,
  InputWrapper,
  NumberWrapper,
};
