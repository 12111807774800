import React, { useState } from "react";
import { Header } from "./header";
import { useMainLookups } from "../../services";
import { RsaFilters } from "../filters";
import { Styled } from "./styles";
import { Drawer } from "../drawer/drawer";
interface LayoutProps {
  children: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = (props) => {
  const { data: lookupData, isLoading } = useMainLookups();
  const [open, setOpen] = useState(false);

  const onClickOpen = () => {
    setOpen(true);
  };

  const onClickClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Drawer
        isOpen={open}
        onClose={onClickClose}
        onOpen={onClickOpen}
        title="Filters"
      >
        {!isLoading && <RsaFilters lookupData={lookupData} />}
      </Drawer>
      <Styled.ContentWrapper $isOpen={open}>
        <Header />
        <Styled.ContentContainer>{props.children}</Styled.ContentContainer>
      </Styled.ContentWrapper>
    </div>
  );
};
