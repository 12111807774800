import React, { useState, useMemo } from "react";
import { BetMakerTable } from "./table";
import { usePlaceOrder, useCancelOrders, useGetOrders } from "../../services";
import { useBetsStore, useTrainingPnLStore } from "../../store";
import { SyncFilters } from "./sync-filters";
import { RaceDataType, RsaBet, RsaRunner } from "../../types";
import { BetUtils } from "./utils";
import { useGetColumns } from "./columns";

export type BetMakerProps = {
  market: any;
  raceData: RaceDataType | undefined;
  runners: RsaRunner[];
  isTraining: boolean;
  onOrderSubmit: () => void;
  marketLoading: boolean;
};

export const BetMaker = (props: BetMakerProps) => {
  const clearBets = useBetsStore((state: any) => state.clear);
  const addTrainingBets = useTrainingPnLStore((state: any) => state.add);

  const [manualTrigger, setManualTrigger] = useState<number>(0);
  const {
    market,
    raceData,
    runners,
    isTraining,
    onOrderSubmit,
    marketLoading,
  } = props;
  const currentMarketId = market?.marketId;

  const placeOrder = usePlaceOrder({
    onError: (err: any) => {
      console.log("ERR:", err);
    },
    onSuccess: (data: any) => {
      setManualTrigger(manualTrigger + 1);
      if (isTraining) {
        addTrainingBets(currentMarketId, data);
      }
      onOrderSubmit();
    },
  });

  const cancelOrder = useCancelOrders({
    onError: (err: any) => {
      console.log("ERR:", err);
    },
    onSuccess: (data: any) => {
      setManualTrigger(manualTrigger + 1);
    },
  });

  const { data: orderData, isLoading: ordersLoading } = useGetOrders(
    currentMarketId,
    manualTrigger,
    isTraining
  );

  const orders = useMemo(() => {
    return (orderData?.timestamp || 0) > (market?.timestamp || 0)
      ? orderData?.orders
      : market?.orders?.orders;
  }, [orderData, market]);

  const submitOrders = (bets: RsaBet[], update?: boolean) => {
    if (!currentMarketId || !bets || bets.length <= 0) return;

    placeOrder.mutate({
      marketId: currentMarketId,
      isSimulate: isTraining,
      update: update,
      bets: BetUtils.prepareForSubmit(bets, runners),
    });

    clearBets(currentMarketId);
  };

  const onUpdateUnmatched = (cancel: boolean, bets?: RsaBet[]) => {
    if (cancel) {
      cancelOrder.mutate({ marketId: currentMarketId, bets: [] });
    } else {
      submitOrders(bets || [], true);
    }
  };

  const { columns } = useGetColumns(
    market,
    isTraining,
    runners,
    orders,
    submitOrders,
    onUpdateUnmatched
  );

  return (
    <>
      <>
        {raceData && <SyncFilters raceData={raceData} />}

        <BetMakerTable
          market={market}
          raceData={raceData}
          marketStatus={market?.marketStatus || "N/A"}
          runners={runners}
          columns={columns}
          loading={marketLoading}
        />
      </>
    </>
  );
};
