import React, { useState } from "react";
import { QuickFinderDataType } from "../../types";
import { QuickFinderComponent } from "../../components/qfinder";

export const QuickFinder = () => {
  const [filterData, setRaceData] = useState<QuickFinderDataType | undefined>();
  const [bsps, setBsps] = useState<number[] | undefined>();

  window.addEventListener("message", (event) => {
    if (event.origin !== window.location.origin) {
      return;
    }
    if (event.data?.type === "RaceDataType") {
      setRaceData(event.data?.data || null);
      setBsps(event.data?.bsps || null);
    }
  });

  return <QuickFinderComponent bsps={bsps} filterData={filterData} />;
};
