import React, { useEffect, useState, useMemo } from "react";
import { BaseTable } from "./base";
import { Utils } from "./utils";
import { useResultsSegmentStore } from "../../store";
import { RsaTableProps } from "./types";
import { prepareTableData, createColumns } from "./columns";
import { Styled } from "./styles";

export const MultiValueTable = (
  props: RsaTableProps & { keyField: string }
) => {
  const { data, title, keyField, options = {}, ...rest } = props;
  let [filteredData, setFilteredData] = useState<{
    rows: any[];
    totals?: any[];
  }>({
    rows: [],
    totals: [],
  });

  options.formatter =
    options.formatter ||
    ((name: string, value: any) => {
      return (
        <div
          className={
            value && Utils.roundInt(value) < 0 ? "negative" : "positive"
          }
        >
          {value && Utils.roundInt(value)}
        </div>
      );
    });

  if (!options.additionalColumns) {
    options.additionalColumns = [{ key: "all", name: "All" }];
  }

  const segment = useResultsSegmentStore((state: any) => state.segment);
  const columns = useMemo(
    () => createColumns(keyField, options),
    [keyField, options]
  );

  const { segmentOptions, rowClass } = useMemo(() => {
    const segmentOptions = {
      ...options,
      valueSelector: (value: any) => {
        if (typeof value === "object") {
          if (segment in value) {
            return value[segment];
          } else {
            return "";
          }
        }
        return value;
      },
    };

    const rowClass =
      props.rowClass ||
      ((row: any, index: number) => {
        return `rdg-${segment}`;
      });

    return { segmentOptions, rowClass };
  }, [segment]);

  useEffect(() => {
    if (data) {
      setFilteredData(prepareTableData(data, segmentOptions));
    }
  }, [data, segment]);

  return (
    <Styled.MVTableContainer $size={options.size}>
      <BaseTable
        rows={filteredData.rows}
        size={options?.size}
        columns={columns}
        totals={filteredData.totals}
        rowClass={rowClass || (() => "")}
        {...rest}
      />
    </Styled.MVTableContainer>
  );
};
