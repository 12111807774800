import { DAYSHEET_DAILY, DAYSHEET_FAVES, templateString } from "../endpoints";
import { request, RequestMethod } from "../requests";
import { useQuery } from "@tanstack/react-query";
import { RsaFaveQueryRequest } from "../../types";
import { useAccessTokenStore } from "../../store";
import { WeekDate } from "../../utils/dateutils";
import { getYears } from "../search/utils";

export const useDaySheetQuery = (date: WeekDate) => {
  const accessToken = useAccessTokenStore((state: any) => state.accessToken);

  const daysheetQuery = async () => {
    const paylod: RsaFaveQueryRequest = {
      week: { from: date.woy, to: date.woy },
      years: getYears() as number[],
    };

    let faves = request(
      DAYSHEET_FAVES,
      { method: RequestMethod.POST, data: paylod },
      accessToken()
    );
    const id = `${date.year}-${date.woy}`;
    const dailyUrl = templateString(DAYSHEET_DAILY, {
      id,
      dow: `${date.dow}`,
      qtraining: "",
    });
    let daily = request(dailyUrl, { method: RequestMethod.GET }, accessToken());

    const [dailyResponse, favesResponse] = await Promise.all([daily, faves]);
    return { dailyData: dailyResponse, favesData: favesResponse };
  };

  return useQuery({
    queryFn: daysheetQuery,
    queryKey: ["daysheet-query", date],
  });
};
