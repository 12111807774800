import { BETFAIR_PROFILE } from "../endpoints";
import { request, RequestMethod } from "../requests";
import { useQuery } from "@tanstack/react-query";
import { useAccessTokenStore, useBfAccessTokenStore } from "../../store";
import { UseQueryResult } from "@tanstack/react-query";
import { DEFAULT_API_CACHE } from "../../types";

export type BetfairProfile = {
  firstName: string;
  lastName: string;
  email: string;
  currencyCode: string;
  currencySign: string;
  balance: number;
  limit: number;
};

export const useGetBetfairProfile = (): UseQueryResult<
  BetfairProfile,
  Error
> => {
  const accessToken = useAccessTokenStore((state: any) => state.accessToken);
  const bfAccessToken = useBfAccessTokenStore(
    (state: any) => state.accessToken
  );

  return useQuery({
    queryFn: () =>
      request(
        BETFAIR_PROFILE,
        { method: RequestMethod.GET },
        accessToken(),
        bfAccessToken()
      ),
    queryKey: ["betfair-profile"],
    staleTime: DEFAULT_API_CACHE
  });
};
