import React from "react";
import { Select } from "@chakra-ui/react";
import { useFilterStore } from "../../store";
import { RsaSelectProps } from "./types";

export const RsaSelect = (props: RsaSelectProps) => {
  const { items, placeholder, size, storekey, datatype, storage, ...rest } =
    props;

  const dataStore = (storage || useFilterStore)((state: any) =>
    state.get(storekey)
  );
  const store = (storage || useFilterStore)((state: any) => state.store);

  return (
    <div style={{ display: "inline-flex" }}>
      <div style={{ display: "inline-flex", margin: "5px 5px 0 10px" }}>
        {props.label}
      </div>
      <div>
        <Select
          placeholder={placeholder}
          size={size}
          value={dataStore}
          isDisabled={props.isDisabled}
          onChange={(event) => {
            store(storekey, datatype, event.target.value);
          }}
          {...rest}
        >
          {items.map((item, index) => (
            <option key={`option-${item.id}-${index + 1}`} value={item.id}>
              {item.label}
            </option>
          ))}
        </Select>
      </div>
    </div>
  );
};
