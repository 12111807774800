import React, { useMemo } from "react";
import "react-data-grid/lib/styles.css";
import DataGrid from "react-data-grid";
import { Styled } from "./styles";
import { BaseTableProps, SummaryRow } from "./types";

import { EmptyRowsRenderer } from "./empty-box";
import Skeleton from "react-loading-skeleton";

export const BaseTable = (props: BaseTableProps) => {
  const { columns, rows, totals, loading, title, size, ...rest } = props;

  const summaryRows = useMemo((): readonly SummaryRow[] => {
    return [
      {
        id: "total_0",
        totals: totals && totals.length > 0 ? totals[0] : {},
      },
    ];
  }, [totals]);

  return (
    <Styled.TableContainer $size={size}>
      {loading ? (
        <Styled.TableLoadingWrapper>
          <Skeleton count={10} />
        </Styled.TableLoadingWrapper>
      ) : (
        <>
          {rows?.length === 0 ? (
            <EmptyRowsRenderer title={title} />
          ) : (
            <DataGrid
              columns={columns}
              rows={rows}
              className="rdg-light"
              bottomSummaryRows={summaryRows}
              {...rest}
            />
          )}
        </>
      )}
    </Styled.TableContainer>
  );
};
