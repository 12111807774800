import React from "react";
import { BaseTable } from "../tables";
import { RsaRunner } from "../../types";
import { time_left } from "./utils";
import { Styled } from "./styles";
import { Stack } from "@chakra-ui/react";
import "./tables.css";
import { SetLayConfig, MyBetTools } from "./betting-tools";
import { RsaTableColumn } from "../tables/types";
import { useWindow } from "../../hook/use-window";

export const BetMakerTable = (props: {
  market: any;
  runners: RsaRunner[];
  columns: RsaTableColumn[];
  raceData: any;
  marketStatus: string;
  loading: boolean;
}) => {
  const { market, marketStatus, runners, columns, raceData } = props;

  let inplay = market?.inplay && !/CLOSED/i.test(market?.status);
  let suspended = /SUSPENDED/i.test(market?.status);
  let displayTime = time_left(market);

  const getHeight = (x: number) => {
    return Math.round(49.44 + 32.26 * x + 0.263 * x * x - 0.00756 * x * x * x);
  };

  const width = useWindow().innerWidth;

  const gridHeight = getHeight(runners.length) + 30;
  const overlayHeight = getHeight(runners.length);

  //@Nasim to review
  return (
    <div className="bet-maker">
      {raceData && (
        <Styled.MarketHeader>
          <div className="market-info in-play">{inplay ? "In-Play" : ""}</div>
          <span className="market-info market-status">{marketStatus}</span>
          <span className={`market-info  time-left ${displayTime.css}`}>
            {market.im_result ? "--" : displayTime.text}
          </span>
          <span className="market-info">{raceData?.G_track}</span>
          <span className="market-info">{raceData?.D_dis}</span>
          <span className="market-info-gap">|</span>
          <span className="market-info">{raceData?.E_type}</span>
          <span className="market-info">{raceData?.H_country}</span>
          <span className="market-info">{raceData?.A_ran}</span>
          <span>|</span>
          <Stack direction="row" style={{ width: "320px" }}>
            <SetLayConfig title="Set Lay" />
            <MyBetTools name="My Bets" title="" />
          </Stack>
        </Styled.MarketHeader>
      )}

      {suspended && (
        <div className="bf-overlay" style={{ height: overlayHeight }}>
          <div className="bf-overlay-text">Suspended</div>
        </div>
      )}
      <BaseTable
        rows={runners || []}
        columns={columns}
        style={{
          height: gridHeight, // Dynamically set the height based on rows
          width: width > 768 ? "1010px" : "100%",
          overflow: "auto", // Prevent scrolling inside the grid
        }}
        loading={props.loading}
      />
    </div>
  );
};
