import React, { useState, useMemo } from "react";
import { IconButton, Stack } from "@chakra-ui/react";
import { BiSolidCartAdd } from "react-icons/bi";
import { BetDialog } from "./dialog";
import { RsaBet, RsaRunner } from "../../../types";
import { ActiveBetMode, ActiveBets } from "./active-bets";
import { useBetsStore } from "../../../store";

export const BettingControls = (props: {
  marketId: string;
  faveNo: number;
  bsp: number;
  bets: RsaBet[];
  runners: RsaRunner[];
  orders?: any[];
  onUpdateUnmatched: (cancel: boolean, bets?: RsaBet[]) => void;
}) => {
  const { marketId, faveNo, bsp, bets, onUpdateUnmatched } = props;
  const setBets = useBetsStore((state: any) => state.set);
  const [dialogData, setDialogData] = useState<{
    bets: RsaBet[];
    unmatched: boolean;
  } | null>(null);

  function updateBet(bets: RsaBet[]): void {
    if (dialogData?.unmatched) {
      onUpdateUnmatched(bets.length <= 0, bets);
    } else {
      setBets(marketId, bets, faveNo);
    }
    setDialogData(null);
  }

  const unmatched = useMemo(() => {
    return (
      props.orders?.flatMap((o) => {
        return o.unmatched.map((u: any) => {
          return { ...u, selectionId: o.selectionId };
        });
      }) || []
    );
  }, [JSON.stringify(props.orders)]);

  return (
    <>
      <BetDialog
        key={`${marketId}:${faveNo}`}
        isOpen={dialogData !== null}
        onClose={() => setDialogData(null)}
        onCloseAndUpdate={updateBet}
        marketId={marketId}
        faveNo={faveNo}
        bsp={bsp}
        unmatched={dialogData?.unmatched || false}
        bets={dialogData?.bets || []}
        runners={props.runners}
      />
      <Stack direction="row">
        {bets.length <= 0 ? (
          <>
            <IconButton
              size="sm"
              fontSize={22}
              variant="unstyled"
              aria-label="Add Bet"
              icon={<BiSolidCartAdd />}
              style={{ color: "#012285" }}
              onClick={() => setDialogData({ bets, unmatched: false })}
            />
          </>
        ) : (
          <ActiveBets
            bets={bets}
            mode={ActiveBetMode.Local}
            onClick={() => setDialogData({ bets, unmatched: false })}
          />
        )}

        {unmatched.length > 0 && (
          <ActiveBets
            bets={unmatched}
            mode={ActiveBetMode.Unmatched}
            onClick={() => setDialogData({ bets: unmatched, unmatched: true })}
          />
        )}
      </Stack>
    </>
  );
};
