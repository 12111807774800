import { create } from "zustand";
import { ResultSegmentEnum } from "../types";

export const useResultsSegmentStore = create((set, get) => ({
  segment: ResultSegmentEnum.money,
  index: 2,
  store: (index: number, value: ResultSegmentEnum) =>
    set(() => {
      return { segment: value, index: index };
    }),
  clear: () => set({ state: ResultSegmentEnum.money }, true),
}));
