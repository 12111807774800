import React from "react";

import "./App.css";
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { appRoutes } from "./routes";
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useWorkerStore } from "./store";
import "react-loading-skeleton/dist/skeleton.css";
// Create the router instance
const router = createBrowserRouter(createRoutesFromElements(appRoutes));
const queryClient = new QueryClient();

function App() {
  const setworker = useWorkerStore((state: any) => state.setworker);
  const worker = useWorkerStore((state: any) => state.worker);

  if (!worker) {
    setworker(new Worker(new URL("./worker/ticker-worker", import.meta.url)));
  }

  return (
    <ChakraProvider>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </ChakraProvider>
  );
}

export default App;
