import {
  BETFAIR_MARKET_DETAILS,
  TRAINING_MARKET_DETAILS,
  templateString,
} from "../endpoints";
import { bfRequest, request, RequestMethod } from "../requests";
import { useAccessTokenStore, useBfAccessTokenStore } from "../../store";
import { useQuery } from "@tanstack/react-query";

export const useGetMarketDetails = (marketId: string, isSimulate: boolean) => {
  const accessToken = useAccessTokenStore((state: any) => state.accessToken);
  const bfAccessToken = useBfAccessTokenStore(
    (state: any) => state.accessToken
  );

  return useQuery({
    queryFn: () => {
      if (!marketId) return { marketId: "", error: "No market id" };

      if (isSimulate) {
        return request(
          templateString(TRAINING_MARKET_DETAILS, { marketId }),
          { method: RequestMethod.GET },
          accessToken()
        );
      }

      return bfRequest(
        templateString(BETFAIR_MARKET_DETAILS, { marketId }),
        { method: RequestMethod.GET },
        accessToken(),
        bfAccessToken()
      );
    },
    queryKey: ["get-market-detail", marketId, isSimulate],
    notifyOnChangeProps: ["data", "error"],
    refetchOnWindowFocus: false,
  });
};
