import React from "react";
import { useAccessTokenStore } from "../store";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { routes } from "./consts";
import { LoginErrorCodeEnum, useUserRefreshToken } from "../services";

interface ProtectRoutesProps {
  children: any;
}

export const ProtectRoutes: React.FC<ProtectRoutesProps> = (props) => {
  const loggedIn = useAccessTokenStore((state: any) => state.loggedIn());
  const { data, isLoading: logingInProgress } = useUserRefreshToken();

  const { children } = props;
  const navigate = useNavigate();
  useEffect(() => {
    if (!loggedIn && data?.error === LoginErrorCodeEnum.NoRefreshToken) {
      navigate(routes.LOGIN);
    }
  }, [loggedIn, navigate, data]);

  return loggedIn ? <>{children}</> : null;
};
