import React, { useEffect, useMemo, useState } from "react";
import { Switch } from "@chakra-ui/react";
import { FilterValueEnum } from "../../types";
import { useFilterStore } from "../../store";
import { UseBoundStore } from "zustand/react";
import { StoreApi } from "zustand/vanilla";
import { GStyled } from "../../styles/global";

export const ValueSwitch = (props: {
  size?: "sm" | "md" | "lg";
  colorScheme?: string;
  label?: string;
  value: string | string[] | undefined;
  defaultChecked?: boolean;
  storekey: string;
  storage?: UseBoundStore<StoreApi<unknown>>;
}) => {
  const { storekey, storage, value, defaultChecked, ...rest } = props;
  const [checked, setChecked] = useState(defaultChecked);
  const dataStore = (storage || useFilterStore)((state: any) =>
    state.get(storekey)
  );
  const store = (storage || useFilterStore)((state: any) => state.store);
  const clearStore = (storage || useFilterStore)((state: any) => state.clear);

  useEffect(() => {
    store(storekey, FilterValueEnum.string, value);
  }, [JSON.stringify(value || "")]);

  useEffect(() => {
    onChange(defaultChecked || false);
  }, [defaultChecked]);

  const onChange = (isChecked: boolean) => {
    setChecked(isChecked ? true : false);
    if (!isChecked) {
      clearStore(storekey);
    } else {
      store(storekey, FilterValueEnum.string, isChecked ? value : null);
    }
  };

  const displayValue: string | undefined | null = useMemo(() => {
    if (!dataStore || !checked) {
      return null;
    }

    if (Array.isArray(dataStore)) {
      return dataStore.length > 0 && dataStore.length <= 2
        ? dataStore.join(", ")
        : dataStore.length > 2
        ? `${dataStore.length} selected`
        : null;
    }

    return dataStore;
  }, [dataStore, checked]);

  return (
    <GStyled.Stack $dir="column">
      <div>{props.label}</div>
      <GStyled.Stack
        $dir="row"
        $h={32}
        $spacing={5}
        $justify="space-between"
        $border="1px solid"
      >
        <div>{displayValue}</div>
        <Switch
          onChange={(event) => onChange(event.target.checked)}
          isChecked={checked}
          {...rest}
        />
      </GStyled.Stack>
    </GStyled.Stack>
  );
};
