import React, { useState } from "react";
import { useSearchQuery } from "../../services";
import { useGetFinderFilters } from "../../store";

import { RsaQueryAggsEnum, RsaQueryResponse } from "../../types";
import {
  MultiValueTable,
  GroupedTable,
  FavTable,
} from "../../components/tables";

import {
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";

import { Styled } from "./styles";

export const BetFinder = () => {
  let [queryResponse, setQueryResponse] = useState<RsaQueryResponse | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const getSearch = useSearchQuery({
    onError: (err: any) => {
      console.log("ERR:", err);
    },
    onSuccess: (data: any) => {
      setQueryResponse(data);
      setLoading(false);
    },
  });

  const query = useGetFinderFilters([
    RsaQueryAggsEnum.Week,
    RsaQueryAggsEnum.Dow,
    RsaQueryAggsEnum.Ran,
    RsaQueryAggsEnum.Type,
    RsaQueryAggsEnum.Dis,
    RsaQueryAggsEnum.Track,
    RsaQueryAggsEnum.RaceNo,
    RsaQueryAggsEnum.SP,
    RsaQueryAggsEnum.Fav,
    RsaQueryAggsEnum.Money,
  ]);

  const onQuery = async () => {
    setLoading(true);
    getSearch.mutate(query);
  };

  return (
    <>
      <Styled.FinderRow>
        <Styled.FinderLeftWrapper>
          <FavTable
            data={queryResponse?.yrs?.Fav}
            title="Fav"
            loading={loading}
          />
          <MultiValueTable
            data={queryResponse?.yrs?.Dow}
            title="DOW"
            keyField="DOW"
            loading={loading}
          />
        </Styled.FinderLeftWrapper>
        <Styled.FinderRightWrapper>
          <Tabs width="100%">
            <TabList>
              <Tab>Type</Tab>
              <Tab>Track</Tab>
              <Tab>Distance</Tab> {/* Fixed typo in Distance */}
              <Tab>Race No.</Tab>
              <Tab>Ran</Tab>
              <Tab>SP</Tab>
              <Tab>IR</Tab>
              <Tab>Week</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <MultiValueTable
                  data={queryResponse?.yrs?.Type}
                  title="Type"
                  keyField="Type"
                  loading={loading}
                />
              </TabPanel>
              <TabPanel>
                <MultiValueTable
                  data={queryResponse?.yrs?.Track}
                  title="Track"
                  keyField="Track"
                  loading={loading}
                />
              </TabPanel>
              <TabPanel>
                <MultiValueTable
                  data={queryResponse?.yrs?.Dis}
                  title="Distance"
                  keyField="Distance"
                  loading={loading}
                />
              </TabPanel>
              <TabPanel>
                <GroupedTable
                  data={queryResponse?.yrs?.RaceNo}
                  title="Race No."
                  levels={2}
                  loading={loading}
                />
              </TabPanel>
              <TabPanel>
                <MultiValueTable
                  data={queryResponse?.yrs?.Ran}
                  title="Ran"
                  keyField="Ran"
                  loading={loading}
                />
              </TabPanel>
              <TabPanel>
                <MultiValueTable
                  data={queryResponse?.yrs?.SP}
                  title="SP"
                  keyField="SP"
                  options={{
                    vh_formatter: (value: number, row: any) =>
                      Number(value / 100).toFixed(2),
                  }}
                  loading={loading}
                />
              </TabPanel>
              <TabPanel>
                <GroupedTable
                  data={queryResponse?.yrs?.IR}
                  title="IR"
                  levels={1}
                />
              </TabPanel>
              <TabPanel>
                <MultiValueTable
                  data={queryResponse?.yrs?.Week}
                  title="Week"
                  keyField="Week"
                  loading={loading}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Styled.FinderRightWrapper>
      </Styled.FinderRow>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          colorScheme="teal"
          type="submit"
          style={{ width: "400px", marginTop: 16 }}
          onClick={onQuery}
        >
          {getSearch.isPending && "loading"}
          Query
        </Button>
      </div>
    </>
  );
};
