import React from "react";
import { useState } from "react";
import { Image } from "./image";
import { Modal } from "./Modal";
import styled from "styled-components";

const Container = styled.div`
  text-align: center;
`;

const SectionTitle = styled.div`
  margin: 0 auto;
  max-width: 80%;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Col = styled.div`
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding: 15px;

  @media (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

const PortfolioItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const PortfolioItem = styled.div`
  flex: 0 0 25%;
  max-width: 25%;
  padding: 15px;

  @media (max-width: 768px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (max-width: 576px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

type TGallery = {
  data: any;
};

export const Gallery = ({ data }: TGallery) => {
  const [overlay, setOverlay] = useState(false);
  const [selectedImg, setSelectedImg] = useState<any>(null);

  return (
    <>
      <Modal overlay={overlay} onClose={() => setOverlay(false)}>
        <div className="portfolio-details">
          <img src={selectedImg?.largeImage} style={{ width: "100%" }} />
          {selectedImg?.details.map((item: any, index: any) => {
            return (
              <span key={index + item.title}>
                <h3>{item?.title}</h3>
                <p>{item?.desc}</p>
              </span>
            );
          })}
        </div>
      </Modal>
      <Container id="portfolio">
        <div className="container">
          <SectionTitle>
            <h2>Gallery</h2>
            <p>RSA Component Screenshots</p>
          </SectionTitle>
          <Row>
            <PortfolioItems>
              {data.map((d: any, i: any) => (
                <PortfolioItem
                  key={`${d.title}-${i}`}
                  onClick={() => {
                    setOverlay(true);
                    setSelectedImg(d);
                  }}
                >
                  <Image title={d.title} smallImage={d.smallImage} />
                </PortfolioItem>
              ))}
            </PortfolioItems>
          </Row>
        </div>
      </Container>
    </>
  );
};
