import { schema_v1 } from "./schema/v1";
import { schema_v2 } from "./schema/v2";
import { JsonValidate } from "../../utils/json-validation";

interface SystemData {
  [key: string]: {
    name: { value: string };
    wk: { plus: string; minus: string };
    ran: { plus: string; minus: string };
    dow: { value: string };
    trk: { value: string };
    dis: { value: string };
    type: { value: string };
    cry: { value: string };
    jfh: { value: string };
  };
}

const convert = (data: SystemData) => {
  const valid = JsonValidate(schema_v1, data);
  if (!valid) {
    return null;
  }

  let filters: any = {};
  let systems = ["s1", "s2", "s3"];
  let counts = 0;
  systems.forEach((sys) => {
    filters[sys] = {
      name: data[sys].name.value,
      week: {
        from: data[sys].wk.plus,
        to: data[sys].wk.minus,
      },
      ran: {
        from: data[sys].ran.plus,
        to: data[sys].ran.minus,
      },
      dow: data[sys].dow.value,
      track: data[sys].trk.value,
      distance: data[sys].dis.value,
      type: data[sys].type.value,
      state: data[sys].cry.value,
      jumpflat: data[sys].jfh.value,
    };
    if (filters[sys] && filters[sys].name) {
      counts++;
    }
  });

  if (counts < 3) {
    return null;
  }
  console.log("filters", filters);
  return filters;
};

export const import_data = (data: string) => {
  try {
    const parsedData = JSON.parse(data);
    debugger;
    if (parsedData) {
      if (parsedData.version && parsedData.version >= 2 && parsedData.filters) {
        const valid = JsonValidate(schema_v2, parsedData);
        if (valid) {
          return parsedData.filters;
        }
      } else {
        return convert(parsedData);
      }
    }
  } catch (error) {
    console.error("Failed to parse data:", error);
  }
  return null;
};
