import React from "react";
import styled from "styled-components";
import { BiLogoTwitter, BiCopyright } from "react-icons/bi";

const Container = styled.div`
  // Add any additional styles if needed
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Col = styled.div`
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding: 15px;

  @media (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

const ContactInfo = styled.div`
  // Add any additional styles if needed
`;

const ContactItem = styled.div`
  // Add any additional styles if needed
`;

const Social = styled.div`
  ul {
    list-style: none;
    padding: 0;
  }

  li {
    display: inline-block;
    margin-right: 10px;
  }
`;

const Footer = styled.div`
  text-align: center;
  padding: 20px 0;
`;

export const Contact = (props: any) => {
  return (
    <Container>
      <div id="contact">
        <div className="container">
          <Row>
            <Col>
              <ContactInfo>
                <ContactItem>
                  <h3>Contact Info</h3>
                  <p>
                    <a href="mailto:info@racestatsapp.co.uk">
                      info@RaceStatsApp.co.uk
                    </a>
                  </p>
                  <p>
                    <span>
                      <i className="fa fa-map-marker"></i> Address
                    </span>
                    {props.data ? props.data.address : "loading"}
                  </p>
                  <Social>
                    <ul>
                      <li>
                        <a
                          href={props.data ? props.data.twitter : "/"}
                          target="_blank"
                        >
                          <BiLogoTwitter fontSize={24} />
                        </a>
                      </li>
                    </ul>
                  </Social>
                </ContactItem>
              </ContactInfo>
            </Col>
          </Row>
        </div>
      </div>
      <Footer id="footer">
        <div className="container">
          <p>
            Vexter.co.uk Ltd &copy; 2015 - 2025 Michael O'Neil Sole distributors
            Of RaceStatsApp Worldwide 48 Broadway, Peterborough, PE1 1YW United
            Kingdom
          </p>
        </div>
      </Footer>
    </Container>
  );
};
