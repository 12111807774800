import { create } from "zustand";
import { persist } from "zustand/middleware";
import { BetSidesEnum } from "../types";

export const useBetMakerStakesStore = create(
  persist(
    (set, get) => ({
      BACK: 0.25,
      LAY: 0.25,
      store: (value: any, side: BetSidesEnum) =>
        set((state: any) => {
          if (side === BetSidesEnum.Lay) {
            return { ...state, LAY: Number(value) };
          }
          return { ...state, BACK: Number(value) };
        }),
      clear: () => set({}, true),
    }),
    { name: "_bm_stakes" }
  )
);

export const useSetLayStore = create(
  persist(
    (set, get) => ({
      price: 0.25,
      size: 0.25,
      setPrice: (value: any) =>
        set((state: any) => {
          return { ...state, price: Number(value) };
        }),
      setSize: (value: any) =>
        set((state: any) => {
          return { ...state, size: Number(value) };
        }),
    }),
    { name: "_bm_setlays" }
  )
);
