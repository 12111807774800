import React from "react";

import { useGetBetfairProfile } from "../../services";
import { Styled } from "./styles";

export const BetfairProfile = (props: any) => {
  const { data: betfairProfile, isLoading: profileLoading } =
    useGetBetfairProfile();

  return (
    <Styled.BetfairProfileWrapper>
      <div>
        <div style={{ whiteSpace: "nowrap" }}>
          <strong>Available Balance</strong>
        </div>
        <div>
          {betfairProfile &&
            `${betfairProfile.currencySign}${betfairProfile.balance}`}
        </div>
      </div>
      <div>
        <a
          href="http://www.betfair.com/www/GBR/en/aboutUs/Betfair.Charges/"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "underline", padding: "0px" }}
        >
          Betfair Charges
        </a>
      </div>
    </Styled.BetfairProfileWrapper>
  );
};
