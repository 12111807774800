const settings_default = {
  s1: {
    name: "Match MBF",
    week: {
      from: 1,
      to: 16,
    },
    ran: {
      from: 0,
      to: 0,
    },
    dow: false,
    track: false,
    distance: true,
    type: true,
    state: true,
    jumpflat: false,
  },
  s2: {
    name: "Plus 2 runners",
    week: {
      from: 0,
      to: 16,
    },
    ran: {
      from: 0,
      to: 0,
    },
    dow: false,
    track: false,
    distance: true,
    type: false,
    state: true,
    jumpflat: false,
  },
  s3: {
    name: "PF meetings",
    week: {
      from: 1,
      to: 1,
    },
    ran: {
      from: 2,
      to: 2,
    },
    dow: false,
    track: true,
    distance: true,
    type: true,
    state: true,
    jumpflat: false,
  },
};

const settings_layout = [
  {
    id: "week",
    type: "range",
    title: "Week/s",
    order: 1,
    css: "weeks53-select",
  },
  {
    id: "ran",
    type: "range",
    title: "Runners",
    order: 2,
    css: "runners50-select",
  },
  {
    id: "dow",
    type: "switch",
    title: "Day of Week",
    order: 3,
  },
  {
    id: "track",
    type: "switch",
    title: "Track",
    order: 4,
  },
  {
    id: "distance",
    type: "switch",
    title: "Distance",
    order: 5,
  },
  {
    id: "type",
    type: "switch",
    title: "Race Type",
    order: 6,
  },
  {
    id: "state",
    type: "switch",
    title: "Country",
    order: 7,
  },
  {
    id: "jumpflat",
    type: "switch",
    title: "Jump/Flat",
    order: 8,
  },
];

export { settings_default, settings_layout };
