import React from "react";
import { useFilterStore } from "../../store";

import { extendTheme, ChakraProvider } from "@chakra-ui/react";

import { MultiSelect, MultiSelectProps, MultiSelectTheme } from "../chakra";
import { RsaMultiSelectProps, RsaSelectItem, RsaSelectProps } from "./types";

export const BasicMultiSelect = (
  props: RsaMultiSelectProps & MultiSelectProps
) => {
  const theme = extendTheme({
    components: {
      MultiSelect: {
        ...MultiSelectTheme,
        baseStyle: (props: Record<string, any>) => {
          const baseStyle = MultiSelectTheme.baseStyle(props);
          return {
            w: 60,
            py: 10,
            ...baseStyle,
          };
        },
      },
    },
  });

  return (
    <ChakraProvider theme={theme}>
      <MultiSelect
        value={props.Buttonvalue}
        options={props.options}
        {...props}
      />
    </ChakraProvider>
  );
};

// consider using a different name for the component
// https://www.npmjs.com/package/react-select
export const RsaMultiSelect = (props: RsaMultiSelectProps) => {
  const { key, storekey, storage, datatype, ...rest } = props;

  const itemformat = (item: RsaSelectItem, index: number) => ({
    label: item.label,
    value: item.id,
    index: `idx-${index + 1}`,
    key: `option-${storekey}-${item.id}-${index + 1}`,
  });

  const options = props.items.map((item, index) => itemformat(item, index));
  const dataStore = (storage || useFilterStore)((state: any) =>
    state.get(storekey)
  );
  const store = (storage || useFilterStore)((state: any) => state.store);

  let selection = props.items
    .filter((item) =>
      (dataStore || []).map((item: any) => `${item}`).includes(item.id)
    )
    .map((item, index) => itemformat(item, index));

  return (
    <BasicMultiSelect
      options={options}
      value={selection}
      onChange={(event: any) => {
        store(
          storekey,
          datatype,
          event.map((item: { value: any }) => item.value)
        );
      }}
      datatype={datatype}
      storekey={storekey}
      {...rest}
    />
  );
};
