import React from "react";
import { CheckIcon, CloseIcon, EditIcon } from "@chakra-ui/icons";
import {
  EditableInput,
  EditablePreview,
  ButtonGroup,
  IconButton,
  Input,
  useEditableControls,
} from "@chakra-ui/react";
import { useFilterStore } from "../../store";
import { FilterValueEnum } from "../../types";
import { Styled } from "./styles";

export function RsaEditable(props: {
  fontSize?: string;
  storekey: string;
  storage?: any;
}) {
  function EditableControls() {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <ButtonGroup justifyContent="center" size="sm">
        <IconButton
          aria-label="Submit"
          icon={<CheckIcon />}
          {...getSubmitButtonProps()}
        />
        <IconButton
          aria-label="Cancel"
          icon={<CloseIcon />}
          {...getCancelButtonProps()}
        />
      </ButtonGroup>
    ) : (
      <IconButton
        aria-label="Edit"
        size="sm"
        icon={<EditIcon />}
        {...getEditButtonProps()}
      />
    );
  }

  const { fontSize, storekey, storage, ...rest } = props;

  const dataStore = (storage || useFilterStore)((state: any) =>
    state.get(storekey)
  );
  const store = (storage || useFilterStore)((state: any) => state.store);

  return (
    <Styled.EditableWrapper
      defaultValue={dataStore}
      fontSize={fontSize}
      isPreviewFocusable={false}
      onChange={(event) => {
        store(storekey, FilterValueEnum.string, event);
      }}
    >
      <EditablePreview />
      <Input as={EditableInput} />
      <EditableControls />
    </Styled.EditableWrapper>
  );
}
