import React from "react";
import { Box } from "@chakra-ui/react";
import { SystemsSegmentedControl } from "./segments";
import { SystemsDialog } from "./dialog";

export const Systems = (props: any) => {
  const { onClose, onEdit, isOpen } = props;

  return (
    <Box>
      <SystemsSegmentedControl onEdit={onEdit} />
      <SystemsDialog isOpen={isOpen} onClose={onClose} key="systems" />
    </Box>
  );
};
