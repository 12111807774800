import React from "react";
import { Box, HStack, useRadio, useRadioGroup } from "@chakra-ui/react";

export function RadioCard(props: any) {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        _checked={{
          bg: "teal.600",
          color: "white",
          borderColor: "teal.600",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={5}
        py={3}
        style={{
          height: "30px",
          padding: "5px 10px",
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
}

export type RadioCardItem = {
  id: string;
  label: string;
  subtitle: string;
};

export function RadioCardGroup(props: {
  options: RadioCardItem[];
  selected: RadioCardItem;
  width?: number;
  onChange: (selection: RadioCardItem) => void;
}) {
  const options = props.options || [];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "framework",
    defaultValue: props.selected.id,
    onChange: (item) => {
      props.onChange(options.find((o) => o.id === item) || options[0]);
    },
  });

  const group = getRootProps();

  return (
    <HStack {...group} width={props.width}>
      {options.map((value: RadioCardItem) => {
        const radio = getRadioProps({ value: value.id });
        return (
          <RadioCard key={value.id} {...radio}>
            <div style={{ whiteSpace: "nowrap" }}>
              {value.label}
              {value.subtitle && (
                <span style={{ padding: "0px 5px" }}>({value.subtitle})</span>
              )}
            </div>
          </RadioCard>
        );
      })}
    </HStack>
  );
}
