import { Button } from "@chakra-ui/react";
import styled from "styled-components";

type FlexProps = {
  $bg?: string;
  $w?: number;
  $h?: number;
  $p?: number;
  $color?: string;
  $border?: string;
};

const Stack = styled.div<
  FlexProps & {
    $dir: "row" | "column";
    $gap?: number;
    $spacing?: number;
    $justify?: string;
  }
>`
  display: flex;
  ${(props) => props.$gap && `gap: ${props.$gap}px;`}
  ${(props) => props.$spacing && `padding: ${props.$spacing}px;`}
  flex-direction: ${(props) => props.$dir};
  ${(props) => props.$justify && `justify-content: ${props.$justify};`}
  ${(props) => props.$border && `border: ${props.$border};`}
  ${(props) => props.$w && `width: ${props.$w}px;`}
  ${(props) => props.$h && `height: ${props.$h}px;`}
`;

const Box = styled.div<FlexProps>`
  ${(props) => props.$bg && `background-color: ${props.$bg};`}
  ${(props) => props.$w && `width: ${props.$w}px;`}
  ${(props) => props.$h && `height: ${props.$h}px;`}
  ${(props) => props.$p && `padding: ${props.$p}px;`}
  ${(props) => props.$color && `color: ${props.$color};`}
  ${(props) => props.$border && `border: ${props.$border};`}
`;

export const ResponsiveButton = styled(Button)`
  width: 150px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const GStyled = {
  Stack,
  Box,
  ResponsiveButton,
};
