import React, { useMemo, useState } from "react";
import { GStyled } from "../../styles/global";
import { useDaySheetQuery } from "../../services";
import { DateUtils, WeekDate } from "../../utils/dateutils";
import {
  RadioCardGroup,
  RadioCardItem,
  SingleDatepicker,
} from "../../components/chakra";
import {
  DailyTable,
  DaySummaryTable,
  FavSheetTable,
  FavTable,
} from "../../components/tables";
import { Checkbox, CheckboxGroup } from "@chakra-ui/react";
import {
  dsDaysMap,
  prepareDSData,
  getToDateWeek,
} from "../../components/tables/custom/daysheet";
import { useWindow } from "../../hook/use-window";

import styled from "styled-components";

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px; /* Adjust the margin as needed */
  gap: 24px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  flex: 1;
  box-sizing: border-box;
  padding: 1rem;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  align-items: stretch;
`;

export const Top = styled.div`
  display: flex;
  gap: 24px;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const DaySheet = () => {
  const [date, setDate] = useState<WeekDate>(DateUtils.getRsaDate(new Date()));
  const [selectedDay, setSelectedDay] = useState<RadioCardItem>();
  const [pickedWeekDays, setPickedWeekDays] = useState<RadioCardItem[]>([]);

  const { data, isLoading } = useDaySheetQuery(date);

  window.addEventListener("message", (event) => {
    if (event.origin !== window.location.origin) {
      return;
    }
    if (event.data?.type === "WeekDate") {
      setDate(event.data?.data);
    }
  });

  const getRadioCardItem = (dayId: string): RadioCardItem => {
    return {
      id: dayId,
      label: dsDaysMap[dayId],
      subtitle: "",
    };
  };

  const { dayItems, weekChoices, allFavesData } = useMemo(() => {
    let dayKeys = Object.keys(data?.dailyData || {}).filter(
      (key) => dsDaysMap[key]
    );
    let dayItems0 = dayKeys.map((key) => getRadioCardItem(key));

    let dayItems1 = [
      {
        id: "Week",
        label: "Week",
        subtitle: "",
      },
      {
        id: "wk2date",
        label: "Wk to Date",
        subtitle: "",
      },
    ];

    let dayItems = dayItems0.length > 0 ? [...dayItems1, ...dayItems0] : [];
    let selectedDay = dayItems0[dayItems0.length - 1];
    if (dayItems0.length > 0) {
      setSelectedDay(selectedDay);
    }

    setPickedWeekDays(dayItems0);

    const allFavesData =
      data &&
      selectedDay &&
      prepareDSData(date.year, data?.dailyData, data?.favesData);

    return { dayItems, weekChoices: dayItems0, allFavesData };
  }, [data]);

  const { dailyData, favesData, summaryData, weeksData, additionalColumns } =
    useMemo(() => {
      if (!data || !selectedDay) {
        return { dailyData: [], favesData: [] };
      }

      const selectedDayId = selectedDay.id || "NA";
      const dailyData =
        (data &&
          selectedDay &&
          data.dailyData &&
          data.dailyData[selectedDayId]) ||
        [];

      const emptyData = { faves: [], shadow: null, summary: null };
      const {
        shadow: weeksData,
        faves: favesData,
        summary: summaryData,
      } = allFavesData
        ? !/\bwk2date\b/gi.test(selectedDayId)
          ? //  {
            //     faves: allFavesData[selectedDayId] || [],
            //     shadow: allFavesData[`${selectedDayId}_Shadow`],
            //   }
            allFavesData[selectedDayId] || emptyData
          : getToDateWeek(
              date.year,
              pickedWeekDays.map((pwd) => pwd.id),
              allFavesData
            )
        : emptyData;

      const maxYear = new Date().getUTCFullYear();
      const firstTwoDigits = maxYear.toString().match(/^(\d{2})(\d{2})/)?.[2];

      const additionalColumns = /\bwk2date\b|\bweek\b/gi.test(selectedDayId)
        ? [{ key: "diff", name: "Diff" }]
        : [{ key: "qsMaxYearqs", name: `?Y${firstTwoDigits}?` }];

      return {
        dailyData: weeksData ? null : dailyData,
        favesData,
        summaryData,
        weeksData,
        additionalColumns,
      };
    }, [data?.dailyData, allFavesData, selectedDay, pickedWeekDays]);

  const selectDate = (date: any) => {
    setDate(DateUtils.getRsaDate(date));
  };

  const selectDay = (day: RadioCardItem) => {
    setSelectedDay(day);
  };
  const width = useWindow().innerWidth;

  return (
    <PageWrapper>
      <Row>
        <Top>
          <SingleDatepicker
            name="date-input"
            date={date.date}
            onDateChange={selectDate}
          />
          {selectedDay && (
            <RadioCardGroup
              options={dayItems}
              onChange={selectDay}
              selected={selectedDay}
            />
          )}
          {selectedDay?.id === "wk2date" && (
            <CheckboxGroup
              colorScheme="green"
              defaultValue={pickedWeekDays.map((pwd) => pwd.id)}
              onChange={(values) =>
                setPickedWeekDays(
                  values.map((value) => getRadioCardItem(`${value}`))
                )
              }
            >
              <GStyled.Stack $dir="row">
                {weekChoices.map((wc) => (
                  <Checkbox value={wc.id}>{wc.label}</Checkbox>
                ))}
              </GStyled.Stack>
            </CheckboxGroup>
          )}
        </Top>
      </Row>
      <Row>
        {weeksData && (
          <Column
            style={{
              width: width > 768 ? "650px" : "100%",
              overflow: "auto", // Prevent scrolling inside the grid
            }}
          >
            <FavTable
              data={weeksData}
              title="Week"
              simple={true}
              loading={isLoading}
            />
          </Column>
        )}

        {favesData && (
          <Column
            style={{
              width: width > 768 ? "650px" : "100%",
              overflow: "auto", // Prevent scrolling inside the grid
            }}
          >
            <FavSheetTable
              data={favesData}
              title="Fav"
              loading={isLoading}
              options={{ additionalColumns }}
            />

            {summaryData && <DaySummaryTable data={summaryData} />}
          </Column>
        )}

        {dailyData && (
          <Column
            style={{
              width: width > 768 ? "650px" : "100%",
              overflow: "auto", // Prevent scrolling inside the grid
            }}
          >
            <DailyTable data={dailyData} title="Daily" loading={isLoading} />
          </Column>
        )}
      </Row>
      <Row></Row>
    </PageWrapper>
  );
};
