import { create } from "zustand";
import { persist } from "zustand/middleware";

const _decode = function (cipher: string) {
  return JSON.parse(window.atob(cipher));
};

const _endcode = function (token: any) {
  return window.btoa(JSON.stringify(token));
};

const _expired = function (state: any, offset: number = 0) {
  const getDate = (dateText: string) => {
    let date = new Date(dateText);
    if (offset !== 0) {
      date.setSeconds(date.getSeconds() + offset);
    }
    return date;
  };

  return state && state.expiresAt && getDate(state.expiresAt) > new Date()
    ? false
    : true;
};

export const useAccessTokenStore = create(
  persist(
    (set, get) => ({
      accessToken: () => {
        let state: any = get();
        let token = !_expired(state) && state.data ? _decode(state.data) : {};
        return token.accessToken;
      },
      refreshToken: () => {
        let state: any = get();
        let token = state.data ? _decode(state.data) : {};
        return token.refreshToken;
      },
      user: () => {
        let state: any = get();
        let token = !_expired(state) && state.data ? _decode(state.data) : {};
        return token.user;
      },
      needsRefresh: () => {
        let state: any = get();
        let needs = false;
        if (state && state.data && state.expiresAt && _expired(state, -120)) {
          needs = (_decode(state.data) || {}).refreshToken ? true : false;
        }
        return needs;
      },
      loggedIn: () => {
        return !_expired(get());
      },
      store: (jwt: any) =>
        set(() => {
          let expiresAt = new Date();
          expiresAt.setSeconds(expiresAt.getSeconds() + (jwt.expiresIn - 60)); //giving 60 second before expiry
          return { data: _endcode(jwt), expiresAt };
        }),
      logout: () =>
        set(
          { loggedIn: () => false, user: () => {}, accessToken: () => "" },
          true
        ),
    }),
    { name: "_srs" }
  )
);

export const useBfAccessTokenStore = create(
  persist(
    (set, get) => ({
      accessToken: () => {
        let state: any = get();
        let token = !_expired(state) && state.data ? _decode(state.data) : {};
        return token.access_token;
      },
      refreshToken: () => {
        let state: any = get();
        let token = state.data ? _decode(state.data) : {};
        return token.refresh_token;
      },
      needsRefresh: () => {
        let state: any = get();
        let needs = false;
        if (state && state.data && state.expiresAt && _expired(state)) {
          needs = (_decode(state.data) || {}).refresh_token ? true : false;
        }
        return needs;
      },
      loggedIn: () => {
        return !_expired(get());
      },
      store: (value: any) =>
        set(() => {
          let expiresAt = new Date();
          expiresAt.setSeconds(
            expiresAt.getSeconds() + (value.expires_in - 60)
          ); //giving 60 second before expiry
          return { data: _endcode(value), expiresAt };
        }),
      logout: () =>
        set(
          { loggedIn: () => false, user: () => {}, accessToken: () => "" },
          true
        ),
    }),
    { name: "_bfk" }
  )
);
