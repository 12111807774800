import { create } from "zustand";

export const useMetadataStore = create((set, get) => ({
  tracksMap: {},
  getTrack: (id: string) => {
    return (get() as any)?.tracksMap[id];
  },
  store: (lookupData: any) =>
    set((state: any) => {
      let mapping: any = {};

      lookupData?.track?.forEach((element: any) => {
        mapping[element.label] = element.id;
        mapping[element.id] = element.id;
      });

      return { ...state, tracksMap: mapping };
    }),
  clear: () => set({}, true),
}));
