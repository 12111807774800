import React, { useEffect } from "react";
import { Styled } from "./styles";
import { NumericFormat } from "react-number-format";

interface DecimalInputProps {
  value: string | number;
  onChange: (value: string) => void;
  placeholder?: string;
  maxLength?: number;
  //defaultValue?: string;
  prefix?: string;
  readOnly?: boolean;
}

export const DecimalInput: React.FC<DecimalInputProps> = ({
  value,
  onChange,
  placeholder = "",
  maxLength,
  //defaultValue = "",
  prefix,
  readOnly = false,
}) => {
  // const [inputValue, setInputValue] = React.useState(value);
  // useEffect(() => {
  //   // Initialize value with defaultValue if it's provided
  //   if (defaultValue && value === "") {
  //     onChange(defaultValue);
  //   }
  //   setInputValue(value);
  // }, [defaultValue, value, onChange]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    //onChange(inputValue);
    // // Allow empty string or valid decimal numbers (e.g., "2", "2.9", "0.9")
    // if (inputValue === "" || /^[0-9]*\.?[0-9]*$/.test(inputValue)) {
    //setInputValue(inputValue);
    const valueWithoutPrefix = prefix ? inputValue.replace(prefix, "") : inputValue;
    onChange(valueWithoutPrefix);
    // }
    //console.log("value", e.target.value, valueWithoutPrefix, Number(valueWithoutPrefix));
  };

  const handleBlur = () => {
    // Remove trailing dots (e.g., "2." -> "2")
    // if (value?.endsWith(".")) {
    //   onChange(value.slice(0, -1));
    // }
  };

  return (
    // <Styled.DecimalInputWrapper
    //   value={inputValue}
    //   onChange={handleInputChange}
    //   onBlur={handleBlur}
    //   placeholder={placeholder}
    //   maxLength={maxLength}
    //   readOnly={readOnly}
    // />
    <NumericFormat
      value={value}
      onChange={handleInputChange}
      onBlur={handleBlur}
      placeholder={placeholder}
      maxLength={maxLength}
      readOnly={readOnly}
      prefix={prefix}
    />
  );
};
