import React from "react";
import ukFlag from "../img/flags/uk.png";
import auFlag from "../img/flags/au.png";
const Switch = ({ isChecked, toggleChecked, handleChange }: any) => {
  return (
    <div className="switch">
      <label className="flags">
        <a href="https://vexter.co.uk/" target="_blank" rel="noreferrer">
          <img src={ukFlag} alt="" />
        </a>{" "}
        UK & IRE
        {/* Change to {this.props.title} and you can set the label text in a higher level component */}
      </label>
      <span>
        <input type="checkbox" checked={isChecked} onChange={handleChange} />
        <button
          className="slider"
          type="button"
          onClick={toggleChecked}
        ></button>
      </span>
      <label className="flags">
        AU{" "}
        <a href="https://vexter.com.au/" target="_blank" rel="noreferrer">
          <img src={auFlag} alt="" />
        </a>
        {/* Change to {this.props.title} and you can set the label text in a higher level component */}
      </label>
    </div>
  );
};

export default Switch;
