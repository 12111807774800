const RandomNumber = () => Math.floor(Math.random() * 1000) + 1;

const UniqueNumber = () => `${RandomNumber()}${Date.now()}${RandomNumber()}`;

const UniqueNumberId = () =>
  `${RandomNumber()}-${Date.now()}-${RandomNumber()}`;

// Generate Number OTP
const UniqueOTP = (length = 6) =>
  Math.floor(
    Math.random() * (Math.pow(10, length) - 1 - Math.pow(10, length - 1) + 1)
  ) + Math.pow(10, length - 1);

// Generate Character OTP
const UniqueCharOTP = (length = 6) => {
  let otp = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  for (let i = 0; i < length; i++) {
    const index = Math.floor(Math.random() * characters.length);
    otp += characters[index];
  }
  return otp;
};

// Generate Color HEX Code
const HEXColor = (isWithoutSymbole = false) => {
  return isWithoutSymbole
    ? Math.floor(Math.random() * 16777215).toString(16)
    : "#" + Math.floor(Math.random() * 16777215).toString(16);
};

export const Uuid = {
  UniqueNumber,
  UniqueNumberId,
  UniqueOTP,
  UniqueCharOTP,
  HEXColor,
};
