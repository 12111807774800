export enum ValueDirEnum {
  Up = "up",
  Down = "down",
}

export enum BetSidesEnum {
  Back = "BACK",
  Lay = "LAY",
  None = "NONE",
}

export enum SPTypesEnum {
  Near = "near",
  Far = "far",
  BSP = "bsp",
  Taken = "taken",
}

export enum OrderType {
  MARKET_ON_CLOSE = "MARKET_ON_CLOSE",
  LIMIT = "LIMIT",
}

export enum PriceType {
  Auto = "auto",
  Fixed = "fixed",
  Percentage = "bsp_pcntg",
}

export type RsaBet = {
  id?: string;
  faveNo: number;
  selectionId?: string;
  side: BetSidesEnum;
  price: number;
  bsp: number;
  size: number;
  orderType: OrderType;
  priceType?: PriceType;
  userBet: boolean;
};
