import { useEffect, useState } from "react";
import { useFilterStore } from "./filter-store";
import {
  useCurrentSystemStore,
  useSystemsStore,
} from "../systems-store/systems-store";
import {
  RsaQueryAggsEnum,
  RsaQueryRequest,
  RaceDataType,
  BetSidesEnum,
  QuickFinderDataType,
} from "../../types";

import { get_digest } from "../../utils/digest";
import { PriceUtils } from "../../utils/prices";
import { extractFiltersfromSystem } from "../../services/search/utils";
import { useShallow } from "zustand/react/shallow";
import { useMetadataStore } from "../meta-store";

export const useGetFinderFilters = (aggs: RsaQueryAggsEnum[]) => {
  const filters = useFilterStore((state: any) => state.filters || {});
  let params = {
    system: "default",
    aggs,
    ...filters,
  };

  return {
    ...params,
    digest: get_digest(params),
  };
};

export const useGetRacingFilters = (
  raceData: RaceDataType,
  bsps: number[],
  aggs: RsaQueryAggsEnum[]
) => {
  const filters = useFilterStore((state: any) => state.filters || {});
  const systems = useSystemsStore((state: any) => state.filters);
  const currentSystemKey = useCurrentSystemStore((state: any) => state.sys);
  const [query, setQuery] = useState<RsaQueryRequest | null>(null);

  useEffect(() => {
    let bspQuery =
      bsps && bsps.length > 0
        ? { bsp_index: PriceUtils.round(bsps[0], BetSidesEnum.Back) }
        : {};
    if (raceData) {
      let sysKeys = Object.keys(systems);

      const queries = sysKeys.map((sys) => {
        let filterData =
          sys === currentSystemKey
            ? filters
            : extractFiltersfromSystem(raceData, sys, systems[sys]);
        if (filterData.ref !== raceData.id || filterData.system !== sys) {
          return undefined;
        }

        let params = {
          aggs,
          ...bspQuery,
          ...(raceData.training ? { history: raceData.ds } : {}),
          ...filterData,
        };
        return {
          ...params,
          digest: get_digest(params),
        };
      });

      const allDigets = queries.map((r) => r?.digest).join(";");
      const available = queries.every((r) => r !== undefined);

      if (available) {
        setQuery({
          queries,
          digest: allDigets,
        });
      }
    }
  }, [filters, systems, raceData, bsps]);

  return query;
};

export const useGetQFQuery = (raceData: RaceDataType) => {
  const filters = useFilterStore(useShallow((state: any) => state.filters));
  const metadata = useMetadataStore((state: any) => state.getTrack);
  const [query, setQuery] = useState<QuickFinderDataType | null>(null);
  const systems = useSystemsStore((state: any) => state.filters);
  const currentSystemKey = useCurrentSystemStore((state: any) => state.sys);

  const merge = (data: string, filters: string[]): string[] => {
    return (filters || []).includes(data)
      ? filters
      : [data, ...(filters || [])];
  };

  const getSwitches = () => {
    const currentSystem = systems[currentSystemKey];
    return {
      track: currentSystem.track,
      distance: currentSystem.distance,
      type: currentSystem.type,
      country: currentSystem.state,
      jumpflat: currentSystem.jumpflat,
    };
  };

  useEffect(() => {
    if (raceData) {
      let queryData = {
        id: raceData.id,
        time: raceData.L_time,
        week: filters.week,
        dow: filters.dow,
        track: merge(metadata(raceData.G_track), filters.track),
        distance: merge(raceData.D_dis, filters.distance),
        type: merge(raceData.E_type, filters.type),
        country: raceData.H_country,
        ran: raceData.A_ran,
        jumpflat: raceData.jumpflat,
        history: raceData.training ? raceData.ds : undefined,
        switches: getSwitches(),
      };

      setQuery({
        ...queryData,
        digest: get_digest(queryData),
      } as QuickFinderDataType);
    }
  }, [raceData, JSON.stringify(filters)]);

  return query;
};
