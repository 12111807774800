import React, { useEffect } from "react";
import {
  useFilterStore,
  useSystemsStore,
  useCurrentSystemStore,
} from "../../store";
import { extractFiltersfromSystem } from "../../services/search/utils";

export const SyncFilters = (props: any) => {
  const currentSystemKey = useCurrentSystemStore((state: any) => state.sys);
  const systems = useSystemsStore((state: any) => state.filters);
  const resetFilters = useFilterStore((state: any) => state.reset);

  useEffect(() => {
    const currentSystem = systems[currentSystemKey];

    let filters = extractFiltersfromSystem(
      props.raceData,
      currentSystemKey,
      currentSystem
    );
    resetFilters(filters);
  }, [props.raceData.id, currentSystemKey, systems]);

  return <></>;
};
