import React, { useEffect, useMemo, useState } from "react";
import { RsaTableColumn, RsaTableProps } from "../types";
import { Utils } from "../utils";
import { BaseTable } from "../base";
import { textEditor } from "react-data-grid";
import { SbbUtils } from "./utils";
import { useWindow } from "../../../hook/use-window";
import "./sbb.css";

export const SBBTable = (
  props: RsaTableProps & { rows: any[]; filter: number }
) => {
  const { data, rows, filter, ...rest } = props;

  const columns: RsaTableColumn[] = [
    {
      key: "percentage",
      name: "",
      width: 80,
      renderCell({ row }: { row: any }) {
        return (
          <>
            {filter > 0 && row.C_spf
              ? `${Utils.toFixed(((row.C_spf - filter) / filter) * 100)}%`
              : ""}
          </>
        );
      },
    },
    {
      key: "C_spf",
      name: "SPF",
      cellClass: "sbbBSPFilter",
      renderEditCell: textEditor,
      renderHeaderCell(props) {
        return (
          <div>
            SP of
            <br />
            Fav
          </div>
        );
      },
    },
    {
      key: "D_spw",
      name: "SPW",
      renderHeaderCell(props) {
        return (
          <div>
            SP of
            <br />
            Winner
          </div>
        );
      },
    },
    {
      key: "IPHigh",
      name: "IPHigh",
      renderHeaderCell(props) {
        return (
          <div>
            IP £<br />
            High
          </div>
        );
      },
      renderCell({ row }: { row: any }) {
        return <>{row.J_highm ? Utils.toFixed(row.J_highm) : ""}</>;
      },
    },
    {
      key: "E_ws",
      name: "WSA",
      renderHeaderCell(props) {
        return (
          <div>
            Winner
            <br />
            Started As
          </div>
        );
      },
    },
    {
      key: "F_type",
      name: "Type",
    },
    {
      key: "G_dis",
      name: "Dis",
    },
    {
      key: "H_ran",
      name: "Ran",
    },
    {
      key: "maxsp",
      name: "MaxSP",
      renderHeaderCell(props) {
        return (
          <div>
            Max SP
            <br />
            Winner
            <br />
            Paid
          </div>
        );
      },
    },
  ];

  const rowHeight = 39; // The height of each row (same as in the CSS)
  const numRows = rows.length;
  const gridHeight = rowHeight * numRows;
  const width = useWindow().innerWidth;

  return (
    <>
      <div className="sbb-table">
        <BaseTable
          rows={rows}
          columns={columns}
          style={{
            height: gridHeight, // Dynamically set the height based on rows
            width: width > 768 ? "650px" : "100%",
            overflow: "auto", // Prevent scrolling inside the grid
          }}
          rowClass={(row, index: number) => {
            if (filter && index === 10) {
              return "sbbHighlightedRowClass";
            }
            return "sbbRowClass";
          }}
          onRowsChange={props.onRowsChange}
          size={props?.options?.size}
          {...rest}
        />
      </div>
    </>
  );
};
