import React, { useState } from "react";
import { useGetProfile, useUserRefreshToken } from "../../services";
import { useAccessTokenStore } from "../../store";
import { BetfairLoginErrorCodeEnum } from "../../services";

import {
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuDivider,
  Center,
  Spinner,
  Box,
} from "@chakra-ui/react";

import { Link } from "react-router-dom";
import { routes } from "../../routes/consts";
import { BetfairStatus } from "./betfair";

export const Profile = () => {
  const { data, isLoading } = useGetProfile();
  const logout = useAccessTokenStore((state: any) => state.logout);

  const onLogout = () => {
    logout();
    window.location.reload();
  };

  // const userRefreshToken = useUserRefreshToken({
  //   onError: (err: any) => {
  //     if (err.message !== BetfairLoginErrorCodeEnum.NoRefreshToken) {
  //       console.error("ERR:", err);
  //       setLogingInProgress(false);
  //       logout();
  //     }
  //   },
  //   onNext: () => {
  //     setLogingInProgress(false);
  //   },
  // });

  // useEffect(() => {
  //   const checkSessionAndFetchData = async () => {
  //     console.log("check for user token refresh");
  //     if (needsRefresh && !logingInProgress) {
  //       // Refresh the token
  //       setLogingInProgress(true);
  //       userRefreshToken.mutate();
  //       console.log("User token refreshed!");
  //     }
  //   };
  //   // Set up the interval to check the session and fetch data every second
  //   const intervalId = setInterval(checkSessionAndFetchData, 5000);
  //   // Clean up the interval on component unmount
  //   return () => clearInterval(intervalId);
  // }, [needsRefresh, userRefreshToken, logingInProgress]);

  return (
    <>
      <Menu>
        <MenuButton
          as={Button}
          rounded={"full"}
          variant={"link"}
          cursor={"pointer"}
          minW={0}
        >
          <Avatar
            size={"sm"}
            src={"https://avatars.dicebear.com/api/male/username.svg"}
          />
        </MenuButton>
        <MenuList alignItems={"center"}>
          <br />
          <Center>
            <Avatar
              size={"2xl"}
              src={"https://avatars.dicebear.com/api/male/username.svg"}
            />
          </Center>
          <br />
          {isLoading ? (
            <Spinner />
          ) : (
            <Center>
              <Link
                to={routes.PROFILE}
              >{`${data.user.name} ${data.user.family}`}</Link>
            </Center>
          )}
          <BetfairStatus />

          <MenuDivider />
          <Box padding={"10px"}>
            {" "}
            <Button
              colorScheme="teal"
              type="submit"
              style={{ width: "100%" }}
              onClick={onLogout}
            >
              Logout
            </Button>
          </Box>
        </MenuList>
      </Menu>
    </>
  );
};
