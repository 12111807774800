import React, { useMemo } from "react";
import { RsaTableProps } from "../types";
import { MultiValueTable } from "../multi-value";
import { PriceUtils } from "../../../utils/prices";
import { BetSidesEnum } from "../../../types";

export const ComparedSPTable = (
  props: RsaTableProps & { bsps?: number[]; simple?: boolean }
) => {
  const { options = {}, data: rawdata, bsps = [], ...rest } = props;

  const vh_formatter = (value: any, row: any) => {
    let text = Number(value / 100).toFixed(2);
    switch (row.faveIdx || 0) {
      case 1:
        return <div className="fav1CellClass">{text}</div>;
      case 2:
        return <div className="fav2CellClass">{text}</div>;
      default:
        return <div className="favOtherCellClass">{text}</div>;
    }
  };

  const compareColumns = (current_bsps: number[], current_data: any): any => {
    if (Object.keys(current_data || {}).length === 0) return current_data;

    let data_updated = { ...current_data };

    let bsp_maps: { [key: string]: { faveNo: number[]; faveIdx: number } } = {};
    for (let idx = 0; idx < current_bsps.length; idx++) {
      let bsp_favex =
        PriceUtils.round_to_range(current_bsps[idx] || 0, BetSidesEnum.Back) *
        100;
      if (!bsp_maps[`${bsp_favex}`]) {
        bsp_maps[`${bsp_favex}`] = { faveNo: [], faveIdx: idx + 1 };
      }
      bsp_maps[`${bsp_favex}`].faveNo.push(idx + 1);
    }

    Object.keys(bsp_maps).forEach((bsp) => {
      if (data_updated[bsp]) {
        data_updated[bsp] = {
          ...data_updated[bsp],
          faves: bsp_maps[bsp].faveNo.join(","),
          faveIdx: bsp_maps[bsp].faveIdx,
          favecompare: data_updated[bsp].compare,
        };
      }
    });

    return data_updated;
  };

  const data: any = useMemo(() => {
    return compareColumns(bsps, rawdata);
  }, [bsps, rawdata]);

  const renderCell = function (colname: string, { row }: { row: any }) {
    return <>{row[colname]}</>;
  };

  return (
    <>
      <MultiValueTable
        data={data}
        keyField="SP"
        title="SP"
        style={{
          height: 900, // Dynamically set the height based on rows
          overflow: "auto", // Prevent scrolling inside the grid
        }}
        options={{
          vh_formatter: vh_formatter,
          columnWidth: { width: 30, minWidth: 30, maxWidth: 50 },
          additionalColumns: [
            { key: "faves", name: "FAV", renderCell },
            { key: "all", name: "All" },
            { key: "favecompare", name: "CMP", renderCell },
          ],
          size: "sm",
        }}
        {...rest}
      />
    </>
  );
};
