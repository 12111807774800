import React, { useMemo } from "react";
import { Styled } from "./styles";
import { BetSidesEnum, RsaBet, PriceType } from "../../../types";
import { useBetMakerStakesStore } from "../../../store";
import { CurrencyValueControl, ValueControl } from "./value-control";
import { MultiStateButton, EmptyMultiStateButton } from "../../button";
import { BiBarChart, BiLock } from "react-icons/bi";

const Betting_Options = {
  [PriceType.Auto]: {
    display: false,
    text: "Auto",
  },
  [PriceType.Fixed]: {
    color: "white",
    background: "#007bff",
    text: "Fixed",
    icon: <BiLock />,
  },
  [PriceType.Percentage]: {
    color: "white",
    background: "green",
    text: "BSP %",
    icon: <BiBarChart />,
  },
};

export enum PriceTypeDisplay {
  Show,
  Hide,
  Invisible,
}

export const StakeMaker = (props: {
  label?: string;
  defaultValue: RsaBet;
  value: RsaBet;
  active?: boolean;
  defaultIncr: number;
  startSize?: boolean;
  showPrice?: boolean;
  showPriceType?: PriceTypeDisplay;
  onChanges: (bet: RsaBet) => void;
}) => {
  const {
    label,
    active = false,
    showPrice = false,
    defaultIncr,
    startSize = false,
    showPriceType = PriceTypeDisplay.Hide,
    onChanges,
  } = props;

  const incStore = useBetMakerStakesStore((state: any) => state);

  const incrSize = (side: BetSidesEnum): number => {
    return Number(incStore[side] || defaultIncr);
  };

  const initSize = (b: RsaBet) =>
    b.size === 0 && startSize ? incrSize(b.side) : b.size;

  const bet = useMemo(() => {
    if (!props.value) return props.defaultValue;
    return { ...props.value, size: initSize(props.value) };
  }, [props.value]);

  const toggleType = () => {
    let newSide =
      bet.side === BetSidesEnum.Back ? BetSidesEnum.Lay : BetSidesEnum.Back;
    let newSize = startSize ? initSize(props.value || props.defaultValue) : 0;
    onChanges({ ...bet, side: newSide, size: newSize });
  };

  return (
    <>
      <Styled.StakeMaker $active={active} $side={bet.side}>
        {label && (
          <Styled.StakeLabel $active={active}>
            <span>{label}</span>
          </Styled.StakeLabel>
        )}
        {showPriceType === PriceTypeDisplay.Show ? (
          <MultiStateButton
            options={Betting_Options}
            currentState={bet.priceType || PriceType.Fixed}
            loop={true}
            onChange={(priceType: PriceType) =>
              onChanges({ ...bet, price: 1, priceType })
            }
          />
        ) : showPriceType === PriceTypeDisplay.Invisible ? (
          <EmptyMultiStateButton />
        ) : (
          <></>
        )}
        {showPrice && (
          <>
            {bet.priceType === PriceType.Percentage ? (
              <CurrencyValueControl
                key={`percentage - ${bet.faveNo} - ${bet.selectionId}`}
                defaultValue={bet.price}
                percentage={true}
                side={bet.side}
                precision={0}
                min={0}
                step={1}
                onChanges={function (value: number): void {
                  onChanges({ ...bet, price: value });
                }}
              />
            ) : (
              <ValueControl
                key={`price - ${bet.faveNo} - ${bet.selectionId}`}
                defaultValue={bet.price}
                side={bet.side}
                active={active}
                enabled={!bet.userBet}
                onChanges={(value) => onChanges({ ...bet, price: value })}
              />
            )}
          </>
        )}

        <CurrencyValueControl
          key={`stake - ${bet.faveNo} - ${bet.selectionId}`}
          defaultValue={bet.size}
          percentage={false}
          side={bet.side}
          active={active}
          step={incrSize(bet.side)}
          min={0}
          onChanges={function (value: number): void {
            onChanges({ ...bet, size: value });
          }}
        />
        <Styled.StakeToggle
          $side={bet.side}
          $active={active}
          onClick={toggleType}
        >
          {`${bet.side}`.toUpperCase()}
        </Styled.StakeToggle>
      </Styled.StakeMaker>
    </>
  );
};
