import React, { useEffect, useState } from "react";
import { FavTable, ComparedSPTable } from "../../components/tables";
import {
  QuickFinderDataType,
  RsaQueryAggsEnum,
  RsaQueryResponse,
} from "../../types";
import { useSearchQuery } from "../../services";
import { GStyled } from "../../styles/global";
import { QFilter } from "../../components/qfinder/qfilter";
import { useGetQuickFilters } from "../../store";
import { Drawer } from "../drawer/drawer";

export const QuickFinderComponent = (props: {
  filterData?: QuickFinderDataType;
  bsps?: number[];
  loading?: boolean;
}) => {
  const [open, setOpen] = useState(false);

  const { filterData, bsps, loading = false } = props;
  const [queryResponse, setQueryResponse] = useState<RsaQueryResponse | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);

  const onClickOpen = () => {
    setOpen(true);
  };

  const onClickClose = () => {
    setOpen(false);
  };

  const getSearch = useSearchQuery({
    onError: (err: any) => {
      console.log("ERR:", err);
    },
    onSuccess: (data: any) => {
      setQueryResponse(data);
      setIsLoading(false);
    },
  });

  const query = useGetQuickFilters(
    [RsaQueryAggsEnum.SP, RsaQueryAggsEnum.Fav],
    filterData
  );

  useEffect(() => {
    if (!query.empty) {
      setIsLoading(true);
      getSearch.mutate(query);
    }
  }, [query.digest]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return (
    <GStyled.Stack $dir="row" $w={1800} $h={2500}>
      <GStyled.Box>
        <Drawer
          isOpen={open}
          onClose={onClickClose}
          onOpen={onClickOpen}
          title="Filters"
          size="sm"
        >
          <QFilter filters={filterData} />
        </Drawer>
      </GStyled.Box>
      <GStyled.Stack $dir="row" $gap={5} style={{ paddingLeft: 45 }}>
        <ComparedSPTable
          data={queryResponse?.yrs?.SP}
          bsps={bsps}
          loading={isLoading}
        />
        <FavTable
          data={queryResponse?.yrs?.Fav}
          title="Fav"
          loading={isLoading}
          options={{
            columnWidth: { width: 30, minWidth: 30, maxWidth: 50 },
            size: "sm",
          }}
        />
      </GStyled.Stack>
    </GStyled.Stack>
  );
};
