import React, { useMemo, useState, useEffect } from "react";
import { RsaTableProps } from "../types";
import "./sbb.css";
import { SBBTable } from "./sbb-table";
import { GStyled } from "../../../styles/global";
import {
  CompareBox,
  CompareBoxType,
  CompareBoxValueType,
} from "../../compare-box";
import { useCurrentSystemStore } from "../../../store";
import { SbbUtils } from "./utils";

export const SBB = (props: RsaTableProps & { filter: number }) => {
  const { data, ...rest } = props;
  const [filter, setFilter] = useState<number>(props.filter || 0);
  const currentSystemKey = useCurrentSystemStore((state: any) => state.sys);

  useEffect(() => {
    setFilter(props.filter);
  }, [props.filter]);

  const {
    actualData,
    rows,
    fave_count,
    fave12_count,
    other_count,
    total_count,
  } = useMemo(() => {
    let actualData =
      data instanceof Array
        ? data?.find((d) => d.system === currentSystemKey)
        : data;
    return {
      actualData,
      ...(actualData
        ? SbbUtils.getRows(actualData, filter)
        : {
            rows: [],
            fave_count: 0,
            fave12_count: 0,
            other_count: 0,
            total_count: 0,
          }),
    };
  }, [data, currentSystemKey, filter]);

  const favSummary = useMemo(() => {
    let favSummary = actualData?.yrs.FavSummary;
    return {
      fave: (favSummary?.Fav?.won || 0) * 100,
      second: (favSummary?.Fav2nd?.won || 0) * 100,
      outsiders: (favSummary?.Outsiders?.won || 0) * 100,
      total: favSummary?.Total?.won || 0,
    };
  }, [actualData]);

  const changeFilter = (rows: any[], key: any) => {
    if (key.indexes && key.indexes.length > 0) {
      let idx = key.indexes[0];
      if (rows && rows.length > idx) {
        let row = rows[idx];
        if (row.C_spf) {
          setFilter(parseFloat(row.C_spf || "0"));
        }
      }
    }
  };

  return (
    <>
      <GStyled.Stack $dir="column" $gap={5}>
        <GStyled.Stack $dir="row" $gap={8} $justify="center">
          <CompareBox
            type={CompareBoxType.Fave}
            title="Fave"
            value={favSummary.fave}
            compareValue={fave_count}
            valueType={CompareBoxValueType.percentage}
          />
          <CompareBox
            type={CompareBoxType.Second}
            title="1st & 2nd"
            value={favSummary.second}
            compareValue={fave12_count}
            valueType={CompareBoxValueType.percentage}
          />
          <CompareBox
            type={CompareBoxType.Outsiders}
            title="Out"
            value={favSummary.outsiders}
            compareValue={other_count}
            valueType={CompareBoxValueType.percentage}
          />
          <CompareBox
            type={CompareBoxType.Total}
            title="Match"
            value={favSummary.total}
            compareValue={total_count}
            valueType={CompareBoxValueType.number}
          />
        </GStyled.Stack>
        <SBBTable data={[]} rows={rows} onRowsChange={changeFilter} {...rest} />
      </GStyled.Stack>
    </>
  );
};
