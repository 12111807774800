import React, { useEffect } from "react";
import {
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
} from "@chakra-ui/react";
import { useFilterStore } from "../../store";
import { UseBoundStore } from "zustand/react";
import { StoreApi } from "zustand/vanilla";
import { FilterValueEnum } from "../../types";

export const RsaDoubleSlider = (props: {
  label: string;
  storekey: string;
  bound: number[];
  storage?: UseBoundStore<StoreApi<unknown>>;
}) => {
  const { label, bound, storekey, storage } = props;
  const storekeyFrom = `${storekey}.from`;
  const storekeyTo = `${storekey}.to`;

  const store = (storage || useFilterStore)((state: any) => state.store);
  const dataStoreFrom = (storage || useFilterStore)((state: any) =>
    state.get(storekeyFrom)
  );
  const dataStoreTo = (storage || useFilterStore)((state: any) =>
    state.get(storekeyTo)
  );

  const [values, setValues] = React.useState([dataStoreFrom, dataStoreTo]);

  const setFinalValues = (val: number[]) => {
    store(storekeyFrom, FilterValueEnum.int, val[0]);
    store(storekeyTo, FilterValueEnum.int, val[1]);
  };

  useEffect(() => {
    setValues([dataStoreFrom, dataStoreTo]);
  }, [dataStoreFrom, dataStoreTo, bound]);

  return (
    <>
      <strong>{label}</strong>
      <RangeSlider
        aria-label={["min", "max"]}
        value={values}
        min={bound[0]}
        max={bound[1]}
        onChange={(val) => setValues(val)}
        onChangeEnd={(val) => setFinalValues(val)}
      >
        <RangeSliderTrack bg="blue.100">
          <RangeSliderFilledTrack bg="blue" />
        </RangeSliderTrack>
        <RangeSliderThumb boxSize={6} index={0}>
          <span>{values[0]}</span>
        </RangeSliderThumb>
        <RangeSliderThumb boxSize={6} index={1}>
          <span>{values[1]}</span>
        </RangeSliderThumb>
      </RangeSlider>
    </>
  );
};
