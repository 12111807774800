import styled, { css } from "styled-components";
import { BetSidesEnum, SPTypesEnum } from "../../../types";
import { Tag } from "@chakra-ui/react";

const StakeLabel = styled.div<{ $active?: boolean }>`
  color: #000;
  padding: 0 1px 0 5px;
  width: 30px;
  font-weight: 700;
  ${(props) => {
    return props.$active
      ? css`
          color: #fff;
        `
      : css`
          color: #000;
        `;
  }}
`;

const StakeInput = styled.div<{ $side?: BetSidesEnum }>`
  input {
    border: 1px solid;
    border-radius: 5px;
    color: #012285;
    width: 70px;
    font-weight: 500;
    text-align: center;
    height: 18px;
    font-size: 11px;
    ${(props) => {
      return props.$side === BetSidesEnum.Back
        ? css`
            background-color: #a7d8fc;
            border-color: #007bff;
          `
        : css`
            background-color: #f6c9d4;
            border-color: #ff0027;
          `;
    }}
  }
`;

const StakeToggle = styled.div<{ $side?: BetSidesEnum; $active: boolean }>`
  padding: 2px 10px;
  border: 1px solid #fff;
  margin: 0px 5px;
  cursor: pointer;
  width: 75px;
  display: flex;
  justify-content: center;
  ${(props) => {
    return props.$side === BetSidesEnum.Back
      ? props.$active
        ? css`
            background-color: #007bff;
            color: #fff;
          `
        : css`
            background-color: #a7d8fc;
            color: #000;
          `
      : props.$active
      ? css`
          background-color: #ff0027;
          color: #fff;
        `
      : css`
          background-color: #f6c9d4;
          color: #000;
        `;
  }}
`;

const StakeMaker = styled.div<{ $side?: BetSidesEnum; $active: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;

  ${(props) => {
    return props.$side === BetSidesEnum.Back
      ? props.$active
        ? css`
            background-color: #007bff;
            color: #000;
          `
        : css`
            background-color: #a7d8fc;
            color: #fff;
          `
      : props.$active
      ? css`
          background-color: #ff0027;
          color: #000;
        `
      : css`
          background-color: #f6c9d4;
          color: #fff;
        `;
  }}

  div {
  }
`;

const TriangleBtn = styled.button<{
  $side: BetSidesEnum;
  $active: boolean;
  $enabled: boolean;
}>`
  border: 1px solid #fff;
  height: 95%;
  margin: 0 5px;
  ${(props) => (props.$enabled ? `cursor: pointer;` : `cursor: not-allowed;`)}
  svg {
    width: 40px;
    height: 40px;
    margin: -7px 0 0;
    ${(props) => {
      return props.$side === BetSidesEnum.Back
        ? props.$active
          ? css`
              color: #a7d8fc;
            `
          : css`
              color: #007bff;
            `
        : props.$active
        ? css`
            color: #f6c9d4;
          `
        : css`
            color: #ff0027;
          `;
    }}
  }
`;

const ActiveBetsTag = styled(Tag)<{
  $side: BetSidesEnum;
  $unmatched: boolean;
}>`
  cursor: pointer;
  border-shadow: 0 0 0 0;
  ${(props) => `border: 1px ${props.$unmatched ? `solid` : `dashed`}`};
  ${(props) => {
    if (props.$unmatched) {
      return css`
        color: #fff !important;
        background: #ffae00 !important;
        --badge-color: #007bff !important;
      `;
    } else {
      switch (props.$side) {
        case BetSidesEnum.Back:
          return css`
            color: #007bff !important;
            background: #a7d8fc4a !important;
            --badge-color: #007bff !important;
          `;
        case BetSidesEnum.Lay:
          return css`
            color: #ff0027 !important;
            background: #f6c9d44a !important;
            --badge-color: #ff0027 !important;
          `;
        case BetSidesEnum.None:
        default:
          return css`
            color: #000;
            background: ${props.$unmatched ? `#f5f5f5` : `#f5f5f5`};
            --badge-color: #f5f5f5;
          `;
      }
    }
    //
  }}
`;

const ActiveBetsBadge = styled.div<{
  $side: BetSidesEnum;
  $size: "sm" | "md" | "lg";
  $unmatched: boolean;
}>`
  display: inline-block;
  width: ${(props) =>
    props.$size === "sm" ? `17px` : props.$size === "md" ? `19px` : `22px`};
  height: ${(props) =>
    props.$size === "sm" ? `17px` : props.$size === "md" ? `19px` : `22px`};
  border-radius: 50%;
  ${(props) => (props.$unmatched ? `font-weight: 900;` : "")});
  background: ${(props) =>
    props.$side === BetSidesEnum.Back
      ? props.$unmatched
        ? `#fff`
        : `#007bff`
      : props.$unmatched
      ? `#fff`
      : `#ff0027`};
  color: ${(props) =>
    props.$unmatched
      ? props.$side === BetSidesEnum.Back
        ? `#007bff`
        : `#ff0027`
      : "#fff"};
  text-align: center;
  line-height: ${(props) =>
    props.$size === "sm" ? `17px` : props.$size === "md" ? `19px` : `22px`};
`;

const PriceBadge = styled.div<{ $side: BetSidesEnum }>`
  display: inline-block;
  height: 17px;
  border-radius: 5px;
  width: 35px;
  background: ${(props) =>
    props.$side === BetSidesEnum.Back ? `#007bff` : `#ff0027`};
  color: #fff;
  text-align: center;
  line-height: 17px;
  font-size: 10px;
`;

const PriceInput = styled.div<{ $side?: BetSidesEnum }>`
  display: inline;
  input {
    border: 1px solid;
    border-radius: 5px;
    color: #fff;
    width: 45px;
    text-align: center;
    height: 18px;
    font-size: 10px;
    font-weight: 700;
    ${(props) => {
      return props.$side === BetSidesEnum.Back
        ? css`
            background-color: #007bff;
            border-color: #a7d8fc;
          `
        : css`
            background-color: #ff0027;
            border-color: #f6c9d4;
          `;
    }}
  }
`;

const PriceBadgeWrap = styled.div``;

const PnLWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  gap: 4px;
`;
const PnLLabel = styled.span``;
const PnLLost = styled.span``;
const PnLWon = styled.span``;
const PnLProfit = styled.span<{ $neg: boolean }>`
  color: ${(props) => (props.$neg ? "red" : "green")};
`;

const SPBox = styled.div<{ $spType: SPTypesEnum; $side?: BetSidesEnum }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.6rem;
  border-radius: 0.5rem;
  width: 100%;
  height: 2rem;
  cursor: pointer;

  ${(props) => {
    switch (props.$spType) {
      case SPTypesEnum.Near:
      case SPTypesEnum.Far:
        return css`
          color: #000;
        `;
      case SPTypesEnum.BSP:
        return css`
          background: #d9f7d8;
          color: #000;
        `;
      case SPTypesEnum.Taken:
      default:
        return props.$side === BetSidesEnum.Lay
          ? css`
              background: #f6c9d4;
              color: #000;
            `
          : css`
              background: #a7d8fc;
              color: #000;
            `;
    }
  }}
  div.sp.price {
    font-size: 1.16em;
    font-weight: 700;
  }
  div.sp.size {
    font-size: 0.95em;
  }
  div.bsp {
    font-size: 1.16em;
    font-weight: 700;
  }
`;

export const Styled = {
  StakeLabel,
  StakeInput,
  StakeToggle,
  StakeMaker,
  TriangleBtn,
  ActiveBetsTag,
  ActiveBetsBadge,
  PriceBadge,
  PriceInput,
  PriceBadgeWrap,
  PnLWrapper,
  PnLLabel,
  PnLLost,
  PnLWon,
  PnLProfit,
  SPBox,
};
