import { create } from "zustand";
import { RsaBet } from "../types";
import { useShallow } from "zustand/react/shallow";
import { Uuid } from "../utils/uuid";

export const useBetsStore = create((set, get) => ({
  storage: {},
  getId: (marketId: string, faveNo: number, ext: number) => {
    return `ub-${marketId}:${faveNo}${Uuid.UniqueNumber()}-${ext}`;
  },
  add: (marketId: string, bet: RsaBet) =>
    set((state: any) => {
      let storage = state.storage;
      if (!storage[marketId]) {
        storage[marketId] = [];
      }
      let found =
        bet.id && storage[marketId].find((b: RsaBet) => bet.id === b.id);

      if (found) {
        storage[marketId] = storage[marketId].map((b: any) =>
          b.id === bet.id ? bet : b
        );
      } else {
        bet.id = bet.id || state.getId(marketId, bet.faveNo, 1);
        storage[marketId] = [...storage[marketId], bet];
      }

      return { ...state, storage, lastId: state.lastId };
    }),
  set: (marketId: string, bets: RsaBet[], faveNo?: number) =>
    set((state: any) => {
      if (!faveNo) {
        state.storage[marketId] = bets;
      } else {
        if (!state.storage[marketId]) {
          state.storage[marketId] = [];
        }
        state.storage[marketId] = state.storage[marketId]?.filter(
          (b: RsaBet) => b.faveNo !== faveNo
        );
        state.storage[marketId].push(...bets);
      }
      return { ...state };
    }),
  clear: (marketId: string) => {
    set((state: any) => {
      delete state.storage[marketId];
      return { ...state };
    });
  },
}));

export const useGetBets = (marketId: string, faveNo?: number) => {
  const bets = useBetsStore(
    useShallow((state: any) =>
      state.storage ? state.storage[marketId] || [] : []
    )
  );

  return faveNo ? bets.filter((b: RsaBet) => b.faveNo === faveNo) : bets;
};

export const useTrainingPnLStore = create((set, get) => ({
  bets: {},
  pnl: { lost: 0, won: 0, profit: 0 },
  add: (marketId: string, orders: RsaBet[]) =>
    set((state: any) => {
      let bets: any = {};
      bets[marketId] = orders;

      return { ...state, bets: { ...state.bets, ...bets } };
    }),
  execute: (marketId: string, selectionId: string) =>
    set((state: any) => {
      let bets = state?.bets[marketId] || [];
      let pnl = state.pnl;
      (bets || []).every((bet: any) => {
        if (bet.selectionId === selectionId) {
          pnl.profit += bet.ifWin;
          if (bet.ifWin > 0) {
            pnl.won++;
          } else {
            pnl.lost++;
          }
          return false;
        }

        return true;
      });

      return { ...state, pnl: pnl };
    }),
  clear: () => {
    set((state: any) => {
      return { ...state, pnl: { lost: 0, won: 0, profit: 0 }, bets: {} };
    });
  },
}));
