import styled, { css } from "styled-components";

const Container = styled.div``;

const Heatmap = styled.div<{
  $orientation: "horizontal" | "vertical";
  $cols: number;
  $rows: number;
}>`
  font-family: Arial, Helvetica, sans-serif;
  display: grid;
  grid-gap: 5px;
  align-items: start;
  background-color: #fff;
  padding: 10px 5px;
  ${(props) => {
    switch (props.$orientation) {
      case "horizontal":
        return css`
          max-width: 900px;
          grid-template-rows: repeat(${props.$rows}, 1fr);
          .col {
            display: grid;
            grid-template-columns: repeat(${props.$cols}, 1fr);
          }
          .cells .label {
            text-align: left;
            font-size: 0.7em;
            font-weight: bold;
          }
        `;
      case "vertical":
      default:
        return css`
          max-width: 400px;
          grid-template-columns: repeat(${props.$rows}, 1fr);
          .col {
            flex-direction: column;
          }
        `;
    }
  }}
  .col {
    display: grid;
    grid-gap: 5px;
  }
`;

const Cell = styled.div<{ $rng: string; $bgColor: string; $inRange: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15px;
  width: 100%;
  position: relative;
  color: ${(props) => (props.$inRange ? "#000" : "#e53e3e")};
  font-size: 0.6em;
  background: ${(props) =>
    props.$rng === "z0"
      ? `#ddd`
      : props.$inRange
      ? `${props.$bgColor || "hsl(196deg 36% 95%)"}`
      : `repeating-linear-gradient( 45deg, ${
          props.$bgColor || "hsl(196deg 36% 95%)"
        }, #eff0f34a 3px, ${
          props.$bgColor || "hsl(196deg 36% 95%)"
        } 7px, #eff0f34a 2px, ${props.$bgColor || "hsl(196deg 36% 95%)"} 7px)`};
  .number {
    border-radius: 50%;
    text-align: center;
    width: 12px;
    height: 12px;
    background: hsl(27.07deg 90% 95%);
  }
`;

const Label = styled.span`
  text-align: center;
  height: 15px;
  font-size: 0.7em;
  font-weight: bold;
`;

const SubLabel = styled.div<{ $highlighted: boolean }>`
  ${(props) => (props.$highlighted ? "background-color: #ffae00;" : "")};
`;

const Legend = styled.div`
  margin-top: 30px;
  max-width: 300px;
  .cell {
    height: 15px;
    width: 100%;
  }
  .labels {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
  }
  span {
    font-size: 14px;
  }
`;

const Filters = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 75px;
  padding: 2px 50px;
  margin-bottom: 10px;
  > div {
    width: 50%;
  }
`;

export const Styled = {
  Container,
  Heatmap,
  Cell,
  Label,
  SubLabel,
  Legend,
  Filters,
};
