import {
  BETFAIR_ORDER_PLACE_LIVE,
  BETFAIR_ORDER_PLACE_SIM,
  templateString,
} from "../endpoints";
import { request, RequestMethod } from "../requests";
import { useAccessTokenStore, useBfAccessTokenStore } from "../../store";
import { MutateOptions, useMutation } from "@tanstack/react-query";
import { RsaBet } from "../../types";
import { AxiosError } from "axios";
import { STATUS_CODE } from "../status-code";
import { routes } from "../../routes/consts";

export const usePlaceOrder = (
  options: MutateOptions<
    unknown,
    AxiosError,
    { marketId: string; isSimulate: boolean; bets: RsaBet[] },
    any
  >
) => {
  const accessToken = useAccessTokenStore((state: any) => state.accessToken);
  const bfAccessToken = useBfAccessTokenStore(
    (state: any) => state.accessToken
  );

  const placeOrder = async (payload: {
    marketId: string;
    isSimulate: boolean;
    update?: boolean;
    bets: RsaBet[];
  }) => {
    const url = templateString(
      payload.isSimulate ? BETFAIR_ORDER_PLACE_SIM : BETFAIR_ORDER_PLACE_LIVE,
      { marketId: payload.marketId }
    );

    return await request(
      url,
      {
        method: payload.update ? RequestMethod.PATCH : RequestMethod.POST,
        data: payload.bets,
      },
      accessToken(),
      payload.isSimulate ? null : bfAccessToken()
    ).catch((err) => {
      if (err?.response?.status === STATUS_CODE.UNAUTHORIZED) {
        window.location.replace(routes.LOGOUT);
      }
      throw err;
    });
  };

  return useMutation({
    mutationFn: placeOrder,
    mutationKey: ["place-order"],
    ...options,
  });
};
