import { BETFAIR_MARKET_LIST, TRAINING_MARKET_LIST } from "../endpoints";
import { request, RequestMethod } from "../requests";
import { useQuery } from "@tanstack/react-query";
import { useAccessTokenStore, useBfAccessTokenStore } from "../../store";

export const useMarketList = (
  query: {
    live?: {
      countries: string;
      range: { from: number; to: number };
    };
    training?: {
      wday: string;
    };
  },
  enabled: boolean = true
) => {
  const accessToken = useAccessTokenStore((state: any) => state.accessToken);
  const bfAccessToken = useBfAccessTokenStore(
    (state: any) => state.accessToken
  );

  const liveQuery = (query: {
    countries: string;
    range: { from: number; to: number };
  }) => {
    let url = `${BETFAIR_MARKET_LIST}?countries=${query.countries}&from=${query.range.from}&to=${query.range.to}`;
    const bfToken = bfAccessToken();
    if (!bfToken) {
      return { data: [], error: "No access token", training: false };
    }

    return request(
      url,
      { method: RequestMethod.GET },
      accessToken(),
      bfToken
    ).then((data) => {
      return { data, training: false };
    });
  };

  const trainingQuery = (query: { wday: string }) => {
    if (!query.wday) return null;

    let url = `${TRAINING_MARKET_LIST}?day=${query.wday}`;
    return request(url, { method: RequestMethod.GET }, accessToken()).then(
      (data) => {
        return { data, training: true };
      }
    );
  };

  return useQuery({
    queryFn: async () => {
      if (query.live) {
        return liveQuery(query.live);
      } else if (query.training) {
        return trainingQuery(query.training);
      } else {
        return Promise.resolve(null);
      }
    },
    queryKey: ["market-list", query],
    refetchOnWindowFocus: false,
    enabled,
  });
};
