import React from "react";
import { Utils } from "../utils";
import { Styled } from "./styles";

type DaySummaryDataItem = {
  faves: number;
  outsiders: number;
};

export type DaySummaryData = {
  actual: DaySummaryDataItem;
  expected: DaySummaryDataItem;
};

export const DaySummaryTable = (props: { data: DaySummaryData }) => {
  const { data, ...rest } = props;

  const sections = [
    {
      title: "Actual",
      data: data.actual,
    },
    {
      title: "Expected",
      data: data.expected,
    },
  ];

  const subSections = [
    {
      title: "Fave",
      key: "faves",
    },
    {
      title: "Out",
      key: "outsiders",
    },
  ];

  return (
    <Styled.SummaryTable>
      <div className="summary-section">
        {sections.map((section, index) => (
          <div key={index}>
            <div className="summary-header">{section.title}</div>
            <div className="summary-body">
              {subSections.map((item, index) => (
                <div className="summary-row" key={index}>
                  <div className="sub-header">{item.title}:</div>
                  <div className={`sub-body ${item.key}`}>
                    {section.data[item.key as keyof DaySummaryDataItem] !== 0
                      ? Utils.formatDecimal(
                          section.data[item.key as keyof DaySummaryDataItem]
                        )
                      : "0"}
                    %
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </Styled.SummaryTable>
  );
};
