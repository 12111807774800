import React, { useState, useMemo } from "react";
import "./react-grid-layout.css";
import "./flexible-layout.css";
import { CSSProperties } from "react";

import { Responsive, WidthProvider } from "react-grid-layout";
const ResponsiveGridLayout = WidthProvider(Responsive);

const styles: CSSProperties = {
  fontFamily: "sans-serif",
  textAlign: "center",
};

export type LayoutItemType = ReactGridLayout.Layout & {
  element?: React.ReactNode;
};

export type LayoutsItemType = {
  [P: string]: LayoutItemType[];
};

export type LayoutElementType = {
  id: string;
  element: React.ReactNode;
};

export const FlexibleLayout = (props: {
  layout: LayoutsItemType;
  elements: LayoutElementType[];
  onLayoutUpdated: (layout: LayoutsItemType) => void;
  debug?: boolean;
}) => {
  const { layout, elements, onLayoutUpdated, debug = false } = props;

  const contents = useMemo(() => {
    return elements.filter((el) => {
      return Object.keys(layout).some((breakpoint) => {
        return layout[breakpoint].some((l) => {
          return l.i === el.id;
        });
      });
    });
  }, [elements, layout]);

  const onRemoveItem = (idx: string) => {
    const layoutAfterRemove = Object.keys(layout).reduce(
      (memo, breakpoint) => {
        return {
          ...memo,
          [breakpoint]: layout[breakpoint].filter(
            (filterLayout: LayoutItemType) => {
              return filterLayout.i !== idx;
            }
          ),
        };
      },
      {
        lg: [],
        sm: [],
      }
    );

    onLayoutUpdated(layoutAfterRemove);
  };

  const createElement = (el: LayoutElementType, index: number) => {
    return (
      <div key={el.id} className="parent">
        <div className="header">
          <div className="dragger"></div>
          <div className="remove" onClick={() => onRemoveItem(el.id)}>
            x
          </div>
        </div>
        <div className="body">{el.element}</div>
      </div>
    );
  };

  const onLayoutChange = (current: any, layouts: any) => {};

  return (
    <div style={styles}>
      <React.Fragment>
        <ResponsiveGridLayout
          className="layout"
          layouts={layout}
          breakpoints={{ lg: 1200, sm: 768 }}
          cols={{ lg: 24, sm: 12 }}
          rowHeight={30}
          width={1800}
          isResizable={true}
          isDraggable={true}
          draggableHandle=".dragger"
          resizeHandles={["se"]}
          onLayoutChange={onLayoutChange}
        >
          {contents.map((el, index) => {
            return createElement(el, index);
          })}
        </ResponsiveGridLayout>
      </React.Fragment>
    </div>
  );
};
