import { Button } from "@chakra-ui/react";
import React from "react";
import { Uuid } from "../../utils/uuid";

export const ExportFile = (props: {
  label: string;
  fileName: string;
  data: string;
}) => {
  const { label, fileName, data } = props;

  const elementId = `div-exportfile-${Uuid.UniqueNumberId()}`;

  const exportData = function () {
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(data)
    );
    element.setAttribute("download", fileName);
    element.style.display = "none";
    const elementContainer: any = document.getElementById(elementId);
    elementContainer.appendChild(element);

    element.click();

    elementContainer.removeChild(element);
  };

  return (
    <>
      <Button onClick={() => exportData()}>{label}</Button>
      <div id={elementId} style={{ display: "none" }}></div>
    </>
  );
};
