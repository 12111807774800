import { LOOKUP_MAIN } from "../endpoints";
import { request, RequestMethod } from "../requests";
import { useQuery } from "@tanstack/react-query";
import { useAccessTokenStore, useMetadataStore } from "../../store";
import { routes } from "../../routes/consts";
import { STATUS_CODE } from "../status-code";

export const useMainLookups = () => {
  const accessToken = useAccessTokenStore((state: any) => state.accessToken);
  const storeMetadata = useMetadataStore((state: any) => state.store);

  return useQuery({
    queryFn: () =>
      request(LOOKUP_MAIN, { method: RequestMethod.GET }, accessToken())
        .then((data) => {
          storeMetadata(data);
          return data;
        })
        .catch((err) => {
          if (err?.response?.status === STATUS_CODE.UNAUTHORIZED) {
            window.location.replace(routes.LOGOUT);
          }
          throw err;
        }),
    queryKey: ["main-lookups"],
    refetchOnWindowFocus: false
  });
};
