import React, { useEffect } from "react";
import { useWorkerStore } from "../../store";

export const Ticker = (props: {
  name: string;
  onTick: (event: any) => void;
}) => {
  const { name, onTick = (event: any) => {} } = props;
  const { worker, addListener, removeListener } = useWorkerStore(
    (state: any) => state
  );

  useEffect(() => {
    addListener(name, onTick);

    return () => {
      removeListener(name);
    };
  }, [worker, addListener, removeListener, name, onTick]);

  return <></>;
};
