import React from "react";
import BetFinderImg from "../img/poweredby/betfair.png";
import ElasticImg from "../img/poweredby/elastic.png";
import appIconsGroup from "../img/app-icons-group.png";

type THeader = {
  data: {
    title: string;
    paragraph: string;
  };
};
export const Header = ({ data }: THeader) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-6 intro-text col-sm-12 ">
                <h1>
                  {data ? data.title : "Loading"}
                  <span></span>
                </h1>
                <p>{data ? data.paragraph : "Loading"}</p>
                <a
                  href="#pricing"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Join Now
                </a>
              </div>
              <div className="col-md-6 col-sm-12 powerd-by-section">
                <div className="d-flex flex-column">
                  <div className="apps">
                    <img
                      src={appIconsGroup}
                      alt=""
                      className="feature-tool-bar"
                    />
                    <h3>Six unique apps in RSA</h3>
                  </div>
                  <div className="vendors">
                    <h3>Powered by </h3>
                    <div className="vendors-icon">
                      <img src={BetFinderImg} alt="" />
                      <img src={ElasticImg} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
