import { BETFAIR_ORDERS_MARKET, templateString } from "../endpoints";
import { bfRequest, RequestMethod } from "../requests";
import { useAccessTokenStore, useBfAccessTokenStore } from "../../store";
import { useQuery } from "@tanstack/react-query";

export const useGetOrders = (
  marketId: string,
  manualTrigger: number,
  isSimulate?: boolean
) => {
  const accessToken = useAccessTokenStore((state: any) => state.accessToken);
  const bfAccessToken = useBfAccessTokenStore(
    (state: any) => state.accessToken
  );

  return useQuery({
    queryFn: () => {
      if (!marketId) return { marketId: "", error: "No market id" };

      const bfToken = bfAccessToken();
      if (!bfToken) {
        return { marketId: "", error: "No access token" };
      }

      return bfRequest(
        `${templateString(BETFAIR_ORDERS_MARKET, { marketId })}${
          isSimulate ? "?simulate=true" : ""
        }`,
        { method: RequestMethod.GET },
        accessToken(),
        bfToken
      ).then((res: any) => {
        return { ...res, timestamp: Date.now() };
      });
    },
    queryKey: ["get-market-orders", marketId, manualTrigger],
  });
};
