import styled from "styled-components";

const LoginWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
  place-items: center;
  padding: 8px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Styled = {
  LoginWrapper,
  FormContainer,
};
