import styled from "styled-components";

const FinderRow = styled.div`
  display: flex;
  justify-content: space-around;
  padding-top: 32px;
  gap: 24px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FinderLeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 32px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const FinderRightWrapper = styled.div`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Styled = {
  FinderRow,
  FinderLeftWrapper,
  FinderRightWrapper,
};
