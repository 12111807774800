import React from "react";
import { RsaTableOptions, RsaTableColumn } from "./types";
import { getYears } from "../../services/search/utils";

export const createColumns = function (
  name: string,
  options?: RsaTableOptions
): RsaTableColumn[] {
  const formatter = options?.formatter ?? ((name: string, value: any) => value);
  const additionalColumns = options?.additionalColumns ?? [];

  const defaultRenderCell = function (colname: string, { row }: { row: any }) {
    return <>{row[colname] ? formatter(colname, row[colname]) : ""}</>;
  };

  const defaultRenderSummaryCell = function (
    colname: string,
    { row }: { row: any }
  ) {
    return row && row.totals && row.totals[colname]
      ? formatter(colname, row.totals[colname])
      : "";
  };

  const columns0 = {
    key: "key",
    name: name,
    width: 50,
    frozen: true,
    resizable: true,
    renderSummaryCell() {
      return <strong>Total</strong>;
    },
  };

  const getColumn = function (
    key: string,
    name: string,
    renderCell?: (colname: string, { row }: { row: any }) => React.JSX.Element,
    renderSummaryCell?: (
      colname: string,
      { row }: { row: any }
    ) => React.JSX.Element
  ) {
    return {
      key: key,
      name: name,
      minWidth: options?.columnWidth?.minWidth,
      maxWidth: options?.columnWidth?.maxWidth,
      width: options?.columnWidth?.width,
      renderCell({ row }: { row: any }) {
        return (renderCell || defaultRenderCell)(key, { row });
      },
      renderSummaryCell({ row }: { row: any }) {
        return (renderSummaryCell || defaultRenderSummaryCell)(key, { row });
      },
    };
  };

  return [
    columns0,
    ...getYears(true).map((yr: any) => getColumn(`${yr.y}`, `Y${yr.sy}`)),
    ...additionalColumns.map((col) =>
      getColumn(col.key, col.name, col.renderCell, col.renderSummaryCell)
    ),
  ];
};

export const prepareTableData = function (
  resp: any,
  options?: RsaTableOptions
): { rows: any[]; totals?: any[] } {
  const valueSelector = options?.valueSelector ?? ((value: any) => value);
  const vh_formatter =
    options?.vh_formatter ?? ((value: any, row: any) => value);

  const rows: any = [];
  const totals: any = [];

  let keys = Object.keys(resp);
  keys.map((key) => {
    let cols = Object.keys(resp[key]);
    let obj: any = { key: vh_formatter(key, resp[key]) };
    cols.forEach((colname) => {
      obj[colname] = valueSelector(resp[key][colname]);
    });
    if (/total/i.test(key)) {
      totals.push(obj);
    } else {
      rows.push(obj);
    }
  });

  return {
    rows,
    totals,
  };
};
