import React from "react";
import { BiCard } from "react-icons/bi";
import "./multistate.css";

export const MultiStateButton = <T extends string>(props: {
  options: {
    [key in T]: {
      display?: boolean;
      color?: string;
      background?: string;
      text: string;
      icon?: React.ReactElement;
    };
  };
  currentState: T;
  loop?: boolean;
  onChange: (selection: T) => void;
}) => {
  const { options, loop, currentState, onChange } = props;

  const handleClick = () => {
    var keys = Object.keys(options).filter(
      (key) => options[key as T].display !== false
    ) as T[];
    let currentIndex = keys.indexOf(currentState);
    let nextIndex = currentIndex;
    if (currentIndex < keys.length - 1) {
      nextIndex = currentIndex + 1;
    } else {
      if (loop) {
        nextIndex = 0;
      }
    }
    onChange(keys[nextIndex]);
  };

  return (
    <button
      onClick={handleClick}
      style={{
        color: options[currentState].color || "#000",
        background: options[currentState].background || "#fff",
      }}
      className={`btn multiStateBtn multiStateBtn-${currentState}`}
    >
      <div className="multiStateBtnContent">
        {options[currentState].icon || <BiCard />}
        {options[currentState].text}
      </div>
    </button>
  );
};

export const EmptyMultiStateButton = () => {
  return <div className="btn multiStateBtn-empty" />;
};
