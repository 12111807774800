import React, { useEffect, useState } from "react";
import { CircularProgress, IconButton, Select } from "@chakra-ui/react";
import { DateUtils, RsaDate } from "../../utils/dateutils";
import { RadioCardGroup, RadioCardItem, SingleDatepicker } from "../chakra";
import { useBfAccessTokenStore, useMarketsStore } from "../../store";
import { useMarketList } from "../../services";
import { BiCaretRight, BiCaretLeft } from "react-icons/bi";
import { Styled } from "./styles";

export type MarketListProps = {
  currentId: string;
  children?: React.ReactNode;
  onRaceSelect: (raceId: string) => void;
  onDateChange: (date: RsaDate) => void;
};

export const MarketList = (props: MarketListProps) => {
  const [days, setDays] = useState<RadioCardItem[]>([]);
  const [weekDate, setWeekDate] = useState<any>(
    DateUtils.getRsaDate(new Date())
  );
  const [day, setDay] = useState<RadioCardItem>(weekDate);
  const currentId = props.currentId;
  const [dayMap, setDayMap] = useState<any>({});
  const [markets, setMarkets] = useState<any>([]);

  const [date, setDate] = useState(new Date());
  const liveQuery = {
    live: {
      countries: "GB,IE",
      range: DateUtils.range(2),
    },
  };
  const [query, setQuery] = useState<any>(liveQuery);
  const marketsMap = useMarketsStore((state: any) => state.store);
  const bfLoggedIn = useBfAccessTokenStore((state: any) => state.loggedIn);

  const { data, isLoading } = useMarketList(query);

  const populateMarketIds = (races: any) => {
    if (races) {
      let ids = Object.keys(races);
      let preMarketMap: any = {};
      let options = ids.map((id) => {
        let market = races[id];
        let time = market.time || market.N_time;
        let ts = 0;
        if (market.marketStartTime) {
          let date = new Date(market.marketStartTime);
          ts = date.getTime();
          time = DateUtils.formatTime(date);
        }
        preMarketMap[id] = market;
        return {
          ts,
          training: market.training,
          time: time,
          id: market.id,
        };
      });
      options.sort((a, b) => {
        return a.ts - b.ts;
      });
      setMarkets(options);
      marketsMap(preMarketMap);
    }
  };

  const loadTrainingData = (trainingData: any) => {
    let daily_map: any = {};
    let days0: string[] = Object.keys(trainingData || {});
    let days1: RadioCardItem[] = bfLoggedIn()
      ? [
          {
            id: "today",
            label: "Live",
            subtitle: ``,
          },
        ]
      : [];
    if (trainingData && days0.length > 0) {
      days0.forEach((wdate) => {
        let day = DateUtils.parseWeekDate(wdate);
        if (!daily_map[day.key]) {
          daily_map[day.key] = {};
          days1.push({
            id: day.key,
            label: day.day,
            subtitle: `Wk ${day.woy}`,
          });
        }
        trainingData[wdate].forEach((element: any) => {
          daily_map[day.key][element.id] = {
            ...element,
            training: true,
            L_time: element.time,
          };
        });
      });
      setDays(days1);
      setDayMap(daily_map);
    }
  };

  const loadLiveData = (marketsData: any) => {
    let daily_map: any = {};
    let days1: RadioCardItem[] = [];

    if (marketsData && marketsData.length > 0) {
      (marketsData ?? []).map((element: any) => {
        let date = new Date(element.marketStartTime);
        let day = DateUtils.getRsaDate(date);
        if (!daily_map[day.key]) {
          daily_map[day.key] = {};
          days1.push({
            id: day.key,
            label: day.day,
            subtitle: `Wk ${day.woy}`,
          });
        }
        element.L_time = DateUtils.formatTime(date);
        daily_map[day.key][element.marketid] = element;
        return day.day;
      });
      setDays(days1);
      setDayMap(daily_map);
    }
  };

  useEffect(() => {
    if (day.id === "today") {
      setQuery(liveQuery);
      setDate(new Date());
    } else {
      populateMarketIds(dayMap[day.id]);
    }
  }, [day]);

  useEffect(() => {
    if (!data) return;

    if (data.training) {
      loadTrainingData(data.data);
    } else {
      loadLiveData(data.data);
    }
  }, [data]);

  const selectDay = (date: any) => {
    setDate(date);
    let datew = DateUtils.getRsaDate(date);
    props.onDateChange(datew);
    setWeekDate(datew);
    setMarkets([]);
    if (!datew.is_today()) {
      setQuery({
        training: {
          wday: datew.key,
        },
      });
    } else {
      setQuery(liveQuery);
    }
  };

  function onNext(): void {
    const currentIndex = markets.findIndex(
      (market: any) => market.id === currentId
    );
    if (currentIndex < markets.length - 1) {
      props.onRaceSelect(markets[currentIndex + 1].id);
    }
  }

  function onPrevious(): void {
    const currentIndex = markets.findIndex(
      (market: any) => market.id === currentId
    );
    if (currentIndex > 0) {
      props.onRaceSelect(markets[currentIndex - 1].id);
    }
  }

  return (
    <Styled.MarketListWrapper>
      <SingleDatepicker
        name="date-input"
        date={date}
        onDateChange={selectDay}
      />

      {isLoading ? (
        <div>
          <CircularProgress isIndeterminate color="green.300" />
        </div>
      ) : (
        <>
          <RadioCardGroup options={days} onChange={setDay} selected={day} />

          <Styled.SelectWrapper>
            <IconButton
              size="lg"
              fontSize={40}
              variant="unstyled"
              aria-label="Previous"
              icon={<BiCaretLeft />}
              onClick={onPrevious}
            />
            <Select
              size={"md"}
              placeholder="Select a race"
              value={currentId}
              onChange={(e) => props.onRaceSelect(e.target.value)}
            >
              {markets &&
                markets.map((market: any) => {
                  return (
                    <option value={market.id} key={market.id}>
                      {market.time}
                    </option>
                  );
                })}
            </Select>

            <IconButton
              size="lg"
              fontSize={40}
              variant="unstyled"
              aria-label="Next"
              icon={<BiCaretRight />}
              onClick={onNext}
            />
          </Styled.SelectWrapper>
        </>
      )}
      <>{props.children}</>
    </Styled.MarketListWrapper>
  );
};
