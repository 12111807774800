import React from "react";
import { Stack, IconButton } from "@chakra-ui/react";
import { useSystemsStore, useCurrentSystemStore } from "../../store";
import { SegmentedControl } from "../segmented/control";
import { SettingsIcon } from "@chakra-ui/icons";

export const SystemsSegmentedControl = (props: { onEdit: () => void }) => {
  const filters = useSystemsStore((state: any) => state.filters);
  const syskeys = Object.keys(filters);
  let segments = syskeys.map((syskey: string) => {
    return { value: syskey, label: syskey.toUpperCase() };
  });

  return (
    <Stack direction="row" spacing={4}>
      <SegmentedControl items={segments} storage={useCurrentSystemStore} />;
      <IconButton
        aria-label="Edit"
        size="sm"
        variant="outline"
        isRound={true}
        icon={<SettingsIcon />}
        onClick={() => props.onEdit()}
      />
    </Stack>
  );
};
