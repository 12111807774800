import { create } from "zustand";

export const useMarketsStore = create((set, get) => ({
  marketsMap: {},
  store: (marketsMap: any) =>
    set(() => {
      return marketsMap;
    }),
  clear: () => set({}, true),
}));
