import React, { useEffect, useMemo } from "react";
import { RsaDoubleSlider } from "../slider";
import { GStyled } from "../../styles/global";
import { Styled } from "./styles";
import { RsaRangeSelect } from "../range";
import { getNumbers, prepareData, RsaMultiSelect } from "../dropdown";
import { FilterValueEnum, QuickFinderDataType } from "../../types";
import { ValueSwitch } from "../switch";
import { useQuickFinderStore } from "../../store";
import { WeekdaySelector } from "../dropdown/day-selector";

export const QFilter = (props: {
  filters?: QuickFinderDataType | undefined;
}) => {
  const { filters } = props;
  const { week, dow, ran, track, distance, type, country, switches, jumpflat } =
    filters || {
      ran: 0,
      switches: {},
    };

  //const store = useQuickFinderStore((state: any) => state.store);

  // useEffect(() => {
  //   store("week.from", FilterValueEnum.int, week?.from);
  //   store("week.to", FilterValueEnum.int, week?.to);
  // }, [week]);

  // useEffect(() => {
  //   store("dow", FilterValueEnum.int, dow || []);
  // }, [dow]);

  const { lower_ran, upper_ran } = useMemo(() => {
    const lower_ran = ran - 5 > 0 ? ran - 5 : 0;
    const upper_ran = ran + 5 < 100 ? ran + 5 : 100;

    //store("ran.from", FilterValueEnum.int, ran);
    //store("ran.to", FilterValueEnum.int, ran + 1);

    return { lower_ran, upper_ran };
  }, [ran]);

  return (
    <Styled.Container>
      <GStyled.Stack $dir="column" $spacing={4} $w={200}>
        <>
          <RsaRangeSelect
            label="Week"
            storekey={"week"}
            items={prepareData(getNumbers(1, 53))}
            size="xs"
            datatype={FilterValueEnum.int}
            placeholder="All"
            storage={useQuickFinderStore}
          />
          <WeekdaySelector
            label="Day of Week"
            placeholder="Select Day"
            size="xs"
            storekey="dow"
            storage={useQuickFinderStore}
          />
          <RsaDoubleSlider
            label="Ran"
            storekey={"ran"}
            bound={[lower_ran, upper_ran]}
            storage={useQuickFinderStore}
          />
          <ValueSwitch
            colorScheme="teal"
            storage={useQuickFinderStore}
            label="Track"
            value={track}
            defaultChecked={switches.track}
            storekey={`track`}
          />
          <ValueSwitch
            colorScheme="teal"
            storage={useQuickFinderStore}
            label="Distance/s"
            value={distance}
            defaultChecked={switches.distance}
            storekey={`distance`}
          />
          <ValueSwitch
            colorScheme="teal"
            storage={useQuickFinderStore}
            label="Race type/s"
            value={type}
            defaultChecked={switches.type}
            storekey={`type`}
          />
          <ValueSwitch
            colorScheme="teal"
            storage={useQuickFinderStore}
            label="IRE/UK"
            value={country}
            defaultChecked={switches.country}
            storekey={`state`}
          />
          <ValueSwitch
            colorScheme="teal"
            storage={useQuickFinderStore}
            label="Jump/Flat"
            value={jumpflat}
            defaultChecked={switches.jumpflat}
            storekey={`jumpflat`}
          />
        </>
      </GStyled.Stack>
    </Styled.Container>
  );
};
