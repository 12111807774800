import React from "react";

import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
} from "@chakra-ui/react";

interface TextFieldProps {
  id: string;
  label: string;
  name: string;
  type?: string;
  placeholder?: string;
  register: any; // You might want to replace 'any' with a more specific type
  isInvalid?: boolean;
  error?: string;
}

export const TextField = (props: TextFieldProps) => {
  const { label, name, type, placeholder, register, isInvalid, error } = props;

  return (
    <FormControl isInvalid={isInvalid}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Input
        id={name}
        placeholder={placeholder}
        type={type ?? "text"}
        {...register(name, {
          required: "This is required",
        })}
      />
      {!!error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};
