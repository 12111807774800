import styled from "styled-components";

const MarketHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  background: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;
  font-size: 12px;
  .market-info {
    display: flex;
    gap: 8px;
  }
  .market-info.time-left {
    width: 100px;
    padding: 0 0 0 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .market-info.time-left.positive {
    color: #0603af;
  }
  .market-info.time-left.negative {
    color: #ff0000;
  }
  .market-info.time-left.inplay {
    font-weight: 700;
    color: #03af03;
  }
  .market-info.in-play {
    font-weight: 700;
    width: 55px;
    background: #03af03;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .market-info.market-status {
    font-weight: 700;
    width: 100px;
    background: rgb(108, 111, 108);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const JockyAvatar = styled.img`
  height: 24px;
  align-self: center;
`;

const Runner = styled.div`
  display: flex;
  gap: 8px;
`;

const RunnerDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const HorseName = styled.div`
  font-weight: 700;
  color: #012285;
  font-size: 0.7rem;
`;

const JockyName = styled.div`
  font-size: 0.6rem;
  color: #000;
`;

const WhatIfsWraper = styled.div`
  display: flex;
  flex-direction: column;
`;
const WhatIfs = styled.span<{ $neg: boolean }>`
  height: 13px;
  color: ${(props) => (props.$neg ? "red" : "green")};
`;

const MarketListWrapper = styled.div`
  display: flex;
  padding: 10px;
  width: 90%;
  gap: 24px;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
`;

export const BetfairProfileWrapper = styled.div`
  display: flex;
  padding: 10px;
  width: 10%;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 50%;
    align-items: flex-end;
  }
`;

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Styled = {
  MarketHeader,
  Runner,
  JockyAvatar,
  RunnerDetails,
  HorseName,
  JockyName,
  WhatIfs,
  WhatIfsWraper,
  MarketListWrapper,
  BetfairProfileWrapper,
  SelectWrapper,
};
