const hheads = [
  { key: "lm80", name: "<80%" },
  { key: "m80", name: "80%" },
  { key: "m60", name: "60%" },
  { key: "m40", name: "40%" },
  { key: "m30", name: "30%" },
  { key: "m20", name: "20%" },
  { key: "m15", name: "15%" },
  { key: "m10", name: "10%" },
  { key: "m5", name: "5%" },
  { key: "z0", name: "0" },
  { key: "p5", name: "5%" },
  { key: "p10", name: "10%" },
  { key: "p15", name: "15%" },
  { key: "p20", name: "20%" },
  { key: "p30", name: "30%" },
  { key: "p40", name: "40%" },
  { key: "p60", name: "60%" },
  { key: "p80", name: "80%" },
  { key: "gp80", name: ">80%" },
];

const dheads = [
  -1000, -80, -60, -40, -30, -20, -15, -10, -5, 0, 5, 10, 15, 20, 30, 40, 60,
  80, 1000,
];

// const filters_pcntg = [2, 5, 10, 12.5, 15, 17.5, 20, 25, 30, 50];
// const filters_faves = [10, 12.5, 15, 17.5, 20, 25, 30, 50];
const filters_pcntg = [2, 12.5, 17.5, 30, 50];
const filters_faves = [10, 12.5, 17.5, 30, 50];

const vheads = {
  "1": "Fave",
  "2": "2nd",
  "3": "3rd",
  "4": "4th",
  "5": "5th",
  "6": "6th",
  "7": "7th",
  "8": "8th",
  "9": "9th",
  "10": "10th",
  "11": "11th",
  "12": "12th",
  "13": "13th",
  "14": "14th",
  "15": "15th",
  "16": "16th",
  "17": "17th",
  "18": "18th",
  "19": "19th",
  "20": "20th",
  "21": "21st",
  "22": "22nd",
  "23": "23rd",
  "24": "24th",
  "25": "25th",
  "26": "26th",
  "27": "27th",
  "28": "28th",
  "29": "29th",
  "30": "30th",
};

const matchCriteria: any[] = [
  //{ from: 0, to: 0, match: "z0"},
];

for (let ix = 0; ix < dheads.length - 1; ix++) {
  let f = dheads[ix];
  let e = dheads[ix + 1];
  matchCriteria.push({
    from: f,
    to: e,
    match: e > 0 ? (e < 1000 ? e : 1000) : f,
  });
  //matchCriteria.push({ from: f, to: e, match: e < 1000 ? `p${e}` : 'gp80'});
  //matchCriteria.push({ from: -e, to: f > 0 ? -f : f, match: e < 1000 ? `m${e}` : 'lm80'});
}

matchCriteria.sort(function (a, b) {
  return a.from - b.from;
});

export const heatmapData = {
  hheads,
  dheads,
  filters_pcntg,
  filters_faves,
  vheads,
  matchCriteria,
};
