import { create } from "zustand";
import { FilterValueEnum } from "../../types";
import { set_filter, get_filter, clear_filter } from "../utils";

export const useFilterStore = create((set, get) => ({
  filters: {},
  get: (id: string) => {
    return get_filter(get() as any, id);
  },
  store: (id: string, type: FilterValueEnum, value: any) =>
    set((state: any) => {
      state = set_filter(state, id, type, value);
      return { ...state, filters: { ...state.filters } };
    }),
  reset: (data: any) =>
    set((state: any) => {
      state.filters = data;
      return { ...state, filters: { ...state.filters } };
    }),
  clear: (id: string) =>
    set((state: any) => {
      state = clear_filter(state, id);
      return { ...state, filters: { ...state.filters } };
    }, true),
}));
