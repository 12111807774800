import React from "react";
import { RsaTableColumn, RsaTableProps } from "../types";
import { BaseTable } from "../base";
import { Utils } from "../utils";

export const DailyTable = (props: RsaTableProps) => {
  const { data, ...rest } = props;

  const columns: RsaTableColumn[] = [
    {
      key: "raceno",
      name: "No.",
    },
    {
      key: "N_time",
      name: "Time",
    },
    {
      key: "G_track",
      name: "Track",
    },
    {
      key: "E_type",
      name: "Type",
    },
    {
      key: "D_dis",
      name: "Dis",
    },
    {
      key: "F_ran",
      name: "Ran",
    },
    {
      key: "Winner",
      name: "Winner",
    },
    {
      key: "WinnerName",
      name: "Winner Name",
    },
    {
      key: "bsp",
      name: "BSP",
      renderCell({ row }: { row: any }) {
        return <>{Utils.formatDecimal(row.bsp)}</>;
      },
    },
  ];

  const rows: any[] = data;

  const rowHeight = 40; // The height of each row (same as in the CSS)
  const numRows = rows.length;
  const gridHeight = rowHeight * numRows;

  return (
    <BaseTable
      rows={rows}
      columns={columns}
      style={{
        height: gridHeight, // Dynamically set the height based on rows
        overflow: "auto", // Prevent scrolling inside the grid
      }}
      size={props?.options?.size}
      {...rest}
    />
  );
};
