import React, { useMemo } from "react";
import { Tabs, TabList, Tab } from "@chakra-ui/react";
import { UseBoundStore } from "zustand/react";
import { StoreApi } from "zustand/vanilla";

interface Item {
  value: string;
  label: React.ReactNode;
  disabled?: boolean;
}

function normalize(items: Array<string | Item>): Item[] {
  return items.map((item) => {
    if (typeof item === "string") return { value: item, label: item };
    return item;
  });
}
//useResultsSegmentStore
export const SegmentedControl = (props: {
  items: Item[];
  storage: UseBoundStore<StoreApi<unknown>>;
}) => {
  const data = useMemo(() => normalize(props.items), []);
  const store = props.storage((state: any) => state.store);
  const selectedIndex = props.storage((state: any) => state.index);

  return (
    <>
      <Tabs
        variant="soft-rounded"
        colorScheme="green"
        defaultIndex={selectedIndex}
        onChange={(index) => store(index, data[index].value)}
      >
        <TabList>
          {data.map((item) => (
            <Tab key={item.value}>{item.label}</Tab>
          ))}
        </TabList>
      </Tabs>
    </>
  );
};
